

import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
// import { Banner } from '../../Banner'
import TrustedOrganization from '../../TrustedOrganization'
import '../../../style/HCMLearning.css'
import { Link } from 'react-router-dom'
import { GetStarted } from '../../FooterSection/GetStarted'
import { Helmet } from 'react-helmet';
import Accordion from 'react-bootstrap/Accordion';
import { AccordionButton } from 'react-bootstrap';
import { GoHorizontalRule, GoPlus } from 'react-icons/go';
import ManagedData from '../../../data/HCMLearning/ManagedServices.json'
const Banner = React.lazy(() => import('../../Banner'));

const ManagedServices = () => {

  const [expandedItem, setExpandedItem] = useState(null);

  const handleToggle = (eventKey) => {
    setExpandedItem(expandedItem === eventKey ? null : eventKey);
  };
  return (
    <>
      <Helmet>
        <title>Application Managed Services | Phenomecloud</title>
        <meta name="description" content="Partner with PhenomeCloud for managed services across business, data, ERP, and HR platforms, ensuring seamless operations, optimized performance, and efficient administration." />
      </Helmet>
      <Helmet>
        <title>Application Managed Services | Phenom&#x1D49;Cloud</title>
        <meta
          name="description"
          content="Partner with PhenomeCloud for managed services across business, data, ERP, and HR platforms, ensuring seamless operations, optimized performance, and efficient administration."
        />
        <meta
          name="keywords"
          content="Business Platforms Management | Managed Services for ERP | Managed Services for HRTech | Managed Analytics Services | Enterprise platforms administration"
        />
        <meta
          name="robots"
          content="index, follow"
        />
        <meta
          property="og:title"
          content="Application Managed Services | Phenom&#x1D49;Cloud"
        />
        <meta
          property="og:description"
          content="Partner with PhenomeCloud for managed services across business, data, ERP, and HR platforms, ensuring seamless operations, optimized performance, and efficient administration."
        />
        <meta
          property="og:keywords"
          content="Business Platforms Management | Managed Services for ERP | Managed Services for HRTech | Managed Analytics Services | Enterprise platforms administration"
        />
      </Helmet>
      <Banner />
      <TrustedOrganization />
      {ManagedData.map((item, index) => {
        const { id, title, desc, bannerImage, accordians, infotext, grid, sub_title, card, button, cta_link, Header, btn_link, subDesc, GridName } = item;
        return (
          <div className="" key={index}>

            {id == 1 ? (
              <div className="row d-flex justify-content-around py-4  mb-3 align-items-center   whitebg_section discover_possible">
                {title && <h3 className="text-dark text-center fw-bold pt-2 col-10">{title}</h3>}
                <div className="col-md-4 text-center col-sm-12 col-12">
                  <div>
                    <img alt="Application Managed Services | Phenom&#x1D49;Cloud" src={require(`../../../image/AdvisoryService/${bannerImage}.webp`)} className="img-size" />
                  </div>
                </div>
                <div className="col-md-6 col-sm-12 col-12">
                  {
                    desc.map((data, index) => (
                      <div className="head_on_desc_body mb-3">

                        {data.Desc &&
                          data.Desc.map((value, index1) => (
                            <div className="explore_service_section" key={index1}>
                              <div className="d-flex align-items-center mb-2" key={index}>
                                <div>
                                  <p className="normal-text-font" dangerouslySetInnerHTML={{ __html: value.text }} ></p>
                                </div>

                              </div>
                            </div>

                          ))
                        }
                        {data.ul &&
                          <ul>{
                            data.ul.map((value, index1) => (
                              <li className="normal-text-font" dangerouslySetInnerHTML={{ __html: value.li }} key={index1}></li>
                            ))}
                          </ul>
                        }
                      </div>
                    ))
                  }
                </div>

              </div>
            ) : ''}

            {
              (id == 2) && (<div className={`row d-flex justify-content-center align-items-center  px-5 Cloud_Solution_cta_section   py-5`}>
                <div className="col-md-12 d-flex flex-column justify-content-center align-items-center">
                  <h1 className="Cloud_sloution_cta_header text-center fw-bold ">{title}</h1>
                  <h3 className="Cloud_sloution_cta_Subheader text-center mb-3">{desc}</h3>
                  <button className="cta_btn"> {
                    cta_link === '/contact' ? (
                      <Link to={cta_link}>{button}</Link>
                    ) : (
                      <Link to={cta_link}>{button}</Link>
                    )
                  }</button>
                </div>
              </div>
              )
            }

            {
              (id == 3) && (
                <div className="row d-flex justify-content-center align-items-center bg-blue">
                  {title && <h3 className="text-center fw-bold hcm_offer_header mt-4" >{title}</h3>}
                  {
                    desc.map((value, items) => (
                      value && <p key={items} className="text-center service_desc" style={{ width: '50%', fontSize: '16px' }} data-aos={"fade-up"}>{value.text}</p>
                    ))
                  }

                  <div className="card-section-content row px-5 justify-content-center text-center">
                    {
                      card.map((data, index) => {

                        const isLastCard = index === card.length - 1;

                        return (
                          <div className={`col-md-4 mb-4 TwoCardWidth ${isLastCard ? 'col-md-6' : ''}`}>
                            <div className='card text-center erp-card-radius card-bg-color py-3 px-3 h-100 erp-card-background Description-Fontsize card-fix-height'>
                              <div className='cardImg text-start d-flex justify-content-between align-items-center'>
                                <p className="fw-bold">{data.card_name}</p>
                                <img alt="Application Managed Services | Phenom&#x1D49;Cloud" src={require(`../../../image/HCMLearning/${data.icon}`)} srcset="" className='icon-box' />
                              </div>
                              <div className="card-body px-1 d-flex flex-column text-start justify-content-center">
                                <p className="Description text-start m-0" dangerouslySetInnerHTML={{ __html: data.card_desc }}></p>
                              </div>
                            </div>
                            {
                              data.card_url && (
                                <div className="col-md-12 d-flex justify-content-end align-items-center">
                                  <Link style={{ marginRight: '2%', textDecoration: 'none' }} to={data.card_url}>
                                    <p>{data.card_btn}</p>
                                  </Link>
                                  <Link to={data.card_url}>
                                    <button className="btn card-btn-color">
                                      <FontAwesomeIcon icon={faArrowRight} style={{ color: "#ffffff" }} />
                                    </button>
                                  </Link>
                                </div>
                              )
                            }
                          </div>
                        );
                      })
                    }
                  </div>


                </div>
              )
            }

            {id === 4 && (
              <div className={`row d-flex justify-content-center align-items-center  px-5 Cloud_Solution_cta_section  light_blue_bg py-5`}>
                <div className="col-md-12 d-flex flex-column justify-content-center align-items-center">
                  <h1 className="Cloud_sloution_cta_header text-center fw-bold ">{Header}</h1>
                  <h3 className="Cloud_sloution_cta_Subheader text-center mb-3">{desc}</h3>
                  {
                    btn_link &&
                    <button className="cta_btn">
                      {
                        btn_link === '/contact' ? (
                          <Link to={btn_link}>{button}</Link>
                        ) : (
                          <Link to={btn_link}>{button}</Link>
                        )
                      }
                    </button>

                  }
                </div>
              </div>
            )}

            {id == 5 && (
              <div className="row d-flex justify-content-around mt-3 mb-3">
                <div className="col-lg-11 col-md-11 col-sm-11 col-11 px-5 padding_fix">
                  <div className=" ">
                    <div className="">
                      <p className="text-center fw-bold" style={{ fontSize: '25px' }} dangerouslySetInnerHTML={{ __html: title }}></p>
                      {
                        desc.map((data, id) => (
                          <p className='line-_height text-center'>{data.text}</p>
                        ))
                      }
                    </div>
                    <div className="">
                      <div className="row d-flex justify-content-center Advisory_service">
                        
                      <div className="col-lg-6 col-md-6 col-sm-12 col-12 text-center dataManagement_Acrd">
                          <img alt="Application Managed Services | Phenom&#x1D49;Cloud" src={require(`../../../image/HCM Coe/Enterprise Integrations/${bannerImage}`)} className=" accordian_img" />
                        </div>



                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 d-flex flex-column justify-content-center " style={{ padding: '16px' }}>
                          {<Accordion className='d-flex flex-column EcCoe'>
                            {accordians.map((item, index) => {
                              const { header, desc } = item;
                              return (
                                <Accordion.Item eventKey={index.toString()} className='mb-2 acoordian_items' key={index} style={{ border: 'none', background: '#E1EDFE' }} >
                                  <AccordionButton className='Dropdown-Bg-Color'
                                    onClick={() => handleToggle(index.toString())}
                                    aria-expanded={expandedItem === index.toString()}
                                  >
                                    <div className="d-flex justify-content-between align-items-center w-100">
                                      <span className='fw-bolder sm-font-size'>{header}</span>
                                      {expandedItem === index.toString() ? <GoHorizontalRule /> : <GoPlus className='PlusIcon' />}
                                    </div>
                                  </AccordionButton>

                                  <Accordion.Body key={index} className='py-1'>
                                    {
                                      desc && desc.map((item, index) => (
                                        <p className='text-start' dangerouslySetInnerHTML={{ __html: item.text }} key={index}></p>
                                      ))
                                    }
                                  </Accordion.Body>

                                </Accordion.Item>
                              );
                            })}
                          </Accordion>
                          }
                        </div>
                       
                       
                      
                      </div>
                    </div>
                    {
                      <div className='d-flex  flex-column align-items-center padding_fix'>
                        <p className='text-center' dangerouslySetInnerHTML={{ __html: subDesc }} ></p>
                      </div>
                    }
                  </div>
                </div>
              </div>
            )

            }




          </div>
        );
      })}


      <GetStarted />

    </>
  )
}

export default ManagedServices