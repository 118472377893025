import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Banner } from './Banner';
import LazyLoad from 'react-lazyload';
import { Button, Form } from 'react-bootstrap';
import '../style/careers.css'
import Modal from 'react-modal';
import axios from 'axios';

const JobDetails = () => {

  useEffect(() => {
    // Set the app element after the component mounts
    Modal.setAppElement('#root'); // '#root' should be the ID of your main app div or element
  }, []);

    // modal 
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formData, setFormData] = useState({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      resume: null,
      coverLetter: null,
      description: '',
    });
  
    // Handle opening the modal when "Apply" button is clicked
    const openModal = () => setIsModalOpen(true);
  
    // Handle closing the modal
    const closeModal = () => setIsModalOpen(false);
  
    // Handle form input changes
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    };
  
    // Handle file input changes
    const handleFileChange = (e) => {
      // const { name, files } = e.target;
      // setFormData({
      //   ...formData,
      //   [name]: files[0],
      // });
      const { name, files } = e.target;
      const file = files[0];
  
      if (file) {
        // Convert the file to base64
        const reader = new FileReader();
        reader.onloadend = () => {
          setFormData({
            ...formData,
            [name]: reader.result, // Store base64 string of the file
          });
        };
        reader.readAsDataURL(file); // Read the file as base64
      }
    };
  
    // Handle form submission
    const handleSubmit = (e) => {
     
      e.preventDefault();

      // Check if all required fields are filled
      if (!formData.firstName || !formData.lastName || !formData.email || !formData.phone || !formData.resume || !formData.coverLetter) {
        alert('Please fill in all required fields!');
        return;
      }
  
      // Create the data object to send to the backend
      const dataToSend = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        phone: formData.phone,
        description: formData.description,
        resume: formData.resume, // base64 encoded resume
        coverLetter: formData.coverLetter, // base64 encoded cover letter
      };
  
      // Log the data to see what is sent
      // console.log('Data to send to backend:', dataToSend);
      // axios.post('http://localhost:5033/api/Careers,',dataToSend)
      // .then((response) => {
      //   setMessage(response.data);  // Set the API response in the state
      // })
      // .catch((error) => {
      //   console.error("There was an error making the request!", error);
      // });
  
      // Send data to backend via API (you can use Axios or Fetch here)
      // Example using Axios:
      // axios.post('/your-api-endpoint', dataToSend)
      //   .then(response => {
      //     console.log('Response:', response);
      //     closeModal();
      //   })
      //   .catch(error => {
      //     console.error('Error:', error);
      //   });
  
      // Close the modal after submission
      closeModal();
    };
    // modal 

 
  const { jobCode } = useParams();

  useEffect(() => {
    // Scroll to the top of the page when the component is mounted
    window.scrollTo(0, 0);
  }, []);

  const jobDetails = {
    'sr-dotnet-developer': {
        title: 'Sr .Net Developer',
        category: 'Engineering',
        type: 'Full Time',
        location: 'Vijayawada',
        shift: 'IST Time Zone – (Day Shift)',
        summary: 'Phenomecloud is looking for experienced .NET Developer, for Vijayawada location, with extensive hands-on experience in designing and developing enterprise applications and complex data models. In addition to expert .NET skills, a successful candidate should be able to communicate extensively with our business clients and work closely with other technical teams. The.NET Developer will also be a primary contributor to the software and database architecture of new applications being built. IN addition to vast technical experience, the candidate must have polished communication skills with the capability of presenting in front of both technical and non-technical audiences.',
        responsibilities: [
          'Guides the team that will perform software coding, testing, debugging, documentation, and installation tasks in accordance with industry best practices and specific internal procedures and standards.',
          'Responsible for task identification, work effort estimates, and work schedules for development and maintenance activities.',
          'Guides the analysis, design, and definition of efficient, cost-effective application solutions which support client business processes and functional requirements.',
          'Specifically, you will partner with both business clients, development team and inter-departmental teams (e.g. database development, network support, help desk) to ensure deliverable commitments are met.',
          'Conducts analysis of client business processes and functional requirements and prepares appropriate documentation to communicate and validate the information.',
          'Understand, document and convert legacy applications into .NET based web applications',
          'Provide leadership and technical guidance to other developers on the team',
          'Having experience with object-oriented solid design patterns/principles',
        ],
        skills: [
          'Bachelors or Masters in Computer Science or Information Technology',
          '5+ Years of experience in building applications using ASP.NET & MVC is a must.',
          'Good hands-on experience on Architectural patterns MVC, MVVM, SOA',
          'Good hands-on experience in C# and OOPS',
          'Good hands-on experience on JQuery & Bootstrap UI',
          'Good experience with SQL stored procedures, functions on MS SQL Server 2008 / Oracle',
          'Good Knowledge of LINQ and REST API',
          'Knowledge of Angular JS is a plus',
          'Excellent communication skills, strong problem-solving abilities and is passionate about software development.',
        ],
        contact: '+91 8688832015',
        email: 'talent@pixentia.com'
      }, 
    'jr-dotnet-developer': {
          title: 'Jr .Net Developer',
          category: 'Engineering',
          type: 'Full Time',
          location: 'Vijayawada',
          shift: 'IST Time Zone – Day Shift',
          summary: 'Phenomcloud is looking for .NET Developer, for Vijayawada location, with extensive hands-on experience on designing and developing enterprise applications and complex data models. In addition to expert .NET skills, a successful candidate should be able to communicate extensively with our business clients and work closely with other technical teams. The .NET Developer will also be a primary contributor to the software and database architecture of new applications being built. In addition to vast technical experience, the candidate must have polished communication skills with the capability of presenting in front of both technical and non-technical audiences.',
          responsibilities: [
            'Guides the team that will perform software coding, testing, debugging, documentation, and installation tasks in accordance with industry best practices and specific internal procedures and standards.',
            'Responsible for task identification, work effort estimates, and work schedules for development and maintenance activities.',
            'Guides the analysis, design and definition of efficient, cost-effective application solutions which support client business processes and functional requirements.',
            'Specifically, you will partner with both business clients, development team and inter-departmental teams (e.g. database development, network support, help desk) to ensure deliverable commitments are met.',
            'Conducts analysis of client business processes and functional requirements and prepares appropriate documentation to communicate and validate the information.',
            'Understand, document and convert legacy applications into .NET based web applications.',
            'Provide leadership and technical guidance to other developers on the team.',
            'Having experience with object-oriented solid design patterns/principles.'
          ],
          skills: [
            'Bachelors or Masters in Computer Science or Information Technology',
            'Building applications using ASP.NET MVC is a must.',
            'Good hands-on experience with Architectural patterns MVC, MVVM, SOA',
            'Good hands-on experience with C# and OOPS',
            'Good hands-on experience with JQuery & Bootstrap UI',
            'Good experience with SQL stored procedures, functions on MS SQL Server 2008 / Oracle',
            'Good Knowledge of LINQ and REST API',
            'Knowledge of Angular JS is a plus',
            'Excellent communication skills, strong problem-solving abilities, and is passionate about software development.'
          ],
          contact: '+91 8688832015',
          email: 'info@phenomecloud.com'
         
      },
      'application-architect': {
        title: 'Application Architect',
        category: 'Professional Services',
        type: 'Full Time',
        location: 'Vijayawada',
        shift: 'IST Time Zone – Day Shift',
        summary: 'To design and create major aspects of the architecture of an application, including user interface, middleware, and infrastructure; to provide technical leadership to the applications development team; to provide design and code reviews; to ensure that uniform enterprise-wide application design standards are maintained; and to collaborate with other stakeholders to ensure architecture is aligned with business requirements.',
        responsibilities: [
          'Evaluate applications development needs and apply technical principles and concepts to develop business solutions.',
          'Analyze procedures and data to develop logical solutions to complex applications programming problems.',
          'Recommend, design, and develop complex applications and supporting hardware and software.',
          'Perform complex application analysis design and development duties.',
          'Evaluate, test, implement, and support new operating systems and hardware.',
          'Evaluate application development practices and create development standards, policies, and procedures.',
          'Create application design standards.',
          'Conduct meetings with customers and transform requirements into effective applications.',
          'Monitor quality assurance and security procedures for business applications.',
          'Ensure application design and development policies are adhered to.',
          'Independently perform complex application development/programming analysis.',
          'Provide detailed design and specification documentation for all applications and databases.',
          'Develop and maintain database standards.',
          'Provide project management, oversight, leadership, and support.',
          'Represent Information Technology at advisory and user group meetings.',
          'Verify stability, interoperability, portability, security, and scalability of application architecture.',
          'Install, research, test, and verify the proper functioning of software updates.',
          'Research new features, software, and tools.',
          'Generate documentation for staff and training.',
          'Create and enforce database standards and procedures.',
          'Create and enforce security standards.',
          'Keep up-to-date on emerging application design and database architectures, technologies, and methodologies, and attend training classes as necessary.',
          'Must demonstrate attendance sufficient to complete the duties of the position as required.',
          'Perform related duties similar to the above in scope and function as required.'
        ],
        skills: [
          'Principles and practices of application testing, analysis, and security administration.',
          'Methods and techniques used to design, develop, test, and implement applications.',
          'Methods and techniques for evaluating business requirements and developing information systems solutions.',
          'Principles and practices of programming languages and operating systems.',
          'Project management and reporting.',
          'Methods and techniques for developing complex application technical manuals and documentation.',
          'Microsoft software development best practices.',
          'Microsoft .NET Framework fundamentals.',
          'Visual Studio .NET Development Environments.',
          'MVC Framework',
          'Structured Query Language (SQL).',
          'Database design and development',
          'Microsoft SQL Server 2000 and above.',
          'Active Server Pages.',
          'JavaScript.',
          'HyperText Markup Language (HTML).',
          'Crystal Reports.',
          'Business Objects Web Intelligence.',
          'Windows XP and above.',
          'Microsoft Windows Server 2000 and above.'
        ],
        contact: '+91 8688832015',
        email: 'info@phenomecloud.com'
      },
      'project-manager': {
        title: 'Project Manager',
        category: 'Professional Services',
        type: 'Full Time',
        location: 'Vijayawada',
        shift: 'US Time Zone (Night Shift)',
        summary: 'To work within the business management team to manage the delivery of a range of projects and engage with stakeholders both internal and external.',
        description: [
          'To manage the delivery of projects through the complete lifecycle from aspiration through to Operations. This includes ensuring that the project is delivered on time, we meet business & technical requirements and is within the agreed budget.',
          'Need to create and maintain effective working relationships with all stakeholders.',
          'To understand the high-level technical architecture and be able to facilitate debate and drive decisions on appropriate solutions.',
          'To contribute to the portfolio and delivery planning for Internet Infrastructure.',
          'To ensure that company requirements are produced to standard in a timely manner.'
        ],
        responsibilities: [
          'To be responsible for the project administration and the production of project documentation, e.g. project plans.',
          'To monitor and manage the project process and track progress against milestones, deadlines, and budget. Report on these items to key stakeholders.',
          'To anticipate, manage and resolve issues and act as the point of contact for the project.',
          'To use best practice risk management, assessing risks in an informed and considered manner and to plan for the mitigation of these risks.',
          'To run in-house projects on a day-to-day basis and take responsibility for their delivery using agile and more formal techniques as appropriate.'
        ],
        knowledgeSkillsAbilities: [
          'Track record of delivering projects within a technically complex and dynamic environment.',
          'Experience of successfully engaging senior management and groups of stakeholders.',
          'Working knowledge of Programme Management, e.g. Managing Successful Programmes (MSP), and agile project management tools and methodologies.',
          'Experience of successfully managing the delivery of multiple projects.',
          'Experience of managing business change.',
          'Able to demonstrate strong management skills including project management, financial management, change management, and facilitation.'
        ],
        personalAttributes: [
          'Ability to cope in a technically complex and fast-changing environment, and to respond calmly and rationally to changing aspirations in a deadline-driven situation.',
          'Strong planning and organizing skills including the ability to manage several workstreams simultaneously.',
          'Determination and focus to drive projects through to implementation.',
          'Excellent communication skills with a capacity to present, discuss and explain issues coherently and logically both in writing and verbally.',
          'Good influencing and persuasion skills with the ability to enthuse and inspire multidisciplinary teams and build successful relationships at all levels.',
          'Good team player, self-motivated and able to work on own initiative.',
          'Computer literate, able to quickly and efficiently use a wide range of common Office software including Word, Excel, Project and PowerPoint.',
          'Clear decision-making ability with the facility to judge complex situations and assess when to escalate issues.',
          'Ability to balance conflicting and changing demands through prioritization and a pragmatic approach.'
        ],
        skills: [
          'Great educational background, preferably in the fields of computer science or engineering for technical project managers.',
          'Proven working experience as a project administrator in the information technology sector.',
          'Solid technical background, with understanding or hands-on experience in software development and web technologies.',
          'Excellent client-facing and internal communication skills.',
          'Excellent written and verbal communication skills.',
          'Solid organizational skills including attention to detail and multi-tasking skills.',
          'Strong working knowledge of Microsoft Office.',
          'PMP/Prince2 certification is Mandatory.'
        ],
        contact: '+91 8688832015',
        email: 'info@phenomecloud.com'
      },
      'sr-business-analyst': {
    title: 'Business Analyst',
    category: 'Professional Services',
    type: 'Full Time',
    location: 'Vijayawada',
    shift: 'US & UK Time Zone',
    summary: 'Phenom<sup>e</sup>cloud offers a full line of innovative business services and solutions. We are looking for a dynamic, energetic and organized Business Analyst to work at our Vijayawada location.',
    detailedSummary: 'The ideal candidate is responsible for customer engagement, analysis of client requirements and works with engineering teams in the implementation of the requirements.',
    responsibilities: [
      'Work effectively with Talent Leaders and executive staff to define and translate high-level business requirements into functional specifications.',
      'Ability to handle projects on different domains (Retail, Education, E-Learning, HR).',
      'Work with customers to solve day-to-day business problems, and have an eye for spotting new ones.',
      'Interact with technology, HR functional areas, and HR Service Centers to formulate and implement solutions to enhance existing systems, services, create new deliverables and improve processes.',
      'Evaluate the quality and effectiveness of new processes, systems and/or modifications to existing solutions.',
      'Provide ongoing support and troubleshooting for various HR systems and others as assigned.',
      'Equally responsible for explaining complex technical issues to customers and communicating detailed requirements to engineering teams.',
      'Maintain confidentiality of customer data.'
    ],
    skills: [
      'Bachelors or Masters in Computer Science, Information Technology or Business preferred.',
      '3+ years’ experience in the Business analyst role.',
      'Knowledge of MS Office/MS Visio/MS Project is required.',
      'Candidates should have demonstrated knowledge and understanding of application programming, database, and system design.',
      'Ability to function independently and as a team player in a fast-paced environment required.',
      'Must have strong written and verbal communication skills.',
      'Excellent Customer service and organizational skills.',
      'Experience with Human Resource and workforce administration concepts.',
      'Experience with Talent Management Systems/Human Capital management Systems like On-Boarding, Hiring, Learning, Performance, Payroll, and Succession is a plus.'
    ],
    contact: '+91 8688832015',
    email: 'info@phenomecloud.com'
      },
  'sr-content-writer': {
    title: 'Content Writer',
    category: 'Marketing',
    type: 'Full Time',
    location: 'Vijayawada',
    shift: 'IST Time Zone – Day Shift',
    summary: 'Phenom<sup>e</sup>cloud offers a full line of innovative business services and solutions. We are looking for a dynamic and energetic experienced Content Writer to work at our Vijayawada, India location.',
    responsibilities: [
      'Responsible to produce new e-learning scripts and content based on client needs.',
      'Participates in the instructional development process by testing/editing materials and providing feedback to peers.',
      'Cast and develop video scripts and shoot/edit them.',
      'Use advanced writing skills to design, schedule, write and edit guides, online help, and release notes, adhering to standards.',
      'Contributes to the creation of documentation and e-learning standards.',
      'Ensures technical documentation is accurate, complete, and meets editorial and client standards.'
    ],
    skills: [
      'Bachelor’s degree in Communications, English or equivalent experience of two years of solid writing and editing skills.',
      'Experience in video casting and presenting as host in e-learning modules.',
      'Experience in content writing, e-learning storyboard writing, product documentation, and Technical Readiness documentation is a plus.',
      'Experience in developing course narration script, and record narration.',
      'Excellent written and verbal communication skills.',
      'Experience in design and development of mobile learning is a plus.',
      'Experience in managing end-to-end instructional design process is a plus.',
      'Experience with HTML, Graphic design, and Photoshop is not required but a plus.',
      'ASQ Training Development knowledge is not required but a plus. Proficient in course authoring tools is not required but a plus.'
    ],
    contact: '+91 8688832015',
    email: 'info@phenomecloud.com'
  },
  'sr-database-developer': {
    title: 'Database Developer',
    category: 'Engineering',
    type: 'Full Time',
    location: 'Vijayawada',
    shift: 'IST Time Zone – Day Shift',
    summary: 'Phenom<sup>e</sup>cloud is looking for a dynamic, energetic and organized Database Developer. The ideal candidate is responsible for understanding the business requirements and supporting the project and support teams by implementing technical systems based on those requirements.',
    responsibilities: [
      'Help write and optimize in-application SQL statements',
      'Ensure performance, security, and availability of databases',
      'Prepare documentation and specifications',
      'Handle common database procedures such as upgrade, backup, recovery, migration, etc.',
      'Profile server resource usage and optimize and tweak as necessary',
      'Collaborate with other team members'
    ],
    skills: [
      'Strong proficiency with SQL and its variation among popular databases. Experience with some of the modern relational databases.',
      'Skilled at optimizing large complicated SQL statements.',
      'Knowledge of best practices when dealing with relational databases.',
      'Capable of configuring popular database engines and orchestrating clusters as necessary.',
      'Ability to plan resource requirements from high-level specifications.',
      'Capable of troubleshooting common database issues.',
      'Familiar with tools that can aid with profiling server resource usage and optimizing it.'
    ],
    contact: '+91 8688832015',
    email: 'info@phenomecloud.com'
  },
  'digital-marketing-executive': {
    title: 'Digital Marketing Executive',
    category: 'Marketing',
    type: 'Full Time',
    location: 'Vijayawada',
    shift: 'US Time Zone',
    summary: 'Phenom<sup>e</sup>cloud is looking for a dynamic, energetic, and organized Inbound Marketing Executive to work at our Vijayawada, India location. The role involves lead generation, email campaigns, social media promotion, SEO, and content marketing.',
    responsibilities: [
      'Develop Lead Generation using email campaigns',
      'Identify target companies and grow the email list',
      'Develop various email templates and messaging campaigns',
      'Social Media promotion on LinkedIn, Twitter, and other platforms',
      'Promotion of blogs, content marketing & SEO',
      'Creating landing pages for various campaigns',
      'Hands-on SEO experience with a strong technical focus',
      'Knowledge about Google Webmaster tool & Google Analytics',
      'Knowledge of Meta tags/Title tag changes',
      'Keyword Research/Analysis, Competitor Analysis',
      'On-page/Off-page activities',
      'Social Bookmarking, classified submission, Business Listing',
      'Blog creation and submission',
      'Creating weekly and monthly reports',
      'Work closely with other team members to meet client goals',
      'Google AdWords campaigns setup',
      'Knowledge of SEO, SMO, SMM, B2B & B2C, PPC Campaigns, Google Analytics, Affiliate Marketing, Email Etiquettes, etc.',
      'Excellent knowledge in CRM tools - Zoho, HubSpot',
      'Experience with Email Marketing tools - Mailchimp, Constant Contact, Zoho',
      'LinkedIn Marketing, Blog & Content Marketing, Twitter Marketing'
    ],
    skills: [
      'Bachelor\'s Degree/MBA (Marketing) related to the Marketing field',
      'Certification in Digital marketing would be a plus',
      'Strong verbal and written communication skills in English',
      'Candidate should be self-motivator and disciplined'
    ],
    contact: '+91 8688832015',
    email: 'info@phenomecloud.com'
  },
  'functional-analyst': {
    title: 'Functional Analyst',
    category: 'Professional Services',
    type: 'Full Time',
    location: 'Vijayawada',
    shift: 'US Time Zone (Night Shift)',
    summary: 'Phenom<sup>e</sup>cloud is looking for a dynamic, energetic, and organized Functional Analyst to work at our Vijayawada location. The role involves analyzing and understanding technical information, project planning, conducting analysis, measuring performance, and developing process improvements.',
    responsibilities: [
      'Strong at Microsoft Technologies (.Net, C#, MVC)',
      'Analyze existing systems and highlight areas of improvement',
      'Identify new software/service opportunities',
      'Perform gap analysis and recommend solutions',
      'Fulfill the role of a subject matter expert in requirement elicitation',
      'Document requirements, create functional specifications, and prepare wireframes/flowcharts',
      'Oversee the development of products as per the specifications',
      'Facilitate change and configuration management',
      'Monitor performance, discover variances, and propose necessary actions',
      'Validate functionality of the end product according to requirements',
      'Prepare technical user manuals, system configuration documents, and other technical reports'
    ],
    skills: [
      'Thorough knowledge of the area of specialization',
      'Analytical and investigation skills',
      'Ability to understand the bigger picture',
      'Working knowledge of data and process modeling',
      'Elicitation and comprehension skills',
      'Grip over Microsoft Technologies',
      'Good command over spoken and written communication',
      'Organizational and presentation skills',
      'Interpersonal and managerial skills'
    ],
    contact: '+91 8688832015',
    email: 'info@phenomecloud.com'
  },
  'inside-sales-representative': {
    title: 'Inside Sales Representative',
    category: 'Sales',
    type: 'Full Time',
    location: 'Vijayawada',
    shift: 'US Time Zone',
    summary: 'This high-energy, analytical, and well-organized Inside Sales Representative will play a key role in Phenom<sup>e</sup>cloud\'s sales and account management organization. The role supports new customer acquisition, revenue growth, and customer retention by qualifying prospects, making calls, generating interest, and deepening customer relationships. Ideal for an ambitious individual who thrives in a fast-paced environment and wants to grow their skills.',
    responsibilities: [
      'Make 40+ outbound calls daily to follow up with leads or engage existing customers to build strong relationships',
      'Research companies, understand needs, identify key players, and generate interest with effective phone calls',
      'Route the qualified opportunities in terms of booked appointments to the sales executives for further development and closure',
      'Manage relationships with existing accounts, engage them regularly, and seek opportunities to up-sell, cross-sell, and generate referrals'
    ],
    skills: [
      '3+ years experience in Services and Support roles, such as Customer Success, Inside Sales, and/or Consulting',
      'Exceptional organizational and time management skills',
      'Excellent written and verbal communication skills',
      'Strong phone presence and ability to make dozens of calls per day',
      'Ability to multi-task, prioritize, and manage time effectively',
      'Proven coordination skills',
      'Experience with CRM systems like HubSpot is a plus',
      'Expertise with MS Office products, especially Excel, PowerPoint, and Outlook',
      'Strong interpersonal skills and the ability to work effectively with a wide range of individuals in a diverse community',
      'BBA/MBA or equivalent'
    ],
    contact: '+91 8688832015',
    email: 'info@phenomecloud.com'
  },
  'network-administrator': {
    title: 'Network Administrator',
    category: 'IT Support',
    type: 'Full Time',
    location: 'Vijayawada',
    shift: 'IST Time Zone – Day Shift',
    summary: 'Phenom<sup>e</sup>cloud is seeking a dynamic, energetic, and organized Network Administrator to work at our Vijayawada location. The ideal candidate will be responsible for developing and maintaining innovative software applications and overseeing network administration. The role includes ensuring the smooth operation of network systems, managing server infrastructures, and handling security measures.',
    responsibilities: [
      'Assist in the development, implementation, and maintenance of policies, procedures, and training plans for network resource administration, appropriate use, and disaster recovery.',
      'Install, configure, maintain, and troubleshoot end-user workstation hardware, software, and peripheral devices.',
      'Manage vendor and contractor relationships.',
      'Conduct research on network products, services, protocols, and standards in support of network procurement and development efforts.',
      'Oversee activities of the Sys Admin team.',
      'Manage servers, including e-mail, print, and backup servers and their associated operating systems and software.',
      'Manage security solutions, including firewall, anti-virus, and intrusion detection systems.',
      'Manage all network hardware and equipment, including routers, switches, and UPSs.',
      'Ensure network connectivity of all workstations.',
      'Administer all equipment, hardware, and software upgrades.'
    ],
    skills: [
      'Any degree and 2-4 years of relevant work experience.',
      'Certifications in Microsoft, VMware, Cisco and/or Citrix technologies are highly desirable.',
      'Working technical knowledge of network, PC, and platform operating systems: Microsoft Windows 7, Server 2003/2008/2008 R2, VMware vSphere/ESXi, Linux.',
      'Host level security practices.',
      'Monitoring through SNMP, Syslog.',
      'Specific expertise with Exchange 2010, IIS 7, Citrix.',
      'Working technical knowledge of current systems software, protocols, and standards including firewalls, Active Directory, DHCP, DNS, SMTP, and SNMP.',
      'Experience with database systems, especially Microsoft SQL Server 2008 R2 and SQL Server 2012.',
      'Knowledge of data privacy practices and laws, including HIPAA and HITECH, is highly desirable.',
      'Ability to conduct research into networking issues and products.',
      'Strong interpersonal skills. Ability to present ideas in user-friendly language.',
      'Highly self-motivated and directed.',
      'Proven analytical and problem-solving abilities.',
      'Ability to prioritize and execute multiple responsibilities.',
      'Desire to work in a team-oriented, collaborative environment.'
    ],
    contact: '+91 8688832015',
    email: 'info@phenomecloud.com'
  },
  'sr-quality-analyst': {
    title: 'Quality Analyst',
    category: 'Engineering',
    type: 'Full Time',
    location: 'Vijayawada',
    shift: 'IST Time Zone – Day Shift',
    summary: 'Phenom<sup>e</sup>cloud is looking for a dynamic, energetic, and organized Quality Analyst. The ideal candidate is responsible for ensuring standard quality practices are followed, conducting end-to-end testing on applications, and ensuring that business, technical, and functional requirements are met throughout the SDLC. The candidate will design and execute test scripts to identify defects before production.',
    responsibilities: [
      'Develop and document application test plans based on software requirements and technical specifications.',
      'Design and execute automated test scripts (manual/automated) on applications, including web-based.',
      'Record and document test results and test summary status.',
      'Detect software failures so that defects may be discovered and corrected.',
      'Maintain a database of software defects/test scripts.',
      'Generate analysis and reporting of historical test results.',
      'Document anomalies, issues, and potential gaps of applications tested.',
      'Contribute to best IT practices and document suggestions. Other related duties as assigned.'
    ],
    skills: [
      '2-4 Years (2 years of experience in Manual Testing & 1 year of experience in Automation using Selenium).',
      'Experience in the design, development, and maintenance of test cases, test plans, and test scripts.',
      'Ability to develop and document application test plans based on software requirements and technical specifications.',
      'Able to participate in an IT team in a dynamic, fast-paced environment.',
      'Demonstrated ability to effectively and efficiently support multiple projects/priorities with varying complexity.',
      'Strong troubleshooting skills.',
      'Excellent verbal and written communication skills.',
      'Exceptional interpersonal skills; team player.',
      'Practical technical testing and business domain experience relative to custom and/or vendor applications required.',
      'Proficiency with Microsoft Office.',
      'Foster strong and collaborative relationships with the team, internal business partners, and external vendors.'
    ],
    contact: '+91 8688832015',
    email: 'info@phenomecloud.com'
  },
  'technical-analyst': {
    title: 'Technical Analyst',
    category: 'Engineering',
    type: 'Full Time',
    location: 'Vijayawada',
    shift: 'US & UK Time Zone',
    summary: 'Phenom<sup>e</sup>cloud is looking for dynamic, energetic, and organized Technical Analysts to work at our Vijayawada location. The ideal candidate must be collaborative, passionate, and committed to continuous improvement, with a relentless drive to win.',
    responsibilities: [
      'Interpret functional specification and convert it into a technical specification.',
      'Time estimation based on development specifications for developments to be done.',
      'Develop new components, customize existing components based on technical specifications.',
      'Design an effective technical architecture and develop it effectively, assisting in incorporating new technologies into IT systems and monitoring production environments.',
      'Coordinate with all business and IT partners, analyze all business requirements of clients, and provide resolutions for business and IT issues.',
      'Assist business partners to analyze changes in requirements and provide enhancements to all applications and provide support.',
      'Testing of customizations/new developments as per user requirements.',
      'Analysis of existing components and optimization for performance.',
      'Troubleshooting and debugging errors arising out of the application.',
      'Development and testing according to Documentation.'
    ],
    skills: [
      'Sound knowledge of DotNet, C# & MVC.',
      'Good analytical and problem-solving skills.',
      'Aptitude in understanding both the business and IT sides in problem-solving.',
      'Flexible attitude.',
      'Good at self-teaching and desire to continuously learn.',
      'Good communication skills (both written and verbal).'
    ],
    contact: '+91 8688832015',
    email: 'info@phenomecloud.com'
  },
  'ui-developer': {
    title: 'UI Developer',
    category: 'Creative',
    type: 'Full Time',
    location: 'Vijayawada',
    summary: 'Phenom<sup>e</sup>cloud offers a full line of innovative business services and solutions. We are looking for a dynamic, energetic, and organized UI Developer with 2 – 5 years of experience in Responsive web design to work at our IDC, Vijayawada. The ideal candidate is required to develop and/or maintain innovative software applications.',
    responsibilities: [
      'Responsive web design practices to deliver high quality software for our customers.',
      'Collaborate on wireframes and design drafts and turn these into well-implemented, functional user interfaces.',
      'Produce mockups and designs in Photoshop and implement them using HTML and CSS.',
      'Work with the back-end development team to implement interactive and AJAX-based functionality.',
      'Deliver on-time with high quality.',
      'Focus on customers’ needs and desires to build technology solutions to address them.'
    ],
    skills: [
      'Any Degree.',
      '2 - 5 Years of experience in Responsive web design.',
      'Expert-level proficiency in user interface and user experience (UI/UX) design with a specialty in designing interfaces for web apps.',
      'Proficient graphic design skills using Photoshop, etc.',
      'Excellent knowledge and understanding of HTML5, CSS3, and cross-browser support.',
      'Proficiency in object-oriented JavaScript, including one or more frameworks (jQuery).',
      'Skilled in debugging and problem solving using relevant tools (Firebug, etc.).',
      'Track record/portfolio of high-quality web application interfaces and design implementations.',
      'Experienced in the use of appropriate development tools including source code management.',
      'Excellent technical communication, both verbal and written.'
    ],
    contact: '+91 8688832015',
    email: 'info@phenomecloud.com'
  },
  'ux-designer': {
    title: 'UX Designer',
    category: 'Creative',
    type: 'Full Time',
    location: 'Vijayawada',
    summary: 'Phenom<sup>e</sup>cloud is seeking an energetic and organized UX Designer to work at our Vijayawada, India location. The ideal candidate will help us change the way people think about User Interfaces and be responsible for envisioning and designing interfaces for the next generation of software applications. This role closely works with engineering teams to craft user interfaces suited for our clients and application needs.',
    responsibilities: [
      'Work full-time in a highly collaborative environment with other skilled software professionals to deliver clients exceptional software and tools to help them improve the way they do business.',
      'Design great user experiences, working alongside developers as they turn your vision and design details into world-class custom software products.',
      'Passionate about user experience and designing/building great products.',
      'Identify the most broken parts of an app and propose solutions to improve them.',
      'Demonstrated ability to design user experiences that your team members can readily turn into products.'
    ],
    skills: [
      'Cranks out great wireframes and sitemaps in real-time designs.',
      'Can scale wireframes from low to hi-fi: from whiteboard photos to gorgeous design specs.',
      'Excels at information design: from simple reports to interactive charts/maps.',
      'Visually communicates concepts and designs in a variety of ways.',
      'Design portfolio showcasing strong creative skills, ability to generate solid design ideas, and a thorough understanding of the design process.',
      'Understanding of HTML and CSS.',
      'Experience using industry-standard design tools including Photoshop, PowerPoint, Director, Flash, or blended experience.',
      'Experience working closely with product teams and can easily integrate into the development process to deliver high-quality UX.',
      'Excellent communication and project management skills.',
      'BS/BA (Bachelors/Masters Degree) in Interaction Design, Graphic Design, Industrial Design, or a related design field.',
      '2-3 years of experience as a UX Designer working on a team delivering logos, application layouts, designs, icons, and UI designs.'
    ],
    contact: '+91 8688832015',
    email: 'info@phenomecloud.com'
  },
  'instructional-designer': {
    title: 'Instructional Designer',
    category: 'Professional Services',
    type: 'Full Time',
    location: 'Vijayawada',
    summary: 'Phenom<sup>e</sup>cloud is seeking an energetic and organized Instructional Designer to work at our Vijayawada, India location. The ideal candidate will help us in developing e-learning modules based on the requirements.',
    responsibilities: [
      'Analyze and define content structure, learning objectives, and instructional approach.',
      'Prepare storyboard for presentation, representation, and assessment methods.',
      'Evaluate and recommend authoring tools required for creating engaging and interactive eLearning content that is SCORM/AICC compliant.',
      'Collaborate with subject matter experts and formulate instructional methods that suit the content, using an appropriate mix of interactivity, simulations, visuals, storyboards, videos, and audios.',
      'Convert course material into e-learning content as per the agreed design, structure, and timelines.',
      'Good understanding of adult learning principles.'
    ],
    skills: [
      'Bachelor’s degree in Communications, English, or equivalent experience.',
      'Two years of experience in e-learning development.',
      'Experience in content writing and e-learning development.',
      'Experience in developing e-learning modules using HTML, UI Design, and ActionScript.',
      'Experience in developing interactive gaming modules is a plus.',
      'Experience with mobile learning development is a plus.'
    ],
    contact: '+91 8688832015',
    email: 'info@phenomecloud.com'
  },
  'full-stack-developer': {
    title: 'Full Stack Developer',
    category: 'Engineering',
    type: 'Full Time',
    location: 'Vijayawada',
    summary: 'Perfect role for someone looking to grow with a fast-paced and thriving software services and consulting organization. This Full Stack Developer performs a key role in building and troubleshooting modern enterprise-level web applications, including front-end, back-end implementation, and integrations with other systems. The role is ideal for tech geeks excited by growing skills and gaining experience in a fast-paced environment where responsibilities are only limited by willingness to take ownership.',
    responsibilities: [
      'Designing and building responsive and user-friendly applications in ASP.Net (C#)/MVC, JavaScript, HTML5, CSS3, JavaScript Frameworks (Backbone.js/Ember.js/Angular.js/React.js/Riot.js).',
      'Responsible for developing RESTful Services on ASP.Net Web API 2.0 framework.',
      'Responsible for developing system integration services for SaaS applications.',
      'Build reusable code and libraries for multiple applications.',
      'Developing new UI/UX designs, features, and enhancements.',
      'Contribute to the database design.'
    ],
    skills: [
      '4+ years of software design, development, and applications support in ASP.Net/C#/SQL Server/Visual Studio.Net environment.',
      '4+ years of Web, internet or network-related development experience.',
      '4+ years of UI development (Ajax, jQuery, JavaScript, WebForm).',
      '3+ years of experience with Angular, Knockout, Backbone, jQuery or other JavaScript libraries.',
      '3+ years of experience with API, Web Services, and data transformation.',
      'Experience with system integration technologies and middleware (like MuleSoft), integrating SaaS-based applications.',
      'Knowledge of SaaS-based HCM platforms, like Workday®.',
      'Exceptional organizational and time management skills.',
      'Excellent written and verbal communication skills.',
      'Engineering in Computer Science or Masters in Computer Applications (MCA) or equivalent.'
    ],
    contact: '+91 8688832015',
    email: 'info@phenomecloud.com'
  },
  'sales-analyst': {
    title: 'Sales Analyst',
    category: 'Sales',
    location: 'Irving, TX, US',
    summary: 'Achieves maximum sales profitability, growth, and account penetration within the Human Capital Management solutions marketplace by effectively selling Phenom<sup>e</sup>cloud\'s consulting services. The Sales Analyst personally contacts and secures new business accounts/customers.',
    responsibilities: [
      'Will be responsible for taking ownership of an individual sales quota. You will grow your clientele through prospecting to mid-market companies within our target industries in a specified geographic region. You’ll also leverage social media like LinkedIn to identify and reach potential prospects and build new relationships.',
      'Demonstrates services to existing/potential customers and assists them in selecting those best suited to their needs.',
      'Responsible for development and delivery of product demonstrations.',
      'Responsible for representing the product to customers at field events such as conferences, seminars, etc.',
      'Develops and executes successful targeted territory development plans to help achieve or exceed growth, revenue, and margin targets.',
      'Develops and manages all phases of the business development cycle including managing proposal negotiations, statement-of-work, and completion of all contract details.',
      'Researches sources for developing prospective customers and for information to determine their potential.',
      'Expedites the resolution of customer problems and complaints.',
      'Works closely with product management and engineering to report product performance by providing detailed & timely product feedback and feature requests.',
      'Identifies advantages and compares organization’s products/services.',
      'Plans and organizes personal sales strategy by maximizing the Return on Time Investment.',
      'Supplies management with oral and written reports on customer needs, problems, interests, competitive activities, and potential for new products and services.',
      'Keeps apprised of market conditions, competitive activities, advertising, and promotional trends.'
    ],
    skills: [
      'Establishes, develops, and maintains business relationships with prospective customers in the assigned territory/market segment to generate new business.',
      'Responsible for development and delivery of product demonstrations.',
      'Responsible for representing the product to customers prospects at field events such as conferences, seminars, etc.',
      'Develops and executes successful targeted territory development plans to achieve or exceed growth, revenue, and margin targets.',
      'Develops and manages all phases of the business development cycle, including managing proposal negotiations, statement-of-work, and completion of all contract details.',
      'Researches sources for developing prospective customers and for information to determine their potential.',
      'Expedites the resolution of customer problems and complaints.',
      'Work closely with product management and engineering to be the voice of the customer and to report product performance.',
      'Identifies advantages and compares organization’s products/services.',
      'Plans and organizes personal sales strategy by maximizing the Return on Time Investment.'
    ],
    qualifications: [
      'Must be a business administration graduate with sales and marketing as a major.',
      'Demonstrated aptitude for problem-solving and the ability to determine solutions for customers.',
      'Must be results-oriented and able to work both independently and within a team environment.',
      'Must possess excellent verbal and written communication skills.',
      'Proficiency in using Microsoft Office Suite applications and contact management software.',
      'Willing to travel regularly.',
      'Thesis/Project in human capital technology space is a plus.'
    ],
    idealCandidateTraits: [
      'Confident and articulate communicator who can take command of most business situations.',
      'Ability to build rapport and good working relationships with people at any level in an organization.',
      'Resourceful self-starter who can deliver projects with minimal back-office support.'
    ],
    contact: '+1-903-401-8804',
    email: 'info@phenomecloud.com',
    address: 'Phenom<sup>e</sup>cloud, 1333 Corporate Dr, Suite 212, Irving, TX 75038.',
    eoeStatement: 'Phenom<sup>e</sup>cloud is committed to creating a diverse environment and is proud to be an equal opportunity employer. All qualified applicants will receive consideration for employment without regard to race, color, religion, gender, gender identity or expression, sexual orientation, national origin, genetics, disability, age, or veteran status.'
  },
  'sr-sales-analyst': {
    title: 'Sr Sales Analyst',
    category: 'Sales',
    location: 'Irving, TX, US',
    summary: 'Achieves maximum sales profitability, growth, and account penetration within the Human Capital Management solutions marketplace by effectively selling Phenom<sup>e</sup>cloud\'s consulting services. The Sr Sales Analyst personally contacts and secures new business accounts/customers.',
    responsibilities: [
      'Will be responsible for taking ownership of an individual sales quota. You will grow your clientele through prospecting to mid-market companies within our target industries in a specified geographic region. You’ll also leverage social media like LinkedIn to identify and reach potential prospects and build new relationships.',
      'Demonstrates services to existing/potential customers and assists them in selecting those best suited to their needs.',
      'Responsible for development and delivery of product demonstrations.',
      'Responsible for representing the product to customers at field events such as conferences, seminars, etc.',
      'Develops and executes successful targeted territory development plans to help achieve or exceed growth, revenue, and margin targets.',
      'Develops and manages all phases of the business development cycle including managing proposal negotiations, statement-of-work, and completion of all contract details.',
      'Researches sources for developing prospective customers and for information to determine their potential.',
      'Expedites the resolution of customer problems and complaints.',
      'Works closely with product management and engineering to report product performance by providing detailed & timely product feedback and feature requests.',
      'Identifies advantages and compares organization’s products/services.',
      'Plans and organizes personal sales strategy by maximizing the Return on Time Investment.',
      'Supplies management with oral and written reports on customer needs, problems, interests, competitive activities, and potential for new products and services.',
      'Keeps apprised of market conditions, competitive activities, advertising, and promotional trends.'
    ],
    skills: [
      'Establishes, develops, and maintains business relationships with prospective customers in the assigned territory/market segment to generate new business for Phenom<sup>e</sup>cloud.',
      'Responsible for development and delivery of product demonstrations.',
      'Responsible for representing the product to customers at field events such as conferences, seminars, etc.',
      'Develops and executes successful targeted territory development plans to achieve or exceed growth, revenue, and margin targets.',
      'Develops and manages all phases of the business development cycle, including managing proposal negotiations, statement-of-work, and completion of all contract details.',
      'Researches sources for developing prospective customers and for information to determine their potential.',
      'Expedites the resolution of customer problems and complaints.',
      'Works closely with product management and engineering to be the voice of the customer and to report product performance.',
      'Identifies advantages and compares organization’s products/services.',
      'Plans and organizes personal sales strategy by maximizing the Return on Time Investment.'
    ],
    qualifications: [
      'Must possess 5 years’ experience in the Human Capital Technology Space.',
      'Demonstrated aptitude for problem-solving and the ability to determine solutions for customers.',
      'Must be results-oriented and able to work both independently and within a team environment.',
      'Must possess excellent verbal and written communication skills.',
      'Proficiency in using Microsoft Office Suite applications and contact management software.',
      'Valid driver’s license.'
    ],
    idealCandidateTraits: [
      'Confident and articulate communicator who can take command of most business situations.',
      'Ability to build rapport and good working relationships with people at any level in an organization.',
      'Resourceful self-starter who can deliver projects with minimal back-office support.'
    ],
    contact: '+1-903-401-8804',
    email: 'info@phenomecloud.com',
    address: 'Phenom<sup>e</sup>cloud, 1333 Corporate Dr, Suite 212, Irving, TX 75038.',
    eoeStatement: 'Phenom<sup>e</sup>cloud is committed to creating a diverse environment and is proud to be an equal opportunity employer. All qualified applicants will receive consideration for employment without regard to race, color, religion, gender, gender identity or expression, sexual orientation, national origin, genetics, disability, age, or veteran status.'
  },
  'hcm-solutions-consultant': {
    title: 'HCM Solutions Consultant',
    category: 'Professional Services',
    location: 'Irving, TX, US',
    summary: 'The HCM Solutions Consultant is expected to deliver high-quality work to clients and spend time identifying and working with leadership to pursue business opportunities. The consultant will work with various HCM modules like Workday, Sumtotal to provide out-of-the-box solutions to clients.',
    responsibilities: [
      'Assist customers in clarifying their operational and functional business requirements.',
      'Conduct discovery sessions to fully understand prospect\'s business and technical requirements.',
      'Provide project leadership and direction on one or more projects while maintaining a high level of service.',
      'Develop and manage client relationships on projects.',
      'Ensure quality of all project deliverables, delivery dates, resourcing, and schedule.',
      'Identify and mitigate project level and client level risks.',
      'Facilitate collaboration with client and/or functional consultants to confirm requirements, consider design alternatives, and ultimately drive requirements sign-off.',
      'Evaluate, communicate, and coordinate the project impacts of application configuration decisions.',
      'Facilitate knowledge transfer and educate clients on developing and operating interfaces to and from a SaaS platform.',
      'Identify opportunities and contribute to selling additional work to clients.',
      'Facilitate project execution by supporting issue resolution and scope management.',
      'Track issues and risks, communicate status, and escalate concerns to project management.',
      'Understand the client\'s business challenges and provide thought leadership in addressing these issues.',
      'Work with your Solution Architect and other team members to ensure proper application of all phases of the systems development life cycle (SDLC) and meet completion dates.'
    ],
    skills: [
      'Significant experience (4+ years) in HCM process advisory/design and HCM SaaS/cloud consulting or technical consulting environments.',
      'Experience with custom and packaged HCM solutions (Workday®, Sumtotal, SuccessFactors, Oracle, etc.).',
      'Technical knowledge on Integrations and Web services is a plus.',
      'Proven client-facing and communication skills.',
      'Experience managing multiple teams, including development teams (on/offshore).',
      'Project management experience with Agile project methodology is desired.',
      'Excellent written and verbal communication skills.',
      'Detailed understanding of software development methodology best practices.',
      'Ability to travel to client sites (50% travel).'
    ],
    idealCandidateTraits: [
      'Should have functional and technical knowledge on different HCM platforms.',
      'Confident and articulate communicator who can take command of most business situations.',
      'Ability to build rapport and good working relationships with people at any level in an organization.'
    ],
    contact: '+1-903-401-8804',
    email: 'info@phenomecloud.com',
    address: 'Phenom<sup>e</sup>cloud, 1333 Corporate Dr, Suite 212, Irving, TX 75038.',
    eoeStatement: 'Phenom<sup>e</sup>cloud is committed to creating a diverse environment and is proud to be an equal opportunity employer. All qualified applicants will receive consideration for employment without regard to race, color, religion, gender, gender identity or expression, sexual orientation, national origin, genetics, disability, age, or veteran status.'
  },
  'people-technology-implementation-analyst': {
    title: 'People Technology Implementation Analyst',
    category: 'Professional Services',
    location: 'Irving, TX, US',
    summary: 'This position is responsible for supporting Phenom<sup>e</sup>cloud’s clients in implementing, managing, and administering Human Resources, Learning (LMS), and Talent management systems. The role also involves researching, recommending, and integrating complementary technologies into the client\'s HR landscape.',
    responsibilities: [
      'Assist implementation teams in determining the customer’s goals and target achievements. Offer real examples to address these goals.',
      'Document customer’s desired business processes and identify functionality gaps; work with Project Manager to develop strategies to address identified gaps.',
      'Develop solutions to maximize the functionality of HR, Learning, and Talent applications, ensuring successful launches and adjustments as needed.',
      'Effectively explain and convey complex ideas in group and individual settings, tailoring language to audience needs.',
      'Assist customers in defining, developing, and implementing new business processes, recommending improvements to meet customer goals.',
      'Elicit and document customer requirements for functional, operational, business performance, and/or acceptance.',
      'Interpret customer requirements to uncover unrealized or undiscovered needs.',
      'Deliver process or training workshops.',
      'Test or facilitate testing of learning and talent projects to ensure effective deployment in LMS.',
      'Work with project manager and technical teams on-site and offshore to define and clarify operational and functional requirements.'
    ],
    skills: [
      'Bachelor\'s degree in Education, Information Technology, or a related field preferred. Associate’s degree with relevant experience may also be considered.',
      'Experience with Workday® or SumTotal Learning & Talent is a plus.',
      'Excellent customer relationship management skills, including expectation setting, negotiation, issue resolution, and escalation.',
      'Ability to execute timely and effective implementations.',
      'Strong presentation, communication, and interpersonal skills.',
      'Able to work independently or as part of a team.',
      'Experience with Visio, MS Project, or other flow-charting tools.',
      'Ability to travel on short notice.'
    ],
    idealCandidateTraits: [
      'Customer-focused and skilled in managing relationships and resolving issues effectively.',
      'Clear communicator with strong presentation and interpersonal skills.',
      'Flexible and adaptable in both independent and team-based environments.'
    ],
    contact: '+1-903-401-8804',
    email: 'info@phenomecloud.com',
    address: 'Phenom<sup>e</sup>cloud, 1333 Corporate Dr, Suite 212, Irving, TX 75038.',
    eoeStatement: 'Phenom<sup>e</sup>cloud is committed to creating a diverse environment and is proud to be an equal opportunity employer. All qualified applicants will receive consideration for employment without regard to race, color, religion, gender, gender identity or expression, sexual orientation, national origin, genetics, disability, age, or veteran status.'
  },
  'content-writer': {
    title: 'Content Writer',
    category: 'Marketing',
    location: 'Irving, TX, US',
    summary: 'Phenom<sup>e</sup>cloud is looking for an energetic and organized Content Writer to work in Texas, US. The ideal candidate will be responsible for writing, video casting, and quality control, focusing on creating content for e-learning modules, blogs, user guides, and manuals.',
    responsibilities: [
      'Produce new e-learning scripts and content based on client needs.',
      'Participate in instructional development by testing and editing materials, providing feedback to peers.',
      'Cast and develop video scripts, shoot and edit them.',
      'Design, schedule, write, and edit guides, online help, and release notes, adhering to editorial and client standards.',
      'Contribute to the creation of documentation and e-learning standards.',
      'Ensure technical documentation is accurate, complete, and meets editorial and client standards.'
    ],
    skills: [
      'Bachelor’s degree in Communications, English, or equivalent experience.',
      'Two years of solid writing and editing experience.',
      'Experience in video casting and presenting as a host in e-learning modules.',
      'Experience in content writing, e-learning story board writing, product documentation, and Technical Readiness documentation is a plus.',
      'Experience in developing course narration scripts and recording narration.',
      'Excellent written and verbal communication skills.',
      'Experience in designing and developing mobile learning is a plus.',
      'Experience in managing the end-to-end instructional design process is a plus.',
      'Experience with HTML, graphic design, and Photoshop is not required but a plus.',
      'ASQ Training Development knowledge is not required but a plus.',
      'Proficiency in course authoring tools is not required but a plus.'
    ],
    idealCandidateTraits: [
      'Strong writing and editing skills.',
      'Experience in video casting and content development for e-learning.',
      'Good communicator, with the ability to present information clearly and effectively.',
      'Ability to work in an organized, detail-oriented manner and manage multiple tasks.'
    ],
    contact: '+1-903-401-8804',
    email: 'info@phenomecloud.com',
    address: 'Phenom<sup>e</sup>cloud, 1333 Corporate Dr, Suite 212, Irving, TX 75038.',
    eoeStatement: 'Phenom<sup>e</sup>cloud is committed to creating a diverse environment and is proud to be an equal opportunity employer. All qualified applicants will receive consideration for employment without regard to race, color, religion, gender, gender identity or expression, sexual orientation, national origin, genetics, disability, age, or veteran status.'
  },
  'quality-analyst': {
    title: 'Quality Analyst',
    category: 'Engineering',
    location: 'Irving, TX, and various unanticipated clients’ locations throughout US. Travel and/or relocation may require.',
    summary: 'Phenom<sup>e</sup>cloud is seeking a dynamic, energetic, and organized Quality Analyst. The ideal candidate will work with project teams to ensure standard quality practices/processes are followed, conducting end-to-end testing on applications and technology, and ensuring that business, technical, and functional requirements are met throughout the SDLC.',
    responsibilities: [
      'Create Automation Test Strategies for departmental initiatives.',
      'Implement automation tools, strategies, and roadmaps for the IT division.',
      'Identify vulnerabilities within existing automation and optimize scripts to increase execution time.',
      'Understand and support business goals as they relate to applications under test.',
      'Ensure automation initiatives across multiple projects and advocate for project quality and reliability.',
      'Code functions, classes, and methods to support automated tests for HP QTP, HP LoadRunner, and Visual Studio.',
      'Code test scripts using C#, VB, and VB scripts. Create, execute, and maintain automated test scripts in Quality Center.',
      'Alert development teams to product design inconsistencies and deviations from standards.',
      'Recommend alternatives for a more optimal user experience.',
      'Work closely with the release management team to certify releases and schedule them for production.',
      'Gather functional and non-functional test requirements from business and operational teams.',
      'Deploy builds to test environments and maintain the integrity of environments.',
      'Conduct triage cycles for identified issues and follow through on their resolution.',
      'Develop quality assurance standards for functional, regression, performance, and load testing.',
      'Stay updated on industry standards, trends, and best practices.',
      'Develop effective relationships within departments and among Agile team members.'
    ],
    skills: [
      'Master or Bachelor’s degree with or without experience. In lieu of a Master’s degree, five years of experience is acceptable.',
      'Proficiency in HP Quick Test Pro (QTP) and Quality Center (QC) to develop automated testing scenarios for Web/Window-based applications.',
      'Hands-on experience in coding, debugging, and maintaining QTP scripts.',
      'Comprehensive understanding of Software Development Life Cycle (SDLC) and QA Methodology.',
      'Proficiency in Test Case design and Defect reporting.',
      'Use of keyword-driven and data-driven framework approaches.',
      'Ability to analyze test results and debug errors, recommending corrective actions.',
      'Monitor bug resolution efforts and track issues uncovered by test automation.',
      'Collaboration with developers, BAs, and QA engineers to resolve technical issues.',
      'Exposure to platforms and technologies like Java, .Net, C++, Web, Mobile, and Mainframe.',
      'Familiarity with Agile/SCRUM/Lean development methodologies.'
    ],
    idealCandidateTraits: [
      'Strong experience in automation testing and coding.',
      'Ability to work with cross-functional teams to ensure the quality and reliability of applications.',
      'In-depth knowledge of automation tools and frameworks.',
      'Effective problem-solving skills and the ability to troubleshoot technical issues.',
      'Ability to adapt to different technologies and platforms.'
    ],
    contact: '+1-903-401-8804',
    email: 'info@phenomecloud.com',
    address: 'Phenom<sup>e</sup>cloud, 1333 Corporate Dr, Suite 212, Irving, TX 75038.',
    eoeStatement: 'Phenom<sup>e</sup>cloud is committed to creating a diverse environment and is proud to be an equal opportunity employer. All qualified applicants will receive consideration for employment without regard to race, color, religion, gender, gender identity or expression, sexual orientation, national origin, genetics, disability, age, or veteran status.'
  },
  'business-analyst': {
    title: 'Business Analyst',
    category: 'Professional Services',
    location: 'Irving, TX, US',
    summary: 'Phenom<sup>e</sup>cloud offers a full line of innovative business services and solutions. We are looking for a dynamic, energetic, and organized Business Analyst to work at our Irving, Texas location. The ideal candidate will be responsible for customer engagement, analysis of client requirements, and working with engineering teams to implement those requirements.',
    responsibilities: [
      'Work effectively with Talent Leaders and executive staff to define and translate high-level business requirements into functional specifications.',
      'Engage in deep technical conversations and high-level conceptual product discussions.',
      'Work with customers to solve day-to-day business problems and identify new opportunities.',
      'Interact with technology, HR functional areas, and HR Service Centers to formulate and implement solutions.',
      'Evaluate the quality and effectiveness of new processes, systems, and/or modifications to existing solutions.',
      'Provide ongoing support and troubleshooting for various HR systems and others as assigned.',
      'Explain complex technical issues to customers and communicate detailed requirements to engineering teams.',
      'Maintain confidentiality of customer data.'
    ],
    skills: [
      'Bachelor\'s or Master\'s in Computer Science, Information Technology, or Business preferred.',
      '3+ years of experience in a Business Analyst role.',
      'Knowledge of MS Office, MS Visio, and MS Project is required.',
      'Demonstrated knowledge and understanding of application programming, database, and system design.',
      'Ability to function independently and as a team player in a fast-paced environment.',
      'Strong written and verbal communication skills.',
      'Excellent customer service and organizational skills.',
      'Experience with Human Resource and workforce administration concepts.',
      'Willingness to travel 20% of the time to client locations.',
      'Experience with Talent Management Systems/Human Capital Management Systems (e.g., Onboarding, Hiring, Learning, Performance, Payroll, Succession) is a plus.'
    ],
    idealCandidateTraits: [
      'Strong analytical skills and the ability to solve complex business problems.',
      'Excellent interpersonal skills for collaborating with clients and internal teams.',
      'Experience in the HR domain, particularly with talent management systems.',
      'Strong ability to explain technical requirements to non-technical stakeholders.',
      'Proven ability to manage multiple tasks in a dynamic, fast-paced environment.'
    ],
    contact: '+1-903-401-8804',
    email: 'info@phenomecloud.com',
    address: 'Phenom<sup>e</sup>cloud, 1333 Corporate Dr, Suite 212, Irving, TX 75038.',
    eoeStatement: 'Phenom<sup>e</sup>cloud is committed to creating a diverse environment and is proud to be an equal opportunity employer. All qualified applicants will receive consideration for employment without regard to race, color, religion, gender, gender identity or expression, sexual orientation, national origin, genetics, disability, age, or veteran status.'
  },
  'development-engineer-wpf': {
    title: 'Development Engineer WPF',
    category: 'Engineering',
    location: 'Irving, TX, US',
    summary: 'Phenom<sup>e</sup>cloud offers a full line of innovative business services and solutions. We are looking for a dynamic, energetic, and organized Development Engineer with 3 – 5 years of experience in a WPF environment to work at our IDC in Vijayawada. The ideal candidate is required to develop and/or maintain innovative software applications.',
    responsibilities: [
      'Possess technical leadership qualities to mentor a team of junior developers throughout the project life-cycle, including direction on best practices, reuse, and development processes.',
      'Design, code, unit test, and interface with team leader, manager, Architect, and onsite coordinator to gather information on functionality.',
      'Collaborate with other members of the engineering team.',
      'Ensure on-time delivery of assigned tasks.'
    ],
    skills: [
      'Bachelor\'s or Master\'s in Computer Science or Information Technology.',
      '3-5 years of experience building applications using Microsoft.NET WPF is a must.',
      'Hands-on experience with C# and Object-Oriented Programming (OOP).',
      'Experience with design patterns.',
      'Experience with concurrency and multithreading.',
      'Experience with SQL stored procedures and functions on MS SQL Server 2008 / Oracle.',
      'Excellent communication skills, strong problem-solving abilities, and a passion for software development.',
      'Microsoft certifications are a plus (but not required).',
      'Experience with Python / Iron Python is an added advantage.'
    ],
    idealCandidateTraits: [
      'Strong leadership qualities for mentoring junior developers.',
      'Ability to design, code, and collaborate with the team effectively.',
      'Passion for developing high-quality software.',
      'Ability to solve complex technical problems.',
      'Solid experience in multithreading and concurrency management.'
    ],
    contact: '+1-903-401-8804',
    email: 'info@phenomecloud.com',
    address: 'Phenom<sup>e</sup>cloud, 1333 Corporate Dr, Suite 212, Irving, TX 75038.',
    eoeStatement: 'Phenom<sup>e</sup>cloud is committed to creating a diverse environment and is proud to be an equal opportunity employer. All qualified applicants will receive consideration for employment without regard to race, color, religion, gender, gender identity or expression, sexual orientation, national origin, genetics, disability, age, or veteran status.'
  },
  'business-development-manager': {
    title: 'Business Development Manager',
    category: 'Sales',
    location: 'Irving, TX, US',
    summary: 'Phenom<sup>e</sup>cloud is looking for a dynamic, energetic, and organized Business Development Manager. The ideal candidate is responsible for the development and growth of the company’s client base in IT services and Staff Augmentation, including developing relationships with IT & hiring managers, negotiating business terms, and securing partnerships. This is a full-time business development role focusing on both existing and new business verticals.',
    responsibilities: [
      'Build a strong sales pipeline and manage sales opportunities to closure.',
      'Develop a growth strategy focused on financial gain and customer satisfaction.',
      'Conduct research to identify new markets and customer needs.',
      'Arrange business meetings with prospective clients.',
      'Promote the company’s products/services, addressing or predicting clients’ objectives.',
      'Refine presentations, proposals, offerings, and act as a facilitator for contract negotiations.',
      'Generate leads, prepare RFPs, proposals, and other sales-related duties.',
      'Work with internal recruiters on client talent needs and ensure sourcing the right candidates.'
    ],
    skills: [
      'Require a relevant US equivalent Master\'s or Bachelor\'s degree.',
      '3+ years of experience in Business Development or an equivalent role.',
      '1+ year experience in Technical IT Services / Staffing Services.',
      'Broad expertise in Business Development, preferably in an international environment.',
      'Thorough understanding of the customer’s business and market sector, and the ability to anticipate how future service offerings should evolve.',
      'Knowledge of IT Professional Services and Staff Augmentation.',
      'Program Management or Project Management experience is a plus.',
      'Domestic travel is required.'
    ],
    idealCandidateTraits: [
      'Strong business development and sales pipeline management skills.',
      'Ability to identify market opportunities and customer needs.',
      'Effective communication and negotiation skills.',
      'Experience in technical IT services and staffing solutions.',
      'Ability to collaborate with internal recruiters for talent sourcing.'
    ],
    contact: '+1-903-401-8804',
    email: 'info@phenomecloud.com',
    address: 'Phenom<sup>e</sup>cloud, 1333 Corporate Dr, Suite 212, Irving, TX 75038.',
    eoeStatement: 'Phenom<sup>e</sup>cloud is committed to creating a diverse environment and is proud to be an equal opportunity employer. All qualified applicants will receive consideration for employment without regard to race, color, religion, gender, gender identity or expression, sexual orientation, national origin, genetics, disability, age, or veteran status.'
  },
  'responsive-web-developer': {
    title: 'Responsive Web Developer',
    category: 'Engineering',
    location: 'Irving, TX, US',
    summary: 'Phenom<sup>e</sup>cloud is looking for a dynamic, energetic, and organized Responsive Web Developer. Ideal candidates should have 2–5 years of experience with Android platform development and expertise in creating high-quality web applications using responsive design principles.',
    responsibilities: [
      'Implement responsive web design practices to deliver high-quality software.',
      'Collaborate on wireframes and design drafts, converting them into functional user interfaces.',
      'Produce mockups and designs in Photoshop, implementing them using HTML and CSS.',
      'Work with back-end developers to integrate interactive and AJAX-based functionality.',
      'Ensure on-time delivery with high-quality standards.',
      'Focus on customer needs to build technology solutions that address them.'
    ],
    skills: [
      'Any Degree.',
      '2-5 years of experience with the Android platform.',
      'Expert-level proficiency in UI/UX design, particularly for web apps.',
      'Demonstrated experience with responsive web design.',
      'Proficient graphic design skills using Photoshop or similar tools.',
      'Strong knowledge of HTML5, CSS3, and cross-browser support.',
      'Proficiency in object-oriented JavaScript, including frameworks like jQuery, Prototype, etc.',
      'Experience with debugging and problem-solving using tools such as Firebug.',
      'Track record/portfolio of high-quality web application interfaces and design implementations.',
      'Experience with development tools, including source code management.',
      'Excellent technical communication skills, both verbal and written.'
    ],
    contact: '+1-903-401-8804',
    email: 'info@phenomecloud.com',
    address: 'Phenom<sup>e</sup>cloud, 1333 Corporate Dr, Suite 212, Irving, TX 75038.',
    eoeStatement: 'Phenom<sup>e</sup>cloud is committed to creating a diverse environment and is proud to be an equal opportunity employer. All qualified applicants will receive consideration for employment without regard to race, color, religion, gender, gender identity or expression, sexual orientation, national origin, genetics, disability, age, or veteran status.'
  },
  'application-developer-net': {
    title: 'Application Developer .NET',
    category: 'Engineering',
    location: 'Irving, TX, and various unanticipated clients’ locations throughout US. Travel and/or relocation may require.',
    summary: 'Phenom<sup>e</sup>cloud is looking for a dynamic, energetic, and organized Application Developer/Software Engineer. The ideal candidate will be responsible for developing internal and external software applications, with involvement in all aspects of the Software Development Life Cycle (SDLC). The goal is to write clean, flawless code to produce fully functional software applications according to business requirements.',
    responsibilities: [
      'Research, develop and deploy solutions.',
      'Understand requirements and translate them into application features.',
      'Collaborate with IT professionals to set specifications for new applications.',
      'Design creative prototypes according to specifications.',
      'Write high-quality source code to program complete applications within deadlines.',
      'Perform unit and integration testing before launch.',
      'Conduct functional and non-functional testing.',
      'Troubleshoot and debug applications.',
      'Evaluate existing applications to reprogram, update, and add new features.',
      'Develop technical documents and handbooks to accurately represent application design and code.'
    ],
    skills: [
      'US equivalent Master or Bachelor degree, with or without experience. In lieu of a Master degree, a Bachelor degree with five years’ experience is acceptable.',
      'Experience in developing applications under .NET Framework using ASP.Net, C#, MVC, WPF, ADO.NET, Angular JS, and WCF.',
      'Experience in designing and developing web applications using HTML4/5, XML, XAML, JavaScript, JQuery, VB Script, LINQ, REST Services, Web Services, and AJAX.',
      'Experience with LINQ and ORM tools like Entity Framework to exchange data between web applications and databases.',
      'Experience working with XML Data structures and schemas.',
      'Experience with Source Control Management tools, such as TFS.',
      'In-depth knowledge of programming for diverse operating systems and platforms using development tools.',
      'Strong understanding of software design and programming principles.',
      'A team player with excellent communication skills.',
      'Analytical thinking and problem-solving capability.',
      'Great attention to detail and time-management skills.',
      'Certified application developer is a plus.'
    ],
    contact: '+1-903-401-8804',
    email: 'info@phenomecloud.com',
    address: 'Phenom<sup>e</sup>cloud, 1333 Corporate Dr, Suite 212, Irving, TX 75038.',
    eoeStatement: 'Phenom<sup>e</sup>cloud is committed to creating a diverse environment and is proud to be an equal opportunity employer. All qualified applicants will receive consideration for employment without regard to race, color, religion, gender, gender identity or expression, sexual orientation, national origin, genetics, disability, age, or veteran status.'
  },
  'application-developer-java': {
    title: 'Application Developer Java',
    category: 'Engineering',
    location: 'Irving, TX, and various unanticipated clients’ locations throughout US. Travel and/or relocation may require.',
    summary: 'Phenom<sup>e</sup>cloud is looking for a dynamic, energetic, and organized Application Developer/Software Engineer. The ideal candidate will be responsible for developing internal and external software applications, with involvement in all aspects of the Software Development Life Cycle (SDLC). The goal is to write clean, flawless code to produce fully functional software applications according to business requirements.',
    responsibilities: [
      'Research, develop and deploy solutions.',
      'Understand requirements and translate them into application features.',
      'Collaborate with IT professionals to set specifications for new applications.',
      'Design creative prototypes according to specifications.',
      'Write high-quality source code to program complete applications within deadlines.',
      'Perform unit and integration testing before launch.',
      'Conduct functional and non-functional testing.',
      'Troubleshoot and debug applications.',
      'Evaluate existing applications to reprogram, update, and add new features.',
      'Develop technical documents and handbooks to accurately represent application design and code.'
    ],
    skills: [
      'US equivalent Master or Bachelor degree, with or without experience. In lieu of a Master degree, a Bachelor degree with five years’ experience is acceptable.',
      'Extensive experience in developing applications using Java, JSP, Servlets, JavaBeans, JSTL, JSP Custom Tag Libraries, JDBC, JNDI, SQL, AJAX, JavaScript, and XML.',
      'Experience in designing and developing applications using HTML4/5, XML, XAML, JavaScript, JQuery, VB Script, AJAX, Angular JS, WPF, REST Services, and Web Services.',
      'In-depth knowledge of programming for diverse operating systems and platforms using development tools.',
      'Excellent understanding of software design and programming principles.',
      'A team player with excellent communication skills.',
      'Analytical thinking and problem-solving capability.',
      'Great attention to detail and time-management skills.',
      'Certified application developer is a plus.'
    ],
    contact: '+1-903-401-8804',
    email: 'info@phenomecloud.com',
    address: 'Phenom<sup>e</sup>cloud, 1333 Corporate Dr, Suite 212, Irving, TX 75038.',
    eoeStatement: 'Phenom<sup>e</sup>cloud is committed to creating a diverse environment and is proud to be an equal opportunity employer. All qualified applicants will receive consideration for employment without regard to race, color, religion, gender, gender identity or expression, sexual orientation, national origin, genetics, disability, age, or veteran status.'
  },
  'hr-generalist': {
    title: 'HR Generalist',
    category: 'Human Resources',
    location: 'Irving, TX, US',
    summary: 'Phenom<sup>e</sup>cloud is looking for a dynamic, energetic, and organized HR Generalist. The ideal candidate will perform HR-related duties, working closely with the entire team to support organizational goals and objectives. This role covers areas like employee relations, training, performance management, onboarding, policy implementation, recruitment, affirmative action, and compliance with employment laws.',
    responsibilities: [
      'Administers various human resource plans and procedures.',
      'Assists in implementing personnel policies and procedures.',
      'Participates in developing team/department goals, objectives, and systems.',
      'Monitors the performance evaluation program and provides data analysis as requested.',
      'Facilitates new hire orientation and conducts onboarding for new hires, transfers, and contract employees.',
      'Administers Service Awards and other employee recognition programs.',
      'Maintains affirmative action program, files reports, and maintains records to conform to EEO regulations.',
      'Ensures compliance with all regulatory postings and communications (EEO, Anti-Harassment, Labor Laws, etc.).',
      'Assists with job evaluations, recruitment, selection, negotiation, and onboarding.',
      'Maintains compliance with federal and state regulations concerning employment.',
      'Supports process improvement and performance management.',
      'Provides support for designing and implementing HR initiatives.',
      'Supports change management and employee engagement efforts.',
      'Manages employee issues by mediating conflicts, conducting investigations, and reaching resolutions.',
      'Provides administrative support to the Director of Human Resources.',
      'Additional duties may be assigned as required.'
    ],
    skills: [
      'US equivalent Master or Bachelor degree, with or without experience. Alternatively, Bachelor degree with five years’ experience.',
      'Strong organization and time management skills.',
      'Ability to work independently as well as collaboratively.',
      'Clear and effective written and verbal communication skills, with strong interpersonal skills.',
      'Ability to resolve employee issues with professionalism and sensitivity.',
      'Ability to maintain confidentiality.',
      'Ability to multitask and stay organized in a fast-paced environment.',
      'Ability to interact with all levels of management.',
      'Capable of analyzing data and providing recommendations.',
      'Proficiency in MS Office Suite (Excel, Word, Outlook, PowerPoint as required).'
    ],
    contact: '+1-903-401-8804',
    email: 'info@phenomecloud.com',
    address: 'Phenom<sup>e</sup>cloud, 1333 Corporate Dr, Suite 212, Irving, TX 75038.',
    eoeStatement: 'Phenom<sup>e</sup>cloud is committed to creating a diverse environment and is proud to be an equal opportunity employer. All qualified applicants will receive consideration for employment without regard to race, color, religion, gender, gender identity or expression, sexual orientation, national origin, genetics, disability, age, or veteran status.'
  },
  'database-developer': {
    title: 'Database Developer',
    category: 'Engineering',
    location: 'Irving, TX, and various unanticipated clients’ locations throughout US. Travel and/or relocation may be required.',
    summary: 'Phenom<sup>e</sup>cloud is looking for a dynamic, energetic, and organized Database Developer. The ideal candidate will understand business requirements and support project teams by implementing technical systems. They will work with teams to ensure technical solutions meet business needs and are developed with high quality.',
    responsibilities: [
      'Contribute to the development and maintenance of the firm’s BI and Reporting systems.',
      'Responsible for the production of client reports and ad-hoc data requests.',
      'Assist with the design, development, and management of proprietary databases and applications.',
      'Develop and manage data integrations between internal and external systems.',
      'Work with business users to define requirements and help determine how tools and features will be built.',
      'Research complex data issues, examine data in multiple systems for inconsistencies, propose and implement corrective actions.',
      'Document, track, and resolve problems in client business systems.',
      'Support all phases of the software development lifecycle, including Requirements Gathering, Analysis, Design, Testing, Implementation, and Production support for software systems.',
      'Identify and recommend application system solutions to business problems and enhancements to existing systems.'
    ],
    skills: [
      'US equivalent Master or Bachelor degree, with or without experience. Alternatively, Bachelor degree with five years’ experience.',
      'Experience with databases like DB2, Oracle, SQL Server, MySQL.',
      'Experience with Business Intelligence Suite (SSIS, SSRS, SSAS) for SQL Server.',
      'Design and develop SSIS packages, database objects, stored procedures, views, tables, triggers, and ETL code.',
      'Experience in SSIS development, understanding variables and expressions in SSIS.',
      'Experience in SQL Server Reporting Services (SSRS).',
      'Experience in SQL programming.',
      'Strong understanding of Stored Procedures.',
      'Strong analytical/mathematical and reasoning skills.',
      'Able to manage multiple projects simultaneously.',
      'Respond quickly to bug fixes and enhancement requests.',
      'Experience with SQL Navigator, SQL Plus, or Toad for Oracle.'
    ],
    contact: '+1-903-401-8804',
    email: 'info@phenomecloud.com',
    address: 'Phenom<sup>e</sup>cloud, 1333 Corporate Dr, Suite 212, Irving, TX 75038.',
    eoeStatement: 'Phenom<sup>e</sup>cloud is committed to creating a diverse environment and is proud to be an equal opportunity employer. All qualified applicants will receive consideration for employment without regard to race, color, religion, gender, gender identity or expression, sexual orientation, national origin, genetics, disability, age, or veteran status.'
  },
  'staffing-accounts-manager': {
    title: 'Staffing Accounts Manager',
    category: 'Staffing',
    location: 'Irving, TX, and various unanticipated clients’ locations throughout US. Travel and/or relocation may be required.',
    summary: 'Phenom<sup>e</sup>cloud is looking for a dynamic, energetic, organized, and self-driven Staffing Accounts Manager. The ideal candidate will act as a dedicated liaison between the company and clients, improve client satisfaction by meeting service level agreements, and create a motivating environment that fosters individual growth and high performance.',
    responsibilities: [
      'Manage assigned corporate accounts, build lasting relationships with client managers and team members, mine the account and increase value and revenue.',
      'New customer acquisition, identify potential clients, pursue them, and onboard new customers.',
      'Expand service offerings, work with leadership to design and offer new service lines to customers.',
      'Ensure quality and timeliness in service delivery to clients at all times.',
      'Develop and maintain strategic relationships within new clients and with hiring managers, Tier 1 Vendors, Prime vendors, etc.',
      'Generate consultant assignment opportunities with existing client managers.',
      'Assist hiring managers in defining consultant needs.',
      'Collaborate with a recruiting team to facilitate successful delivery processes.',
      'Manage the staffing sales process from prospecting to close.',
      'Maintain relationships with key client management to ensure satisfaction and future sales.',
      'Generate sales revenue in new accounts.',
      'Work closely with recruiting team to ensure customer delivery and achieve agreed business targets.',
      'Follow a disciplined planning process; well-versed in using technology and contact management systems.',
      'Support, guide, and manage aligned recruiters.',
      'Provide technical assistance to recruiters in facilitating searches.',
      'Have contacts with T1 Vendors, Implementation Partners, or Clients.',
      'Provide weekly sales activity, job orders, pipeline progress, and deals report to Operations Head.'
    ],
    skills: [
      'US equivalent Master or Bachelor degree.',
      'Excellent verbal, written communication, determination, and strategic thinking skills.',
      'Experience in IT Staffing sales, Marketing, or Business Development.',
      'Excellent negotiation skills.',
      'Managerial and leadership skills.',
      'Analytical skills.',
      'Training skills.',
      'Team player with an enthusiastic attitude, target-driven, and able to thrive in a time-pressured environment.'
    ],
    contact: '+1-903-401-8804',
    email: 'info@phenomecloud.com',
    address: 'Phenom<sup>e</sup>cloud, 1333 Corporate Dr, Suite 212, Irving, TX 75038.',
    eoeStatement: 'Phenom<sup>e</sup>cloud is committed to creating a diverse environment and is proud to be an equal opportunity employer. All qualified applicants will receive consideration for employment without regard to race, color, religion, gender, gender identity or expression, sexual orientation, national origin, genetics, disability, age, or veteran status.'
  },
  'software-engineer': {
    title: 'Software Engineer',
    category: 'Engineering',
    location: 'Irving, TX, US.',
    summary: 'Phenom<sup>e</sup>cloud is looking for a dynamic, energetic, and organized Software Engineer. The ideal candidate will work with the project team ensuring availability of relevant data from the client’s internal systems and third-party sources, handling structured and unstructured data produced from emerging media like social, mobile, IoT, and others.',
    responsibilities: [
      '7+ years of data management and data processing for BI/Analytics solutions.',
      'Creating technical design and architecture for enterprise data warehouses.',
      'Extracting data from outside sources.',
      'Transforming it to fit operational and analytics needs.',
      'Loading it into the database.',
      'Managing policies, rules, or standards that govern data collection, storage, arrangement, integration, and usage in data systems.',
      'Protecting the database from destructive forces and unauthorized access.',
      'Managing access to data sources and information within the organization.'
    ],
    skills: [
      'Knowledge of R, Python, SQL, SPSS, Weka, NoSQL.',
      'Dimensional modelling and Data warehousing, Snowflake schema, star schema, data normalization, performance tuning.',
      'Advanced knowledge of technologies like Informatica, ODI, Oracle, Teradata, Apache Hadoop, HDFS.',
      'Working knowledge of machine learning, time series forecasting, and data mining algorithms.',
      'Advanced knowledge of algorithms/data structures and statistical techniques for regressions, CART, neural networks, KNN, random forest, generalized boosted models, support vector machines, Bagging, gradient boosting, ARIMA, clustering.',
      'Master’s Degree from an accredited college or university in a related field.'
    ],
    contact: '+1-903-401-8804',
    email: 'info@phenomecloud.com',
    address: 'Phenom<sup>e</sup>cloud, 1333 Corporate Dr, Suite 212, Irving, TX 75038.',
    eoeStatement: 'Phenom<sup>e</sup>cloud is committed to creating a diverse environment and is proud to be an equal opportunity employer. All qualified applicants will receive consideration for employment without regard to race, color, religion, gender, gender identity or expression, sexual orientation, national origin, genetics, disability, age, or veteran status.'
  },
  'inside-sales-and-customer-success-representative': {
    title: 'Inside Sales & Customer Success Representative',
    category: 'Sales',
    location: 'Irving, TX, US.',
    summary: 'Perfect role for someone looking to grow with a fast-paced and thriving software services and consulting organization. The Inside Sales & Customer Success Representative will contribute to customer acquisition, revenue growth, and customer retention. The role supports qualifying prospects, making calls, generating interest, and deepening customer relationships. This position is ideal for an ambitious individual excited to grow skills in a fast-paced environment.',
    responsibilities: [
      'Make 30+ outbound calls daily to follow up with leads (inbound leads, marketing qualified leads, or cold calls) or to engage existing customers and build relationships.',
      'Research companies, understand needs/requirements, identify key players, and generate interest with effective phone calls.',
      'Route qualified opportunities to sales executives for further development and closure.',
      'Manage relationships with existing accounts, engage them regularly, actively seek opportunities to up-sell, cross-sell, and generate referrals.'
    ],
    skills: [
      '1+ year(s) experience in Services and Support roles, such as Customer Success, Inside Sales, and/or Consulting.',
      'Exceptional organizational and time management skills.',
      'Excellent written and verbal communication skills.',
      'Strong phone presence and ability to make dozens of calls per day.',
      'Ability to multi-task, prioritize, and manage time effectively.',
      'Proven coordination skills.',
      'Experience with CRM systems like HubSpot is a plus.',
      'Expertise with MS Office products, especially Excel, PowerPoint, and Outlook.',
      'Strong interpersonal skills and the ability to work effectively with a wide range of individuals in a diverse community.',
      'BA/BS degree or equivalent.'
    ],
    contact: '+1-903-401-8804',
    email: 'info@phenomecloud.com',
    address: 'Phenom<sup>e</sup>cloud, 1333 Corporate Dr, Suite 212, Irving, TX 75038.',
    eoeStatement: 'Phenom<sup>e</sup>cloud is committed to creating a diverse environment and is proud to be an equal opportunity employer. All qualified applicants will receive consideration for employment without regard to race, color, religion, gender, gender identity or expression, sexual orientation, national origin, genetics, disability, age, or veteran status.'
  },
  'systems-administrator': {
    title: 'Systems Administrator',
    category: 'Infrastructure',
    location: 'Irving, TX, and various unanticipated clients’ locations throughout US. Travel and/or relocation may require.',
    summary: 'Phenom<sup>e</sup>cloud is looking for a dynamic, energetic, and organized Systems Administrator responsible for the installation, configuration, and ongoing maintenance of development, QA, and Production infrastructures/environments. Other tasks include Shell Scripting installations, replication of environments, performance tuning, and troubleshooting application issues.',
    responsibilities: [
      'Build Environments to specifications.',
      'Provide Environment Support 24/7 with onshore/offshore resources.',
      'Create and maintain standard installation and setup procedures for various applications.',
      'Help update documentation for Run The Engine Functions.',
      'Participate in incident resolution and perform root cause analysis on problems.',
      'Consult with vendors and teams to recommend best practices and tune software during the development cycle.',
      'Install, configure, and maintain Commercial-off-the-Shelf (COTS) software.',
      'Install, integrate, administer, and maintain WebSphere software.',
      'Monitor, analyze, and tune clusters or instances for performance.',
      'Provide advice, guidance, and assistance to the customer as needed.',
      'Monitor Incident and Service Request queues and perform work per requirements.',
      'Design solutions based on customer requirements.',
      'Install and maintain WebSphere, Apache, Tomcat, Weblogic, MQ, Filenet, F5 - WAF, ASM, LDAP, SSL Certificates.'
    ],
    skills: [
      'Require relevant US equivalent Master or Bachelor degree with/without experience. In lieu of Master degree, we will accept Bachelor degree with five years’ experience.',
      'Hands-on experience with WebSphere software.',
      'Experience with Linux/Unix and Windows systems and processes.',
      'Experience configuring and setting up Connect: Direct in a Unix or Linux environment.',
      'Understanding of SSL, TLS, and data encryption.',
      'Shell Scripting (Shell, Perl, Python, etc.).',
      'Strong knowledge of HTTP and HTTPS protocols.',
      'Experience in identifying and troubleshooting system outages.',
      'Proven interpersonal, communication, organizational, leadership, and customer service skills.',
      'Must be willing to work off hours, weekends, and be on call for production support.',
      'Experience with/Exposure to one or more of the following products: WebSphere Process Server, WebSphere Application Server, WebSphere Portal Server, WebSphere Partner Gateway.'
    ],
    contact: '+1-903-401-8804',
    email: 'info@phenomecloud.com',
    address: 'Phenom<sup>e</sup>cloud, 1333 Corporate Dr, Suite 212, Irving, TX 75038.',
    eoeStatement: 'Phenom<sup>e</sup>cloud is committed to creating a diverse environment and is proud to be an equal opportunity employer. All qualified applicants will receive consideration for employment without regard to race, color, religion, gender, gender identity or expression, sexual orientation, national origin, genetics, disability, age, or veteran status.'
  } 
  };

  const job = jobDetails[jobCode];

  if (!job) return <div>Job not found</div>;

  return (
    <>
      <div className="container-flued">
        <div className="TalentHub TalentHubBannerBg">
          <div className="area">
            <ul className="circles">
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </div>

          {
            <div id="Bannercarousel" className="carousel slide">
              <div className={` ImgSection carousel-inner`}>
                <div className={`carousel-item active`}>
                  <div className={`OurPartnersPage text-white row`}>
                    <div
                      className={`BannerHeading col d-flex flex-column align-items-center`}
                    >
                      <div className="MainSection">
                        <h3
                          className={`Heading w-100 `}
                          title="Become a part of Phenom<sup>e</sup>cloud and Grow With Us"
                        >
                          Become a part of Phenom<sup>e</sup>cloud and Grow With
                          Us
                        </h3>

                        <p
                          className="Description w-100"
                          title="Create adventures with technology in a creative, challenging and vibrant environment."
                        >
                          Create adventures with technology in a creative,
                          challenging and vibrant environment.
                        </p>
                      </div>
                    </div>

                    <div className={`ImgBox col`}>
                      <LazyLoad offset={100}>
                        <img
                          alt={`OurPartnersPage text-white | PhenomeCloud`}
                          className="BannerImg"
                          src={require(`../image/BannerImg/careers-image.png`)}
                          loading="lazy"
                        />
                      </LazyLoad>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
      <div className="job-details container">
        {/* Check if the jobCode matches and render job details */}
        {jobDetails[jobCode] && (
          <div className="col-md-12 my-4">
            <div className="row align-items-center">
              <div className="col-md-12">
                <h2 className="fontstyle1 boldfont orangetext">
                  {jobDetails[jobCode].title}
                </h2>
              </div>
              <div className="col-md-12 text-md-left">
                <a id="Applyjob" className="btn-style pull-right" onClick={openModal}>
                  Apply
                </a>
              </div>
            </div>

            <div className="col-md-12 p-0">
              <div className="col-md-12 p-0">
                <p className="text_style padtopbot">
                  <span style={{ paddingRight: "10px", margin:'0px' }}>
                    <span className="Pjobtitle text-dark fw-bold m-0">Job Category:</span>{" "}
                    {jobDetails[jobCode].category}
                  </span>
                  {
                    jobDetails[jobCode].type && (
                      <span
                      style={{
                        borderLeft: "1px solid #000",
                        padding: "0px 10px",
                      }}
                    >
                      <span className="Pjobtitle m-0 fw-bold text-dark">Job Type:</span>{" "}
                      {jobDetails[jobCode].type}
                    </span>
                    )
                  } 
                  {
                    jobDetails[jobCode].location && (
                      <span
                      style={{
                        borderLeft: "1px solid #000",
                        padding: "0px 10px",
                      }}
                    >
                      <span className="Pjobtitle m-0 fw-bold text-dark">Job Location:</span>{" "}
                      {jobDetails[jobCode].location}
                    </span>
                    )
                  }

                  { 
                    jobDetails[jobCode].shift && (
                      <span
                      style={{
                        borderLeft: "1px solid #000",
                        padding: "0px 10px",
                      }}
                    >
                      <span className="Pjobtitle m-0 fw-bold text-dark">Shift Timings:</span>{" "}
                      {jobDetails[jobCode].shift}
                    </span>
                    )

                  }
                  
                </p>
                <div className="Pjobtitle fw-bold mt-3">Position Summary:</div>
                <p className="text_style padtopbot" dangerouslySetInnerHTML={{ __html: jobDetails[jobCode].summary }}>
                 
                </p>
              </div>
            </div>

            <div className="col-md-12 p-0 mt-4">
              <div className="col-md-12 p-0 Pjobtitle fw-bold">
                Duties/Responsibilities:
              </div>
            </div>

            <div className="col-md-12 p-0">
              <div className="col-md-12">
                <ul style={{ listStyle: "initial", padding: "10px 45px" }}>
                  {jobDetails[jobCode].responsibilities.map(
                    (responsibility, index) => (
                      <li key={index} style={{ listStyleType: "square" }}>
                        {responsibility}
                      </li>
                    )
                  )}
                </ul>
              </div>
            </div>

            <div className="col-md-12 p-0">
              <div className="col-md-12 padleft Pjobtitle fw-bold">
                Skills/Requirements:
              </div>
            </div>

            <div className="col-md-12 p-0 cleard">
              <div className="col-md-10 text_style">
                <ul style={{ listStyle: "initial", padding: "10px 45px" }}>
                  {jobDetails[jobCode].skills.map((skill, index) => (
                    <li key={index} style={{ listStyleType: "square" }}>
                      {skill}
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            {/* <div className="col-md-12 p-0 text-md-left py-3">
              <a id="Applyjob" className="btn-style pull-right mt-0"  >
                Apply
              </a>
            </div> */}

            {/* modal form  */}

            <div className="col-md-12 p-0 text-md-left py-3">
              <a  id="Applyjob"  className="btn-style pull-right mt-0"  onClick={openModal} >
                Apply
              </a>
            </div>

            {/* Modal */}
            <Modal
            ariaHideApp={false}
            id='job_appl_form'
              isOpen={isModalOpen}
              onRequestClose={closeModal}
              style={{
                content: {
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  height: "600px",
                  padding: "20px",
                  backgroundColor: "white",
                  borderRadius: "10px",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                },
                overlay: {
                  backgroundColor: "rgba(0, 0, 0, 0.7)", // Darker background
                  zIndex: 1000, // Ensures the modal overlay is on top
                  position: "fixed", // Ensures it covers the full viewport
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                },
              }}
              bodyOpenClassName="no-scroll"
              contentLabel="Application Form Modal"
            >
              <h2>Application Form</h2>
              <form onSubmit={handleSubmit}>
                {/* First Name */}
                <div className="input-container">
                  <input
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    placeholder="First Name*"
                    required
                  />
                </div>

                {/* Last Name */}
                <div className="input-container">
                  <input
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    placeholder="Last Name*"
                    required
                  />
                </div>

                {/* Email */}
                <div className="input-container">
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Email*"
                    required
                  />
                </div>

                {/* Phone Number */}
                <div className="input-container">
                  <input
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    placeholder="Phone*"
                    required
                  />
                </div>

                {/* Resume (File Picker) */}
                <div className="input-container d-flex flex-column">
                  <label>Attached Resume *</label>
                  <input
                    style={{ borderBottom: "none" }}
                    type="file"
                    name="resume"
                    accept=".pdf"
                    onChange={handleFileChange}
                    required
                  />
                  {/* <span>{formData.resume ? formData.resume.name : 'No file chosen'}</span> */}
                </div>

                {/* Cover Letter (File Picker) */}
                <div className="input-container">
                  <label>Attached Cover Letter</label>
                  <input
                    style={{ borderBottom: "none" }}
                    type="file"
                    name="coverLetter"
                    accept=".pdf"
                    onChange={handleFileChange}
                  />
                  {/* <span>{formData.coverLetter ?'' : 'No file chosen'}</span> */}
                </div>

                {/* Description (Optional) */}
                <div className="input-container ">
                  <label>Description</label>
                  <textarea
                    type="text"
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    placeholder="Description "
                    rows="1"
                  />
                </div>

                {/* Buttons */}
                <div
                  style={{
                    marginTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <button
                    type="button"
                    onClick={closeModal}
                    className="b_none apply_form_btn"
                    style={{ marginRight: "10px" }}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="b_none apply_form_btn"
                    style={{ marginLeft: "10px" }}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </Modal>

            {/* Custom CSS */}
            <style>
              {`
          /* Custom styles */
          .input-container {
            position: relative;
            margin-bottom: 20px;
          }

          /* Full-width input with bottom border */
          input[type="text"],
          input[type="email"],
          input[type="tel"],
          textarea {
            width: 100%;
            border: none;
            border-bottom: 2px solid #ccc;
            padding: 10px;
            font-size: 16px;
            outline: none;
            background: transparent;
            transition: all 0.3s ease;
          }

          input[type="text"]:focus,
          input[type="email"]:focus,
          input[type="tel"]:focus,
          textarea:focus {
            border-bottom: 2px solid #007bff;
          }

          /* Placeholder floating when focused or text entered */
          input[type="text"]:focus::placeholder,
          input[type="email"]:focus::placeholder,
          input[type="tel"]:focus::placeholder,
          textarea:focus::placeholder {
            color: transparent;
          }

          input[type="text"]:valid::placeholder,
          input[type="email"]:valid::placeholder,
          input[type="tel"]:valid::placeholder,
          textarea:valid::placeholder {
            color: transparent;
          }

          /* Label styling */
          label {
            font-size: 14px;
            color: #333;
            margin-bottom: 5px;
            display: inline-block;
          }

          /* File input span for "No file chosen" */
          input[type="file"] {
            display: inline-block;
            width: calc(100% - 120px);
            padding: 10px;
            font-size: 14px;
            margin-bottom: 10px;
            border: none;
            border-bottom: 2px solid #ccc;
            background: transparent;
          }

          input[type="file"]:focus {
            border-bottom: 2px solid #007bff;
          }

          span {
            font-size: 14px;
            color: #777;
            margin-left: 10px;
            display: inline-block;
          }
        `}
            </style>
            {/* modal form  */}

            <div className="col-md-12 p-0">
              <p className="text_style padtopbot reverse_flow  d-flex">
                <div>
                <span className="Pjobtitle fw-bold">Contact:</span>{" "}
                {jobDetails[jobCode].contact}
                </div>
                <div>
                <span className="Pjobtitle fontPjobtitlesize  padleft3 fw-bold pe-1">
                  Email:
                </span>
                <a
                  href={`mailto:${jobDetails[jobCode].email}`}
                  style={{ textDecoration: "none" }}
                >
                  {jobDetails[jobCode].email}
                </a>
                </div>
                
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default JobDetails;
