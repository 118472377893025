import React, { useEffect, useState } from 'react';
import FooterBrandData from '../data/FooterBrands.json';
import { Link, useParams } from 'react-router-dom';
import { Carousel } from 'react-bootstrap';

function FooterBrands() {
    const [itemsPerSlide, setItemsPerSlide] = useState(5);

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            if (width <= 767) {
                setItemsPerSlide(2);  // 2 items per slide on small screens
            } else if (width <= 1300) {
                setItemsPerSlide(3);  // 3 items per slide on medium screens
            } else {
                setItemsPerSlide(5);  // 5 items per slide on large screens
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

 

    // Get the `className` from the URL params
    let { className } = useParams();
    if (className === undefined) {
        className = 'home';
    }

    useEffect(()=>{
        if(className === 'enterprise-accelerators'){
            setItemsPerSlide(4)
        }
    },[className])

    return (
        <div>
            {FooterBrandData.map((item) => {
                const { Id, MainClassName, Grid1, Grid2, Heading, BrandData, PageName } = item;

                if (className === PageName) {
                    // Grouping items based on the current itemsPerSlide value
                    const groupedItems = [];
                    for (let i = 0; i < BrandData.length; i += itemsPerSlide) {
                        groupedItems.push(BrandData.slice(i, i + itemsPerSlide));
                    }

                    return (
                        <div className="BrandSection" key={Id}>
                            <div className={MainClassName}>
                                <div className={Grid1}>
                                    <h5 dangerouslySetInnerHTML={{ __html: Heading }}></h5>
                                </div>

                                <div className={`${Grid2} tablet_padding_remove`}>
                                    <Carousel interval={3000} indicators={false}>
                                        {groupedItems.map((group, idx) => (
                                            <Carousel.Item key={idx}>
                                                <div className="d-flex justify-content-center">
                                                    {group.map((brand, index) => {
                                                        const { Img, Url } = brand;
                                                        return (
                                                            <div className="image_wrapper" key={index}>
                                                                <Link to={Url}  rel="noopener noreferrer">
                                                                    <img style={{aspectRatio: className === "enterprise-accelerators" ? 'unset' : '', height: className === "enterprise-accelerators" ? '120px' : ''}}
                                                                        className={`img-fluid ${Img}`}
                                                                        src={require(`../image/Brands/${Img}`)}
                                                                        alt={`Logo ${brand.Id}`}
                                                                    />
                                                                </Link>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </Carousel.Item>
                                        ))}
                                    </Carousel>
                                </div>
                            </div>
                        </div>
                    );
                }

                return null;
            })}
        </div>
    );
}

export default FooterBrands;
