import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import TrustedOrganization from '../TrustedOrganization'
import { GetStarted } from '../FooterSection/GetStarted'
import ImplementationData from '../../data/ERP/Implementation.json'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { Accordion, AccordionButton } from 'react-bootstrap'
import { GoHorizontalRule, GoPlus } from 'react-icons/go'
const Banner = React.lazy(() => import('../Banner'));

const Implementation = () => {
    const [expandedItem, setExpandedItem] = useState(null);

    const handleToggle = (eventKey) => {
        setExpandedItem(expandedItem === eventKey ? null : eventKey);
    };

    return (
        <>
            <Helmet>
                <title>Technology Implementation Consulting | Phenom&#x1D49;Cloud</title>
                <meta
                    name="description"
                    content="Partner with PhenomeCloud for expert enterprise technology implementation, driving digital transformation, optimizing performance, and ensuring sustainable business growth."
                />
                <meta
                    name="keywords"
                    content="Enterprise Implementation Consulting | Technology Implementation Services | Digital Transformation Consulting | Business Process Optimization | Scalable Business IT Solutions"
                />
                <meta
                    name="robots"
                    content="index, follow"
                />
                <meta
                    property="og:title"
                    content="Technology Implementation Consulting | Phenom&#x1D49;Cloud"
                />
                {/* <meta
                    property="og:title"
                    content="Expert Enterprise Technology Implementation Consulting for Digital Transformation and Business Growth"
                /> */}
                {/* <meta
                    property="og:description"
                    content="Comprehensive Technology Implementation Solutions"
                /> */}
                <meta
                    property="og:description"
                    content="Partner with PhenomeCloud for expert enterprise technology implementation, driving digital transformation, optimizing performance, and ensuring sustainable business growth."
                />
                <meta
                    property="og:keywords"
                    content="Enterprise Implementation Consulting | Technology Implementation Services | Digital Transformation Consulting | Business Process Optimization | Scalable Business IT Solutions"
                />
            </Helmet>
            <Banner />
            <TrustedOrganization />
            {ImplementationData.map((item, index) => {
                const { cta_link, Header, CTA, Desc, id, title, desc, bannerImage, accordians, infotext, grid, sub_title, card, header, bottomContent, popUp } = item;
                return (
                    <div className="" key={id}>
                        {(id === 1) ? (
                            <div className={`row d-flex justify-content-evenly py-4  mb-3 align-items-center  erp_future_analytic  whitebg_section discover_possible `} >
                                {header && <p className="col-10 text-center mb-3 headerOrder" style={{ fontWeight: 'bold', fontSize: '25px' }} dangerouslySetInnerHTML={{ __html: header }}></p>}
                                <div className="col-md-4 text-center col-sm-12 col-12">
                                    <div>
                                        <img alt="Enterprise Technology Implementations | Phenom&#x1D49;Cloud" src={require(`../../image/ERP/Implementation/${bannerImage}`)} style={{ width: '300px' }} className="img-fluid " />
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12 col-12">
                                    {
                                        desc.map((data, index) => (
                                            <div className="head_on_desc_body mb-3">
                                                {data.Desc_header && <p className="mb-3" style={{ fontWeight: 'bolder', lineHeight: '23px', fontSize: '25px' }} dangerouslySetInnerHTML={{ __html: data.Desc_header }}></p>}
                                                {data.Desc &&
                                                    data.Desc.map((value, index1) => (
                                                        <div className="explore_service_section">
                                                            <div className="d-flex align-items-center mb-2" key={index}>
                                                                <div>
                                                                    <p className="normal-text-font" dangerouslySetInnerHTML={{ __html: value.text }}></p>
                                                                    {
                                                                        value.ul && value.ul.map((value1, index2) => (
                                                                            <li className="normal-text-font" dangerouslySetInnerHTML={{ __html: value1.li }}></li>
                                                                        ))
                                                                    }
                                                                </div>

                                                            </div>
                                                        </div>

                                                    ))
                                                }
                                            </div>
                                        ))
                                    }
                                </div>

                            </div>
                        ) : ''}
                        {
                            id === 2 && (
                                <div className="row d-flex justify-content-center align-items-center  mb-3" style={{ backgroundColor: "#f7fafc" }}>
                                    {title && <h3 className="text-center fw-bold hcm_offer_header px-5 mt-4">{title}</h3>}
                                    {desc && <div className="title_desc  d-flex flex-column align-items-center">
                                        {
                                            desc.map((value, items) => (
                                                value && <p key={items} className="text-center service_desc" style={{ width: '70%', fontSize: '16px' }}>{value.text}</p>
                                            ))
                                        }
                                    </div>
                                    }
                                    <div className="row d-flex justify-content-center align-items-center our_service_body_content mt-3 mb-3">
                                        {
                                            card.map((data, index) => {
                                                return <div className={`col-md-3 py-2  our_service_card col-dm-12 m-3 card text-center erp-card-radius card-bg-color py-3 px-3 erp-card-background Description-Fontsize eb_implementation-card-fix-height ${data.card_class}`}>

                                                    <div className='cardImg text-start d-flex justify-content-between align-items-center'>

                                                        <p className="fw-bold">{data.card_name}</p>
                                                        <img alt="Enterprise Technology Implementations | Phenom&#x1D49;Cloud" src={require(`../../image/ERP/Implementation/${data.icon}`)} className='icon-box' />
                                                    </div>
                                                    <div className="card-body px-1 d-flex align-items-center">

                                                        <div className="onboarding_card_desc" >
                                                            <p className="our_service_card_desc" dangerouslySetInnerHTML={{ __html: data.card_desc }}></p></div>
                                                    </div>
                                                    {
                                                        data.card_url && (
                                                            <div className=" col-md-12 d-flex justify-content-end align-items-center">
                                                                <Link style={{ marginRight: '2%', textDecoration: 'none' }} to={data.card_url}>
                                                                    <p >{data.card_btn}</p></Link>
                                                                <Link to={data.card_url} >
                                                                    <button className="btn card-btn-color"> <FontAwesomeIcon icon={faArrowRight} style={{ color: "#ffffff" }} /></button></Link>
                                                            </div>
                                                        )
                                                    }
                                                </div>

                                            })
                                        }
                                    </div>
                                </div>
                            )
                        }
                        {
                            id === 3 &&
                            <div className={`row d-flex justify-content-center align-items-center  px-5 Cloud_Solution_cta_section white_bg py-4`}>
                                <div className="col-md-12 d-flex flex-column justify-content-center align-items-center">
                                    <h1 className="Cloud_sloution_cta_header text-center fw-bold " dangerouslySetInnerHTML={{ __html: Header }}></h1>
                                    <h3 className="Cloud_sloution_cta_Subheader text-center mb-3">{Desc}</h3>
                                    {
                                        cta_link &&
                                        <Link to={cta_link} className="cta_btn text-decoration-none">
                                            {CTA}
                                        </Link>

                                    }
                                </div>
                            </div>
                        }
                        {id === 4 && (
                            <div className="row d-flex justify-content-around mt-3 mb-3">
                                <div className="col-lg-11 col-md-11 col-sm-11 col-11 px-5 padding_fix">
                                    <div className=" ">
                                        <div className="">
                                            <p className="text-center fw-bold" style={{ fontSize: '25px' }} dangerouslySetInnerHTML={{ __html: title }}></p>
                                            {
                                                desc.map((data, id) => (
                                                    <p className='line-_height text-center'>{data.text}</p>
                                                ))
                                            }
                                        </div>
                                        <div className="">
                                            {

                                                <div className="row d-flex justify-content-center  Advisory_service  eb_implementation">
                                                    <div className="row d-flex align-items-center justify-content-center flex-row-reverse m-auto  Advisory_service">
                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
                                                            <img alt="Enterprise Technology Implementations | Phenom&#x1D49;Cloud" src={require(`../../image/ERP/Implementation/${bannerImage}.webp`)} className=" accordian_img" />
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 d-flex flex-column justify-content-center  " style={{ padding: '16px' }}>
                                                            {<Accordion className='d-flex flex-column EcCoe'>
                                                                {accordians.map((item, index) => {
                                                                    const { header, desc } = item;
                                                                    return (
                                                                        <Accordion.Item eventKey={index.toString()} className='mb-2 acoordian_items' key={index} style={{ border: 'none', background: '#E1EDFE' }} >
                                                                            <AccordionButton className='Dropdown-Bg-Color'
                                                                                onClick={() => handleToggle(index.toString())}
                                                                                aria-expanded={expandedItem === index.toString()}
                                                                            >
                                                                                <div className="d-flex justify-content-between align-items-center w-100">
                                                                                    <span className='fw-bolder sm-font-size'>{header}</span>
                                                                                    {expandedItem === index.toString() ? <GoHorizontalRule /> : <GoPlus className='PlusIcon' />}
                                                                                </div>
                                                                            </AccordionButton>

                                                                            <Accordion.Body key={index} className='py-1'>
                                                                                {
                                                                                    desc && desc.map((item, index) => (
                                                                                        <p className='text-start' dangerouslySetInnerHTML={{ __html: item.text }}></p>
                                                                                    ))
                                                                                }
                                                                            </Accordion.Body>

                                                                        </Accordion.Item>
                                                                    );
                                                                })}
                                                            </Accordion>
                                                            }
                                                        </div>
                                                    </div>
                                                    {bottomContent && <p className='text-center' dangerouslySetInnerHTML={{ __html: bottomContent }}></p>}
                                                </div>

                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                    </div>
                );
            })}
            <GetStarted />
        </>
    )
}

export default Implementation