import React from "react";
import TrustedOrganization from "./TrustedOrganization";
import policyData from "../data/PrivacyPolicy.json";
const Banner = React.lazy(() => import('./Banner'));

const PrivacyPolicyLayout = () => {
  return (
    <>
      <Banner />
      <TrustedOrganization />

      <div className="privacy_policy_body d-flex container-fluid" style={{ padding: "5%" }}>
        <div className="row">
          <div className="col-md-6 col-sm-12 privacy_policy_left_body" style={{ padding: "1%" }}>
            {policyData[0].Data.map((item, index) =>
              index < 5 ? (
                <div className="left_content">
                  <h5 style={{ color: "#f37021", fontWeight: "bold" }} dangerouslySetInnerHTML={{ __html: item.Header }} />
                  {item.desc.map((item1, index1) => (
                    <p className="privacy_desc" dangerouslySetInnerHTML={{ __html: item1.Desc }} key={index1} />
                  ))}
                  {item.Id === 2 || item.Id === 5 ? (
                    <ul>
                      {
                        item.ul.map((item3, index3) => (
                          <li className="mb-1" dangerouslySetInnerHTML={{ __html: item3.list }} style={{ listStyleType: "circle" }} />
                        ))
                      }
                    </ul>
                  ) : (
                    ""
                  )}
                  {
                    item.subDescription && (
                      item.subDescription.map((item1, index1) => (
                        <p className="privacy_desc" key={index1}>{item1.subDesc}</p>
                      ))
                    )
                  }

                </div>
              ) : (
                ""
              )
            )}
          </div>
          <div className="col-md-6 col-sm-12 privacy_policy_right_body" style={{ padding: "1%" }}>
            {policyData[0].Data.map((item, index) =>
              index > 4 ? (
                <div className="right_content">
                  <h5 style={{ color: "#f37021", fontWeight: "bold" }} >{item.Header}</h5>
                  {item.Id === 10 ? (
                    <>
                      {
                        item.desc && (
                          item.desc.map((item1, index1) => (
                            <p className="privacy_desc" key={index1} >{item1.Desc}</p>
                          ))
                        )
                      }
                      <ul>
                        {
                          item.ul.map((item6, index6) => (
                            <li className="mb-1" style={{ listStyleType: "circle" }} key={index6}><strong>{item6.Heading}&nbsp;</strong><a href={item6.url}>{item6.list}</a></li>
                          ))
                        }
                      </ul>
                    </>
                  ) : (
                    item.desc && (
                      item.desc.map((item1, index1) => (
                        <p className="privacy_desc" dangerouslySetInnerHTML={{ __html: item1.Desc }} key={index1} />
                      ))
                    )
                  )}

                </div>
              ) : (
                ""
              )
            )}
          </div>
        </div>

      </div>

    </>
  );
};

export default PrivacyPolicyLayout;
