import React from "react";
import { Helmet } from "react-helmet";
import TrustedOrganization from "../TrustedOrganization";
import { GetStarted } from "../FooterSection/GetStarted";
import FooterBrands from '../FooterBrands';
import accelerators from "../../data/ERP/Accelerators.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
const Banner = React.lazy(() => import('../Banner'));

export const Accelerators = () => {

  return (
    <>
      <Helmet>
        <title>Enterprise Accelerators | Phenom&#x1D49;cloud</title>
        <meta name="description" content="Accelerate growth with Enterprise Accelerators, ERP, HR Platforms, Cloud Accelerators, and Digital Transformation solutions to optimize operations and drive innovation." />
        <meta
          name="keywords"
          content="Enterprise Accelerators | Business Accelerators | ERP Accelerators | HR Platforms Accelerators | Cloud Accelerators | Digital Transformation Accelerators"
        />
      </Helmet>
      <Banner />
      <TrustedOrganization />
      {accelerators.map((item, index) => {
        const {
          id, className, title, desc, bannerImage, card, Header, Desc, cta_link, CTA, } = item;
        return (
          <div className="" key={id}>
            {id === 1 ? (
              <div className="row d-flex justify-content-evenly py-4    align-items-center   whitebg_section discover_possible reverse_flow Automation_benifit">
                <h4
                  className="  text-center fw-bold col-10 my-3"
                  style={{ fontSize: "25px" }}
                >
                  {title}
                </h4>
                <div className="col-md-4 text-center col-sm-12 col-12">
                  <div>
                    <img alt="Enterprise Accelerators | Phenom&#x1D49;Cloud"
                      src={require(`../../image/Accelerator/${bannerImage}.webp`)}
                      className="img-fluid AcceleratorsParaImage"
                    />
                  </div>
                </div>
                <div className="col-md-6 col-sm-12 col-12">
                  {desc.map((data, index) => (
                    <div className="head_on_desc_body mb-3">
                      {data.Desc_header && (
                        <p className="mb-3 h5 fw-bolder">{data.Desc_header}</p>
                      )}
                      {data.Desc &&
                        data.Desc.map((value, index1) => (
                          <div className="explore_service_section">
                            <div
                              className="d-flex align-items-center"
                              key={index}
                            >
                              <div>
                                <p
                                  className="normal-text-font"
                                  dangerouslySetInnerHTML={{
                                    __html: value.text,
                                  }}
                                ></p>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              ""
            )}

            {id === 2 && (
              <div
                className={`row d-flex justify-content-center align-items-center  px-5 Cloud_Solution_cta_section white_bg py-4`}
              >
                <div className="col-md-12 d-flex flex-column justify-content-center align-items-center">
                  <h1
                    className="Cloud_sloution_cta_header text-center fw-bold "
                    dangerouslySetInnerHTML={{ __html: Header }}
                  ></h1>
                  <h3 className="Cloud_sloution_cta_Subheader text-center mb-3">
                    {Desc}
                  </h3>
                  {cta_link && (
                    <Link
                      to={cta_link}
                      className="cta_btn text-decoration-none"
                    >
                      {CTA}
                    </Link>
                  )}
                </div>
              </div>
            )}

            {(id === 3) && (
              <div
                className={`row d-flex justify-content-center align-items-center allcoe_service_card_bg ${className ? className : ""
                  }`}
              >
                {title && (
                  <h3 className="text-center fw-bold hcm_offer_header mt-4 px-5">
                    {title}
                  </h3>
                )}
                {desc && (
                  <div className="title_desc  d-flex flex-column align-items-center">
                    {desc.map((value, items) => (
                      <>
                        {value.text && value && (
                          <p
                            key={items}
                            className="text-center service_desc"
                            style={{ width: "70%" }}
                          >
                            {value.text}
                          </p>
                        )}
                      </>
                    ))}
                  </div>
                )}
                <div className="row d-flex justify-content-center align-items-center our_service_body_content mt-3 mb-3">
                  {card.map((data, index) => {
                    return (
                      <div
                        className={`col-xl-3 col-md-5  our_service_card col-dm-12 m-3 card text-center erp-card-radius card-bg-color py-3 px-3 erp-card-background Description-Fontsize staffAugumentation-card-fix-height erp-accelerator-card-fix-height ${data.card_class}`}
                      >
                        <div className="cardImg text-start d-flex justify-content-between align-items-center">
                          <p className="fw-bold">{data.card_name}</p>
                          <img alt="Enterprise Accelerators | Phenom&#x1D49;Cloud"
                            src={require(`../../image/ERP/Accelerator/${data.icon}`)}

                            className="icon-box"
                          />
                        </div>
                        <div className="card-body px-1 d-flex">
                          <div className="onboarding_card_desc d-flex align-items-center">
                            <p
                              className="text-start Description-Fontsize"
                              dangerouslySetInnerHTML={{
                                __html: data.card_desc,
                              }}
                            ></p>
                          </div>
                        </div>
                        {data.card_url && (
                          <div className=" col-md-12 d-flex justify-content-end align-items-center">
                            <Link
                              style={{
                                marginRight: "2%",
                                textDecoration: "none",
                              }}
                              to={data.card_url}
                            >
                              <p>{data.card_btn}</p>
                            </Link>
                            <Link to={data.card_url}>
                              <button className="btn card-btn-color">
                                {" "}
                                <FontAwesomeIcon
                                  icon={faArrowRight}
                                  style={{ color: "#ffffff" }}
                                />
                              </button>
                            </Link>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            {
              id === 4 && (
                <div
                  className={`row d-flex justify-content-center col-12  px-5 Cloud_Solution_cta_section  light_blue_bg py-0 GetStart-flex-reverse ${id === 11 ? "light_blue_bg py-5" : " white_bg py-4"}`}
                >
                  <div className="downloadBookImage col-12 col-md-12 col-lg-5 col-xl-5 d-flex text-left justify-content-start">
                    <img
                      src={require(`../../image/HCM Coe/${bannerImage}.png`)}
                      className="ebookDownloadImg"
                    />
                  </div>
                  <div className="downloadBookContainer col-12 col-md-12 col-lg-6 col-xl-6 d-flex flex-column justify-content-center  align-items-start">
                    <h1 className="Cloud_sloution_cta_header HCM_cta_header fw-bold ">
                      {Header}
                    </h1>
                    <h5 className="Cloud_sloution_cta_Subheader  mb-3">
                      {Desc}
                    </h5>
                    {cta_link && (
                      <Link to={cta_link} style={{ width: 'fit-content' }} className="cta_btn ">
                        {CTA}
                      </Link>
                    )}
                  </div>

                </div>
              )
            }
            {id === 5 && (
              <div className="row d-flex justify-content-around  pt-4 pb-4">
                <div className="col-lg-11 col-md-11 col-sm-11 col-11 px-5 padding_fix">
                  <div className=" ">
                    <div className="">
                      <p
                        className="text-center fw-bold"
                        style={{ fontSize: "25px" }}
                        dangerouslySetInnerHTML={{ __html: title }}
                      ></p>
                      {desc.map((data, id) => (
                        <p className="line-_height text-center">{data.text}</p>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}


          </div>
        );
      })}
      <FooterBrands />
      <GetStarted />
    </>
  );
};
