import React, { useState } from "react";
import hcmcoe from "../../data/HCM/hcm_coe.json";
// import { Banner } from "../Banner";
import FooterBrands from "../FooterBrands";
import { Link } from "react-router-dom";
import TrustedOrganization from "../TrustedOrganization";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import Aos from "aos";
import { GetStarted } from "../FooterSection/GetStarted";
import { Helmet } from "react-helmet";
import { Accordion, AccordionButton } from "react-bootstrap";
import { GoHorizontalRule, GoPlus } from "react-icons/go";
const Banner = React.lazy(() => import('../Banner'));

const HCMCOE = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  });
  const [expandedItem, setExpandedItem] = useState(null);

  const handleToggle = (eventKey) => {
    setExpandedItem(expandedItem === eventKey ? null : eventKey);
  };

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const updateScreenSize = () => {
      setIsSmallScreen(window.innerWidth <= 1269);
    };

    window.addEventListener("resize", updateScreenSize);

    updateScreenSize();

    return () => {
      window.removeEventListener("resize", updateScreenSize);
    };
  }, []);

  return (
    <div className="HCM-COE" style={{ overflowX: "hidden" }}>
      <Helmet>
        <title>Enterprise HRTech | Phenom&#x1D49;cloud</title>
        <meta name="Drive enterprise growth with PhenomeCloud’s HRTech solutions. Offering advisory, consulting, implementation, People Analytics, and more to modernize HR operations." />

        <meta
          property="og:title"
          content=">Enterprise HRTech | Phenom&#x1D49;cloud"
        />
        <meta
          property="og:description"
          content="Drive enterprise growth with PhenomeCloud’s HRTech solutions. Offering advisory, consulting, implementation, People Analytics, and more to modernize HR operations."
        />
      </Helmet>
      <Banner />
      <TrustedOrganization />
      {hcmcoe.map((item) => {
        const { id, title, desc, Desc, bannerImage, cta_link, CTA, card, } = item;
        return (
          <div className="" key={id}>
            {id == 1 && (
              <div className="row d-flex justify-content-center align-items-center py-4 px-5 p-remove normal-text-font">
                <div className="col-md-12 d-flex flex-column justify-content-center align-items-center px-5 mainHeadingHcmCoe">
                  <h3 className="text-dark text-center fw-bold pt-2 HeadingSmallSize">
                    {title}
                  </h3>
                  <div className="coe_future_head_content row d-flex justify-content-center align-items-center mt-4 px-5">
                    <div className="text-black col-md-5 col-sm-12 col-12 text-center">
                      <img
                        alt="Enterprise HRtech Solutions| Phenom&#x1D49;Cloud"
                        src={require(`../../image/HCM Coe/${bannerImage}.webp`)}
                        className="HCMImg"
                      />
                    </div>
                    <div className="col-md-7 col-sm-12 col-12">
                      {desc.map((data, index) => (
                        <div className="head_on_desc_body mb-3" key={index}>
                          {data.Desc_header && (
                            <p
                              className="fw-bold mb-0"
                              style={{ fontSize: "18px" }}
                            ></p>
                          )}
                          {data.Desc && (
                            <p
                              dangerouslySetInnerHTML={{ __html: data.Desc }}
                            ></p>
                          )}
                          {data.btn_url && (
                            <a
                              className="btn HoverBtn button-hover overlaybtn text-white normal-text-font"
                              href={data.btn_url}
                            >
                              {data.btn}
                            </a>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {id === 2 && (
              <div
                className={`row d-flex justify-content-center col-12  px-5 Cloud_Solution_cta_section  light_blue_bg py-0 GetStart-flex-reverse`}
              >
                <div className="downloadBookContainer col-12 col-md-12 col-lg-6 col-xl-6 d-flex flex-column justify-content-center  align-items-start">
                  <h1 className="Cloud_sloution_cta_header HCM_cta_header fw-bold ">
                    {title}
                  </h1>
                  <h5 className="Cloud_sloution_cta_Subheader  mb-3">
                    {Desc}
                  </h5>
                  {cta_link && (
                    <Link to={cta_link} style={{ width: 'fit-content' }} className="cta_btn ">
                      {CTA}
                    </Link>
                  )}
                </div>
                <div className="text-black downloadBookImage col-12 col-md-12 col-lg-5 col-xl-5 d-flex justify-content-center">
                  <img
                    alt="Enterprise HRtech Solutions| Phenom&#x1D49;Cloud"
                    src={require(`../../image/HCM Coe/${bannerImage}.png`)}
                    className="ebookDownloadImg"
                  />
                </div>
              </div>
            )}
            {id === 3 && (
              <div className="row d-flex justify-content-center align-items-center bg-blue  card-section-content px-5 py-3 text-center padding_remove inside-border-padding-remove">
                <h3 className="text-center fw-bold hcm_offer_header mt-4 mb-4">
                  {title}
                </h3>

                <div className="card-section-content row px-5 justify-content-center text-center border-padding-remove pb-4">
                  {card.map((data, index) => (
                    <div className="col-md-4 mb-4 TwoCardWidth" key={index}>
                      <Link to={data.card_url} className="text-decoration-none">
                        <div
                          className="card text-center erp-card-radius card-bg-color py-3 px-3 erp-card-background Description-Fontsize h-100"
                          data-aos={`${isSmallScreen ? "fade-up" : data.popUp}`}
                        >
                          <div className="cardImg text-start d-flex justify-content-between align-items-center">
                            <p
                              className="fw-bold"
                              dangerouslySetInnerHTML={{
                                __html: data.card_name,
                              }}
                            ></p>
                            <img
                              alt="Enterprise HRtech Solutions| Phenom&#x1D49;Cloud"
                              src={require(`../../image/HCM Coe/COEimages/${data.icon}`)}
                              className="img-fluid hcm_coe_img"
                            />
                          </div>
                          <div className="card-body px-1 d-flex">
                            <p
                              className="text-start"
                              dangerouslySetInnerHTML={{
                                __html: data.card_desc,
                              }}
                            ></p>
                          </div>
                          {
                            data.card_url && (
                              <div className=" col-md-12 d-flex justify-content-end align-items-center">
                                <Link style={{ marginRight: '2%', textDecoration: 'none' }} to={data.card_url}>
                                  <p >Discover More</p></Link>
                                <Link to={data.card_url} >
                                  <button className="btn card-btn-color"> <FontAwesomeIcon icon={faArrowRight} style={{ color: "#ffffff" }} /></button></Link>
                              </div>
                            )
                          }
                        </div>

                      </Link>

                    </div>
                  ))}
                </div>
              </div>
            )}
            {(id === 4 || id == 6) && (
              <div
                className={`row d-flex justify-content-center align-items-center  px-5 Cloud_Solution_cta_section HCM_cta_section light_blue_bg py-5`}
              >
                <div className="col-md-12 d-flex flex-column justify-content-center align-items-center">
                  <h1 className="Cloud_sloution_cta_header text-center fw-bold ">
                    {title}
                  </h1>
                  <h5 className="Cloud_sloution_cta_Subheader HCM_cta_Subheader text-center mb-3">
                    {Desc}
                  </h5>
                  {cta_link && (
                    <Link to={cta_link} className="cta_btn">
                      {CTA}
                    </Link>
                  )}
                </div>
              </div>
            )}
            {id === 5 &&
              hcmcoe.map((item, index) => {
                const { className, accordians, title, desc, bannerImage, subDesc, } = item;
                return (
                  className === "Accordian" && (
                    <div className="row d-flex justify-content-around mt-3 mb-3">
                      <div className="col-lg-11 col-md-11 col-sm-11 col-11 px-5 padding_fix">
                        <div className=" ">
                          <div className="">
                            <p className="text-center fw-bold" style={{ fontSize: "25px" }} dangerouslySetInnerHTML={{ __html: title }}></p>
                            {desc.map((data, id) => (
                              <p className="line-_height text-center">
                                {data.text}
                              </p>
                            ))}
                          </div>
                          <div className="">
                            <div className="row d-flex justify-content-center whitebg_section EB_COE">
                              <div className="col-lg-6 col-md-6 col-sm-12 col-12 d-flex flex-column" style={{ padding: "16px" }}>
                                {
                                  <Accordion className="d-flex flex-column EcCoe justify-content-center align-items-center">
                                    {accordians.map((item, index) => {
                                      const { header, desc } = item;
                                      return (
                                        <Accordion.Item eventKey={index.toString()} className="mb-2 acoordian_items" key={index}
                                          style={{
                                            border: "none",
                                            background: "#E1EDFE",
                                          }}>
                                          <AccordionButton className="Dropdown-Bg-Color"
                                            onClick={() =>
                                              handleToggle(index.toString())
                                            }
                                            aria-expanded={
                                              expandedItem === index.toString()
                                            }>
                                            <div className="d-flex justify-content-between align-items-center w-100">
                                              <span className="fw-bolder sm-font-size">
                                                {header}
                                              </span>
                                              {expandedItem ===
                                                index.toString() ? (
                                                <GoHorizontalRule />
                                              ) : (
                                                <GoPlus className="PlusIcon" />
                                              )}
                                            </div>
                                          </AccordionButton>

                                          <Accordion.Body
                                            key={index}
                                            className="py-1"
                                          >
                                            {desc &&
                                              desc.map((item, index) => (
                                                <p
                                                  className="text-start"
                                                  dangerouslySetInnerHTML={{
                                                    __html: item.text,
                                                  }}
                                                ></p>
                                              ))}
                                          </Accordion.Body>
                                        </Accordion.Item>
                                      );
                                    })}
                                  </Accordion>
                                }
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-12 col-12 text-center dataManagement_Acrd EbCardImg">
                                <img alt="Enterprise HRtech Solutions| Phenom&#x1D49;Cloud"
                                  src={require(`../../image/ERP/DataManagement/${bannerImage}.webp`)}
                                  className=" accordian_img"
                                />
                              </div>
                            </div>
                          </div>
                          {
                            <div className="d-flex  flex-column align-items-center padding_fix">
                              <p
                                className="text-center"
                                dangerouslySetInnerHTML={{ __html: subDesc }}
                              ></p>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  )
                );
              })}
          </div>
        );
      })}

      <FooterBrands />
      <GetStarted />
    </div>
  );
};

export default HCMCOE;
