import React from 'react'
import { GetStarted } from './FooterSection/GetStarted';
import TrustedOrganization from './TrustedOrganization';
import { Helmet } from 'react-helmet';
const Banner = React.lazy(() => import('./Banner'));



export const About = ({ adata }) => {
    var aboutUs = adata
    return (
        <>
            <Helmet>
                <title>Phenom&#x1D49;Cloud | About Us</title>
                <meta name="description" content="PhenomeCloud is a technology solutions provider committed to empowering businesses with innovative tools and tailored support, maximizing the value of your technology investments." />
            </Helmet>

            <Banner />
            <TrustedOrganization />
            <div className="container-fluid p-0 overflow-hidden">
                {
                    aboutUs.map((item) => {
                        const { Id, Heading, Img, Data } = item;
                        return (
                            <div key={Id}>
                                {
                                    Id === 1 && (
                                        <div className="row pt-2 pb-0 abtUs_body justify-content-center">
                                            <div className="col-md-11 d-flex WeAre card_body_section_bg align-items-center pb-0 pt-1 AboutUsCardReverse">
                                                <div className="col-md-4 ps-5">
                                                    <div className="WeAreImg">
                                                        <img src={require(`../image/About/${Img}`)} alt="" />
                                                    </div>
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="Content px-5">
                                                        <div className="Heading text-start mb-2">
                                                            <h3 className='fw-bold text-dark'>{Heading}</h3>
                                                        </div>
                                                        <div className="Description text-start">
                                                            {
                                                                Data.map((item) => {
                                                                    const { Id, Name } = item;
                                                                    return (
                                                                        <div key={Id}>
                                                                            <p className='mb-3' dangerouslySetInnerHTML={{ __html: Name }} />
                                                                        </div>
                                                                    )
                                                                })
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    Id === 2 && (
                                        <>
                                            <div className="row justify-content-center card_section_bg">
                                                <div className='col-md-11 py-5'>
                                                    <div className="PromiseHeading col d-flex justify-content-center">
                                                        <h3 className='text-white fw-bold'>{Heading}</h3>
                                                    </div>
                                                    <div className="cardContainer AboutUsCard px-5">
                                                        <div className="row justify-content-between">
                                                            {
                                                                Data.map((item) => {
                                                                    const { Id, Name, Img, ClassName, ArrowLine, ArrowName } = item;
                                                                    return (
                                                                        <div className="col-md-5 py-4 arrow-container" key={Id}>
                                                                            <div className="card h-100">
                                                                                <div className="card-body">
                                                                                    <div className="imgContent">
                                                                                        <img className='card-img-top' src={require(`../image/About/${Img}`)} alt="" />
                                                                                    </div>
                                                                                    <p className='Description Description-Fontsize' dangerouslySetInnerHTML={{ __html: Name }} />
                                                                                </div>
                                                                            </div>
                                                                            <div className={ClassName} >
                                                                                <div dangerouslySetInnerHTML={{ __html: ArrowLine }}></div>
                                                                                <div className={ArrowName}></div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </>

                                    )
                                }
                            </div>
                        )
                    })
                }

            </div>
            <GetStarted />
        </>
    )
}
