import React from "react";
import siteMapData from "../data/SiteMap.json";
import { Link } from "react-router-dom";
import { BsChevronRight } from "react-icons/bs";
import '../style/Sitemap.css'
const Banner = React.lazy(() => import('./Banner'));

const SiteMap = () => {
  return (
    <>=
      <Banner />
      <div className="siteMap_body container mt-5">
        <ul style={{ paddingLeft: 0 }}>
          <li style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
            <BsChevronRight
              style={{
                fontSize: "20px",
                color: "#f37021",
                marginRight: "8px",
              }}
            />
            <Link
              to={siteMapData[0].Url}

              className="hover-link"
            >
              <h4 className="siteMap_body_header m-0">
                {siteMapData[0].Heading}
              </h4>
            </Link>
          </li>

          {/* Iterate through each data section */}
          {siteMapData[0].Data.map((section, sectionIndex) => (
            <div key={sectionIndex} className={`${sectionIndex > 0 ? 'inlineClass' : ''} mb-2`} >
              {/* Section Header with Icon */}
              <li style={{ display: "flex", alignItems: "center", marginBottom: "15px" }}>
                <BsChevronRight
                  style={{
                    fontSize: "20px",
                    color: "#f37021",
                    marginRight: "8px",
                  }}
                />
                <h4 className="m-0" >
                  {section.Header}
                </h4>
              </li>

              {/* Subcategories */}
              <div className="row" style={{ paddingLeft: "5%" }}>
                {section.ul.map((category, categoryIndex) => (
                  <div key={categoryIndex} className={`${sectionIndex > 0 ? 'col-md-10' : 'col-md-4'} mb-2`}>
                    {/* Category Header */}
                    {category.Header && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <BsChevronRight
                          style={{
                            fontSize: "20px",
                            color: "#f37021",
                            marginRight: "8px",
                          }}
                        />
                        <h5 className="fw-700 m-0">{category.Header}</h5>
                      </div>
                    )}

                    {/* Sub-items */}
                    {category.UL && (
                      <ul style={{ paddingLeft: "5%" }}>
                        {category.UL.map((item, itemIndex) => (
                          <li
                            key={itemIndex}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "8px",
                            }}
                          >
                            <BsChevronRight
                              style={{
                                fontSize: "16px",
                                color: "#f37021",
                                marginRight: "8px",
                              }}
                            />
                            <Link
                              to={item.Url || "#"}
                              style={{

                                cursor: item.Url ? "pointer" : "not-allowed",
                              }}
                              className={item.Url ? "hover-link" : ""}
                            >
                              {item.li}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </ul>
      </div>
    </>
  );
};

export default SiteMap;
