import React, { useState } from "react";
import { Helmet } from "react-helmet";
import TrustedOrganization from "../TrustedOrganization";
import { GetStarted } from "../FooterSection/GetStarted";
import integrations from "../../data/ERP/Integrations.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Accordion, AccordionButton } from "react-bootstrap";
import { GoHorizontalRule, GoPlus } from "react-icons/go";
const Banner = React.lazy(() => import('../Banner'));

export const Integrations = () => {
  const [expandedItem, setExpandedItem] = useState(null);

  const handleToggle = (eventKey) => {
    setExpandedItem(expandedItem === eventKey ? null : eventKey);
  };
  return (
    <>
      <Helmet>
        <title>Technology Integrations Consulting | Phenom&#x1D49;cloud</title>
        <meta
          name="description"
          content="Partner with Phenom&#x1D49;Cloud for expert technology integrations consulting. Seamlessly implement and integrate enterprise platforms for growth, efficiency, and success."
        />
        <meta
          name="keywords"
          content="Enterprise Platforms Integrations |Technology Integration Consulting | Seamless Systems Integration| End-to-End systems integration | Cloud Platforms Integration "
        />
      </Helmet>
      <Helmet>
        <title>Technology Integrations Consulting | Phenom&#x1D49;Cloud</title>
        <meta
          name="description"
          content="Partner with PhenomeCloud for expert technology integrations consulting. Seamlessly implement and integrate enterprise platforms for growth, efficiency, and success."
        />
        <meta
          name="keywords"
          content="Enterprise Platforms Integrations |Technology Integration Consulting | Seamless Systems Integration| End-to-End systems integration | Cloud Platforms Integration"
        />
        <meta
          name="robots"
          content="index, follow"
        />
        <meta
          property="og:title"
          content="Technology Integrations Consulting | Phenom&#x1D49;Cloud"
        />
        <meta
          property="og:description"
          content="Partner with PhenomeCloud for expert technology integrations consulting. Seamlessly implement and integrate enterprise platforms for growth, efficiency, and success."
        />
        <meta
          property="og:keywords"
          content="Enterprise Platforms Integrations |Technology Integration Consulting | Seamless Systems Integration| End-to-End systems integration | Cloud Platforms Integration"
        />
      </Helmet>
      <Banner />
      <TrustedOrganization />
      {integrations.map((item, index) => {
        const {
          id, className, title, desc, bannerImage, accordians, downDescripiton, card, Header, Desc, cta_link, CTA, } = item;
        return (
          <div className="" key={id}>
            {id === 1 ? (
              <div className="row d-flex justify-content-evenly py-4    align-items-center   whitebg_section discover_possible reverse_flow Automation_benifit">
                <h4
                  className="  text-center fw-bold col-10 my-3"
                  style={{ fontSize: "25px" }}
                >
                  {title}
                </h4>
                <div className="col-md-4 text-center col-sm-12 col-12">
                  <div>
                    <img alt="Enterprise Technology Integrations | Phenom&#x1D49;Cloud"
                      src={require(`../../image/ERP/Integration/${bannerImage}.webp`)}
                      className="img-fluid "
                    />
                  </div>
                </div>
                <div className="col-md-6 col-sm-12 col-12">
                  {desc.map((data, index) => (
                    <div className="head_on_desc_body mb-3">
                      {data.Desc_header && (
                        <p className="mb-3 h5 fw-bolder">{data.Desc_header}</p>
                      )}
                      {data.Desc &&
                        data.Desc.map((value, index1) => (
                          <div className="explore_service_section">
                            <div
                              className="d-flex align-items-center"
                              key={index}
                            >
                              <div>
                                <p
                                  className="normal-text-font"
                                  dangerouslySetInnerHTML={{
                                    __html: value.text,
                                  }}
                                ></p>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              ""
            )}

            {id === 2 && (
              <div
                className={`row d-flex justify-content-center align-items-center  px-5 Cloud_Solution_cta_section white_bg py-4`}
              >
                <div className="col-md-12 d-flex flex-column justify-content-center align-items-center">
                  <h1
                    className="Cloud_sloution_cta_header text-center fw-bold "
                    dangerouslySetInnerHTML={{ __html: Header }}
                  ></h1>
                  <h3 className="Cloud_sloution_cta_Subheader text-center mb-3">
                    {Desc}
                  </h3>
                  {cta_link && (
                    <Link
                      to={cta_link}
                      className="cta_btn text-decoration-none"
                    >
                      {CTA}
                    </Link>
                  )}
                </div>
              </div>
            )}

            {(id === 3) && (
              <div
                className={`row d-flex justify-content-center align-items-center allcoe_service_card_bg ${className ? className : ""
                  }`}
              >
                {title && (
                  <h3 className="text-center fw-bold px-5 hcm_offer_header mt-4">
                    {title}
                  </h3>
                )}
                {desc && (
                  <div className="title_desc  d-flex flex-column align-items-center">
                    {desc.map((value, items) => (
                      <>
                        {value.text && value && (
                          <p
                            key={items}
                            className="text-center service_desc"
                            style={{ width: "70%" }}
                          >
                            {value.text}
                          </p>
                        )}
                      </>
                    ))}
                  </div>
                )}
                <div className="row d-flex justify-content-center align-items-center our_service_body_content mt-3 mb-3">
                  {card.map((data, index) => {
                    return (
                      <div key={index}
                        className={` col-xl-3 col-lg-5 col-md-12 col-sm-12  our_service_card col-dm-12 m-3 card text-center erp-card-radius card-bg-color py-3 px-3 erp-card-background Description-Fontsize integrations-card-fix-height ${data.card_class}`}
                      >
                        <div className="cardImg text-start d-flex justify-content-between align-items-center">
                          <p className="fw-bold">{data.card_name}</p>
                          <img alt="Enterprise Technology Integrations | Phenom&#x1D49;Cloud"
                            src={require(`../../image/ERP/Integration/${data.icon}`)}

                            className="icon-box"
                          />
                        </div>
                        <div className="card-body px-1 d-flex">
                          <div className="onboarding_card_desc d-flex align-items-center">
                            <p
                              className="text-start Description-Fontsize"
                              dangerouslySetInnerHTML={{
                                __html: data.card_desc,
                              }}
                            ></p>
                          </div>
                        </div>
                        {data.card_url && (
                          <div className=" col-md-12 d-flex justify-content-end align-items-center">
                            <Link
                              style={{
                                marginRight: "2%",
                                textDecoration: "none",
                              }}
                              to={data.card_url}
                            >
                              <p>{data.card_btn}</p>
                            </Link>
                            <Link to={data.card_url}>
                              <button className="btn card-btn-color">
                                {" "}
                                <FontAwesomeIcon
                                  icon={faArrowRight}
                                  style={{ color: "#ffffff" }}
                                />
                              </button>
                            </Link>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            {id === 4 && (
              <div
                className={`row d-flex justify-content-center align-items-center  px-5 Cloud_Solution_cta_section white_bg py-4`}
              >
                <div className="col-md-12 d-flex flex-column justify-content-center align-items-center">
                  <h1
                    className="Cloud_sloution_cta_header text-center fw-bold "
                    dangerouslySetInnerHTML={{ __html: Header }}
                  ></h1>
                  <h3 className="Cloud_sloution_cta_Subheader text-center mb-3">
                    {Desc}
                  </h3>
                  {cta_link && (
                    <Link
                      to={cta_link}
                      className="cta_btn text-decoration-none"
                    >
                      {CTA}
                    </Link>
                  )}
                </div>
              </div>
            )}

            {id === 5 && (
              <div className="row d-flex justify-content-around  pt-4 pb-4">
                <div className="col-lg-11 col-md-11 col-sm-11 col-11 px-5 padding_fix">
                  <div className=" ">
                    <div className="">
                      <p
                        className="text-center fw-bold"
                        style={{ fontSize: "25px" }}
                        dangerouslySetInnerHTML={{ __html: title }}
                      ></p>
                      {desc.map((data, id) => (
                        <p className="line-_height text-center">{data.text}</p>
                      ))}
                    </div>
                    <div className="">
                      {id == 5 ? (
                        <div className="">
                          <div className="row d-flex align-items-center justify-content-center flex-row-reverse  Advisory_service">
                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 text-center ">
                              <img alt="Enterprise Technology Integrations | Phenom&#x1D49;Cloud"
                                src={require(`../../image/ERP/Integration/${bannerImage}.webp`)}
                                className=" accordian_img"
                              />
                            </div>
                            <div
                              className="col-lg-6 col-md-6 col-sm-12 col-12 d-flex flex-column  "
                              style={{ padding: "16px" }}
                            >
                              {
                                <Accordion className="d-flex flex-column EcCoe">
                                  {accordians.map((item, index) => {
                                    const { header, desc } = item;
                                    return (
                                      <Accordion.Item
                                        eventKey={index.toString()}
                                        className="mb-2 acoordian_items"
                                        key={index}
                                        style={{
                                          border: "none",
                                          background: "#E1EDFE",
                                        }}
                                      >
                                        <AccordionButton
                                          className="Dropdown-Bg-Color"
                                          onClick={() =>
                                            handleToggle(index.toString())
                                          }
                                          aria-expanded={
                                            expandedItem === index.toString()
                                          }
                                        >
                                          <div className="d-flex justify-content-between align-items-center w-100">
                                            <span className="fw-bolder sm-font-size">
                                              {header}
                                            </span>
                                            {expandedItem ===
                                              index.toString() ? (
                                              <GoHorizontalRule />
                                            ) : (
                                              <GoPlus className="PlusIcon" />
                                            )}
                                          </div>
                                        </AccordionButton>

                                        <Accordion.Body
                                          key={index}
                                          className="py-1"
                                        >
                                          {desc &&
                                            desc.map((item, index) => (
                                              <p
                                                className="text-start"
                                                dangerouslySetInnerHTML={{
                                                  __html: item.text,
                                                }}
                                              ></p>
                                            ))}
                                        </Accordion.Body>
                                      </Accordion.Item>
                                    );
                                  })}
                                </Accordion>
                              }
                            </div>
                          </div>

                          {downDescripiton && (
                            <p
                              className="Description-Fontsize mt-3 text-center"
                              dangerouslySetInnerHTML={{
                                __html: downDescripiton,
                              }}
                            ></p>
                          )}
                        </div>
                      ) : (
                        <div className="row d-flex justify-content-center whitebg_section Advisory_service">
                          <div
                            className="col-lg-6 col-md-6 col-sm-12 col-12 d-flex flex-column "
                            style={{ padding: "16px" }}
                          >
                            {
                              <Accordion className="d-flex flex-column EcCoe">
                                {accordians.map((item, index) => {
                                  const { header, desc } = item;
                                  return (
                                    <Accordion.Item
                                      eventKey={index.toString()}
                                      className="mb-2 acoordian_items"
                                      key={index}
                                      style={{
                                        border: "none",
                                        background: "#E1EDFE",
                                      }}
                                    >
                                      <AccordionButton
                                        className="Dropdown-Bg-Color"
                                        onClick={() =>
                                          handleToggle(index.toString())
                                        }
                                        aria-expanded={
                                          expandedItem === index.toString()
                                        }
                                      >
                                        <div className="d-flex justify-content-between align-items-center w-100">
                                          <span className="fw-bolder sm-font-size">
                                            {header}
                                          </span>
                                          {expandedItem === index.toString() ? (
                                            <GoHorizontalRule />
                                          ) : (
                                            <GoPlus className="PlusIcon" />
                                          )}
                                        </div>
                                      </AccordionButton>

                                      <Accordion.Body
                                        key={index}
                                        className="py-1"
                                      >
                                        {desc &&
                                          desc.map((item, index) => (
                                            <p
                                              className="text-start"
                                              dangerouslySetInnerHTML={{
                                                __html: item.text,
                                              }}
                                            ></p>
                                          ))}
                                      </Accordion.Body>
                                    </Accordion.Item>
                                  );
                                })}
                              </Accordion>
                            }
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12 col-12 text-center erp_analytics_img">
                            <img alt="Enterprise Technology Integrations | Phenom&#x1D49;Cloud"
                              src={require(`../../image/ERP/SolutionEngineer/${bannerImage}.webp`)}
                              className=" accordian_img"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        );
      })}
      <GetStarted />
    </>
  );
};
