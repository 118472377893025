import React, { useState, useEffect, useMemo } from "react";
import homedata from "../data/Home/agile.json";
import { Carousel } from "react-bootstrap";
import debounce from 'lodash.debounce'; // Import debounce from lodash

const TrustedOrganization = () => {
  var org = homedata[0].org;

  const [itemsPerSlide, setItemsPerSlide] = useState(5); // Default to 5 items per slide

  // Debounced resize handler
  const handleResize = debounce(() => {
    const width = window.innerWidth;
    if (width <= 767) {
      setItemsPerSlide(2);  // 2 items per slide on small screens
    } else if (width <= 1300) {
      setItemsPerSlide(3);  // 3 items per slide on medium screens
    } else if (width <= 1600) {
      setItemsPerSlide(5);  // 5 items per slide on large screens
    } else if (width <= 1700) {
      setItemsPerSlide(6);  // 6 items per slide on extra-large screens
    } else {
      setItemsPerSlide(7);  // 7 items per slide on very large screens
    }
  }, 200); // 200ms debounce for resize events

  // Update the number of items per slide based on window width
  useEffect(() => {
    // Call once on mount
    handleResize();

    // Add resize listener
    window.addEventListener('resize', handleResize);

    // Cleanup the listener on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Memoizing the grouping logic to avoid recalculating on every render
  const groupedItems = useMemo(() => {
    const groups = [];
    for (let i = 0; i < org.length; i += itemsPerSlide) {
      groups.push(org.slice(i, i + itemsPerSlide));
    }
    return groups;
  }, [org, itemsPerSlide]);

  return (
    <div className="container-flude TrustedBrand">
      <div className="row d-flex justify-content-around">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
          {homedata.map((data) => {
            const { id, title, org } = data;
            return (
              <div key={id}>
                {id === 1 && (
                  <div className="row align-items-center mt-1">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 py-2">
                      <div className="container">
                        <h6 className="text-center fw-bold" dangerouslySetInnerHTML={{ __html: title }}></h6>
                      </div>
                    </div>

                    <div className="OrgContainer">
                      <Carousel interval={null} indicators={false} controls={true}>
                        {groupedItems.map((itemsToDisplay, idx) => {
                          return (
                            <Carousel.Item key={idx}>
                              <div className="d-flex justify-content-center">
                                {itemsToDisplay.map((item, index) => {
                                  const { Img, Url } = item;
                                  return (
                                    <div className="image_wrapper" key={index}>
                                      <a href={Url} target="_blank" rel="noopener noreferrer">
                                        <img
                                          className={`img-fluid ${Img}`}
                                          src={require(`../image/trustedorglogos/${Img}.webp`)}
                                          alt={`Logo ${item}`}
                                          loading="lazy" // Lazy load images
                                        />
                                      </a>
                                    </div>
                                  );
                                })}
                              </div>
                            </Carousel.Item>
                          );
                        })}
                      </Carousel>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TrustedOrganization;
