import React, { useState, useRef, useEffect, memo } from 'react'
import NavLogo from '../image/navLogo.png'
import { Link, useNavigate } from 'react-router-dom'
import { GoChevronDown } from "react-icons/go";
import Data from '../data/Hearder.json'
import { GoChevronRight } from "react-icons/go";
import SearchComponent from './SearchComponent'
import { IoLanguageSharp } from "react-icons/io5";
import { FiPhone } from "react-icons/fi";
import { HiOutlineMail } from "react-icons/hi";
import { RiMenu5Fill } from "react-icons/ri";
import { IoMdClose } from "react-icons/io";
import { GoArrowLeft } from "react-icons/go";
import { Collapse } from 'react-bootstrap'
import LazyLoad from 'react-lazyload';




const Header = () => {
    var NavBarData = Data;

    const [activeCollapse, setActiveCollapse] = useState(null);
    const [rotatedItem, setRotatedItem] = useState(null);
    const [isScreenSmall, setIsScreenSmall] = useState(window.innerWidth <= 991);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const [navigateTo, setNavigateTo] = React.useState(null);

    // Handle parent hover
    const handleMouseEnterParent = (collapseId) => {
        setActiveCollapse(collapseId); // Show the parent collapse
        setRotatedItem(collapseId); // Set the hovered item to be rotated
    };

    // Handle parent mouse leave
    const handleMouseLeaveParent = () => {
        setActiveCollapse(null); // Hide the parent collapse
        setRotatedItem(null); // Reset rotation
    };


    const handleMouseEnter = (collapseId) => {
    };

    const handleMouseLeave = () => {
        setActiveCollapse(null); // Hide the collapse
        setRotatedItem(null); // Reset rotation
    };

    const handleResize = () => {
        setIsScreenSmall(window.innerWidth <= 991);
    };

    useEffect(() => {
        // Add resize event listener
        window.addEventListener("resize", handleResize);
        return () => {
            // Cleanup resize event listener
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        if (!open && navigateTo) {
            navigate(navigateTo);
        }
    }, [open, navigateTo, navigate]);





    return (
        <>
            <nav className="navbar navbar-expand-lg bg-light">
                <div className="container-fluid row NavContent">
                    <div className="col-md-3 col-sm-3 col-3">
                        <Link to={`/`} className="PhenomLogo">
                            <LazyLoad offset={0}>
                                <img
                                    className="navImg"
                                    src={NavLogo}
                                    alt="phenomecloud logo icon"
                                    width="120"  // Set a fixed width
                                    height="40"  // Set a fixed height
                                    style={{ objectFit: 'contain' }}
                                />
                            </LazyLoad>
                        </Link>
                    </div>
                    {/* show upto 1080px */}
                    {
                        !isScreenSmall && (<>
                            <div className="col-md-6">
                                <div className="collapse navbar-collapse" id="navbarNav">
                                    <ul className="navbar-nav w-100 justify-content-start">
                                        {
                                            NavBarData.map((item, index) => {
                                                const { Id, className, ParentCollapse, Name, Data } = item;
                                                return (
                                                    <li className="nav-item h-100 d-flex align-items-center" key={index}
                                                        onMouseEnter={() => handleMouseEnterParent(ParentCollapse)}
                                                        onMouseLeave={handleMouseLeaveParent}
                                                    >
                                                        <button className="nav-link" role="button">
                                                            {Name} <GoChevronDown className={`down-icon ${rotatedItem === ParentCollapse ? "rotate" : ""}`}
                                                            />
                                                        </button>
                                                        <div className={`collapse ${activeCollapse === ParentCollapse ? "show" : ""}`} id={ParentCollapse} >
                                                            <div className="card card-body rounded-0 p-4 px-3 CollapseBorder">
                                                                {
                                                                    <div className="row row-gap-1">
                                                                        {
                                                                            Data.map((item, index) => {
                                                                                const { GridName, ChildClassName, Img, Heading, Description, Url, target } = item;
                                                                                return (
                                                                                    <>
                                                                                        <div className={GridName}>
                                                                                            {
                                                                                                ChildClassName && (
                                                                                                    <Link to={Url}
                                                                                                        target={target}
                                                                                                        rel={target === "_blank" ? "noopener noreferrer" : undefined}
                                                                                                        className='text-decoration-none' onClick={() => setActiveCollapse(null)}>
                                                                                                        <div className={ChildClassName}>
                                                                                                            <div className="IconSection d-flex justify-content-center align-items-start me-2">
                                                                                                                {Img && (
                                                                                                                    <img src={require(`../image/NavIcon/${Img}`)} alt={Heading} className="NavIcon" />
                                                                                                                )}
                                                                                                            </div>
                                                                                                            <div className="Content">
                                                                                                                <h3 className='NavHeading'>{Heading}</h3>
                                                                                                                <p className='NavDescription'>{Description}</p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </Link>
                                                                                                )
                                                                                            }
                                                                                        </div>
                                                                                    </>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                }

                                                            </div>
                                                        </div>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="Links d-flex align-items-center">
                                    <div className="row d-flex LinkChild w-100 justify-content-end">
                                        <div className="col-md-5 col-sm-5 col-xxl-4 d-flex justify-content-center align-items-center Link p-2">
                                            <SearchComponent />

                                            <a href="tel:+1 855-978-6816" className='mx-1' target="_blank" rel="noopener noreferrer">
                                                {/* <img className='phoneIcon p-2' src={phoneIcon} alt="" /> */}
                                                <FiPhone className='phoneIcon' />
                                            </a>

                                            <a href="mailto:info@phenomecloud.com" className='mx-1' target="_blank" rel="noopener noreferrer"
                                                onMouseEnter={handleMouseEnter}
                                                onMouseLeave={handleMouseLeave}>
                                                {/* <img className='emailIcon p-2' src={emailIcon} alt="" /> */}
                                                <HiOutlineMail className="emailIcon" />
                                                {/* {isHovered ? (
                                        <HiOutlineMailOpen className="emailIcon" />
                                        ) : (
                                        )} */}
                                            </a>
                                        </div>
                                        <div className="col-md-5 col-sm-5 col-xxl-4 d-none">
                                            <div className="d-flex align-items-center">
                                                <IoLanguageSharp className='LanguageIcon' />
                                                <select className="form-select" aria-label="Default select example">
                                                    <option value="Eng">English</option>
                                                    <option value="Fr">Français</option>
                                                    <option value="3">Three</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        )
                    }
                    {/* show upto 1079px */}
                    {isScreenSmall && (
                        <div className="col-md-5 col-sm-8 col-8 text-end">
                            <div className="d-flex">
                                <div className="AllLinks d-flex align-items-center">
                                    <div className="row d-flex LinkChild w-100 justify-content-end">
                                        <div className="col-md-5 col-sm-5 col-xxl-4 col-4 d-flex justify-content-center align-items-center MainLinkContent p-2">
                                            <SearchComponent />

                                            <a href="tel:+1 855-978-6816" className='mx-1' target="_blank" rel="noopener noreferrer">
                                                {/* <img className='phoneIcon p-2' src={phoneIcon} alt="" /> */}
                                                <FiPhone className='phoneIcon' />
                                            </a>

                                            <a href="mailto:info@phenomecloud.com" className='mx-1' target="_blank" rel="noopener noreferrer"
                                                onMouseEnter={handleMouseEnter}
                                                onMouseLeave={handleMouseLeave}>
                                                {/* <img className='emailIcon p-2' src={emailIcon} alt="" /> */}
                                                <HiOutlineMail className="emailIcon" />
                                            </a>
                                        </div>
                                        <div className="col-md-5 col-sm-5 col-xxl-4 d-none">
                                            <div className="d-flex align-items-center">
                                                <IoLanguageSharp className='LanguageIcon' />
                                                <select className="form-select" aria-label="Default select example">
                                                    <option value="Eng">English</option>
                                                    <option value="Fr">Français</option>
                                                    <option value="3">Three</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button className='CollapseBtn' onClick={() => { setOpen(!open); }} aria-controls="NavbarToggle" aria-expanded={open}>
                                    {
                                        !open && (
                                            <RiMenu5Fill className='NavbarToggleBtn' />
                                        )
                                    }
                                    {
                                        open && (
                                            <IoMdClose className='NavbarToggleBtn' />
                                        )
                                    }
                                </button>
                            </div>
                        </div>
                    )}
                    {
                        isScreenSmall && (
                            <>

                                <Collapse in={open} >
                                    <div id="NavbarToggle" className='smallToggle p-3'>
                                        <div className="row gap-2">
                                            {
                                                NavBarData.map((item, index) => {
                                                    const { Id, className, SmallChildCollapse, Name, Data } = item;
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <div className="col-12">
                                                                <div className="card border-0 py-2 px-2" data-bs-toggle="offcanvas" data-bs-target={`#${SmallChildCollapse}`} aria-controls="staticBackdrop">
                                                                    <div className="d-flex align-items-center justify-content-between">
                                                                        <p className='smallNavMainHeading'>{Name}</p>
                                                                        <GoChevronRight className='SmallRightIcon fw-bolder' />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                    )
                                                })
                                            }
                                            <div className="col-md-12">
                                                <div className="card py-3 px-2 NavbarContactUsContent">
                                                    <Link to={'/contact'} className='NavbarContactUsBtn text-center w-100 text-decoration-none' onClick={() => {
                                                        setActiveCollapse(null);
                                                        setOpen(false);
                                                    }}>Contact Us</Link>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="card py-3 px-2 NavbarDemoContent">
                                                    <Link to={'/demo'} className='NavbarDemoBtn text-center w-100 text-decoration-none' onClick={() => {
                                                        setActiveCollapse(null);
                                                        setOpen(false);
                                                    }}>Schedule a Demo</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ChildCollapseContent">
                                            {
                                                NavBarData.map((item, index) => {
                                                    const { SmallChildCollapse, Data } = item;
                                                    return (
                                                        <div className="offcanvas offcanvas-start childCollapse" data-bs-backdrop="static" tabindex="-1" id={SmallChildCollapse} aria-labelledby="staticBackdropLabel">
                                                            <div className="offcanvas-header childCollapse d-flex justify-content-between">
                                                                <a type="button" className='text-prime fw-bolder backBtn' data-bs-dismiss="offcanvas" aria-label="Close"><GoArrowLeft /> Back</a>
                                                                <img className='navImg' src={NavLogo} alt="" />
                                                                <button className='CollapseBtn' onClick={() => setOpen(!open)} data-bs-dismiss="offcanvas" aria-label="Close" aria-controls="NavbarToggle" aria-expanded={open}>
                                                                    <IoMdClose className='NavbarToggleBtn' />
                                                                </button>
                                                            </div>
                                                            <div className="offcanvas-body">
                                                                <div className='row row-gap-3'>

                                                                    {
                                                                        Data.map((item, index) => {
                                                                            const { smallGridName, smallScreenClassName, Img, Heading, Description, Url } = item;
                                                                            const isExternal = Url?.startsWith("https:");
                                                                            const target = isExternal ? "_blank" : undefined;
                                                                            return (
                                                                                <div className={smallGridName}>
                                                                                    {isExternal ? (
                                                                                        <a
                                                                                            className='card px-3 py-2 smallCardSection cardsection d-flex flex-row text-decoration-none'
                                                                                            href={Url}
                                                                                            target={target}
                                                                                            rel={target === "_blank" ? "noopener noreferrer" : undefined}
                                                                                        >
                                                                                            <div className="d-flex w-100 align-items-center justify-content-between">
                                                                                                <div className="d-flex  ">
                                                                                                    <div className="smallNavIcon">
                                                                                                        {Img && (
                                                                                                            <img
                                                                                                                src={require(`../image/NavIcon/${Img}`)}
                                                                                                                alt={Heading}
                                                                                                                className="NavIcon"
                                                                                                            />
                                                                                                        )}
                                                                                                    </div>
                                                                                                    <div className="d-flex flex-column ps-2">
                                                                                                        <h3 className="NavHeading">{Heading}</h3>
                                                                                                        <p className="NavDescription">{Description}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                                {Url && <GoChevronRight className="SmallRightIcon fw-bolder" />}
                                                                                            </div>
                                                                                        </a>
                                                                                    ) :
                                                                                        (<Link to={Url}
                                                                                            className={`${smallScreenClassName}`}

                                                                                            // data-bs-dismiss="offcanvas" aria-label="Close"
                                                                                            onClick={(e) => {
                                                                                                e.preventDefault();

                                                                                                // Cleanup offcanvas and backdrop
                                                                                                const offcanvas = document.querySelector('.offcanvas.show');
                                                                                                if (offcanvas) {
                                                                                                    offcanvas.classList.remove('show');
                                                                                                }
                                                                                                const backdrop = document.querySelector('.offcanvas-backdrop');
                                                                                                if (backdrop) {
                                                                                                    backdrop.remove();
                                                                                                }

                                                                                                // Reset body styles
                                                                                                const body = document.body;
                                                                                                body.classList.remove('modal-open');
                                                                                                body.style.overflow = '';
                                                                                                body.style.paddingRight = '';

                                                                                                // Close React-controlled collapse state
                                                                                                setOpen(false);

                                                                                                setTimeout(() => {
                                                                                                    navigate(Url);  // Navigate to the URL
                                                                                                }, 0);
                                                                                            }}

                                                                                        >
                                                                                            <div className="d-flex align-items-center justify-content-between">
                                                                                                <div className="d-flex  ">
                                                                                                    <div className="smallNavIcon">
                                                                                                        {Img && (
                                                                                                            <img
                                                                                                                src={require(`../image/NavIcon/${Img}`)}
                                                                                                                alt={Heading}
                                                                                                                className="NavIcon"
                                                                                                            />
                                                                                                        )}
                                                                                                    </div>
                                                                                                    <div className="d-flex flex-column ps-2">
                                                                                                        <h3 className="NavHeading">{Heading}</h3>
                                                                                                        <p className="NavDescription">{Description}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                                {Url && <GoChevronRight className="SmallRightIcon fw-bolder" />}
                                                                                            </div>
                                                                                        </Link>)}

                                                                                </div>
                                                                            )
                                                                        })
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </Collapse>
                            </>
                        )
                    }



                </div >
            </nav >
        </ >
    )
}
export default Header;
