import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import BannerData from '../data/Banner.json';
import { useParams } from 'react-router-dom';
import LazyLoad from 'react-lazyload';

export const SliderCarousel = () => {
    let fixNum = 5; // Default number of items
    let { className } = useParams();

    if (className === undefined) {
        className = 'home';
    }

    useEffect(() => {
        if (className === 'enterprise-cloud-solutions') {
            fixNum = 4; // Adjust the number of items for a specific class
        }
        if (className === 'enterprise-erp-solutions') {
            fixNum = 3; // Adjust the number of items for a specific class
        }
        if (className === 'enterprise-hrtech-solutions') {
            fixNum = 3;
        }
        if (className === 'enterprise-digital-solutions') {
            fixNum = 3;
        }

    }, [className]);


    const boxesRef = useRef([]);
    const stageRef = useRef(null);

    // const setTransformOrigin = (box, index, angle) => {
    //     const transformValue = {
    //         "max-width: 1176px and min-width: 992px": -170,
    //         "max-width: 992px and min-width: 768px": -130,
    //         "max-width: 767px and min-width: 500px": -150,
    //         "max-width: 499px and min-width: 350px": -110,
    //         "max-width: 349px and min-width: 250px": -90,
    //     };

    //     let transformOriginValue = -220; // Default value

    //     for (const [query, value] of Object.entries(transformValue)) {
    //         if (window.matchMedia(`(${query})`).matches) {
    //             transformOriginValue = value;
    //             break;
    //         }
    //     }

    //     gsap.set(box, {
    //         rotationY: index * angle,
    //         transformOrigin: `50% 50% ${transformOriginValue}px`,
    //     });
    // };

    const setTransformOrigin = (box, index, angle) => {
        if (window.matchMedia("(max-width: 1176px) and (min-width: 992px)").matches) {
            gsap.set(box, {
                rotationY: index * angle,
                transformOrigin: "50% 50% -170"
            });
        }
        else if (window.matchMedia("(max-width: 992px) and (min-width: 768px)").matches) {
            gsap.set(box, {
                rotationY: index * angle,
                transformOrigin: "50% 50% -130"
            });
        }
        else if (window.matchMedia("(max-width: 767px) and (min-width: 500px)").matches) {
            gsap.set(box, {
                rotationY: index * angle,
                transformOrigin: "50% 50% -150"
            });
        }
        else if (window.matchMedia("(max-width: 499px) and (min-width: 350px)").matches) {
            let transformOriginValue = "48% 50% -170";

            if (className === 'enterprise-cloud-solutions') {
                transformOriginValue = "50% 50% -130";
            } else if (className === 'enterprise-erp-solutions') {
                transformOriginValue = "50% 50% -100";
            } else if (className === 'enterprise-hrtech-solutions') {
                transformOriginValue = "50% 50% -100";
            } else if (className === 'enterprise-digital-solutions') {
                transformOriginValue = "50% 50% -100";
            }
            else if (className === 'home') {
                transformOriginValue = "50% 50% -140";
            }

            gsap.set(box, {
                rotationY: index * angle,
                transformOrigin: transformOriginValue
            });

        } else if (window.matchMedia("(max-width: 349px) and (min-width: 250px)").matches) {
            let transformOriginValue = "50% 50% -90";

            if (className === 'home') {
                transformOriginValue = "50% 50% -120";
            }
            gsap.set(box, {
                rotationY: index * angle,
                transformOrigin: transformOriginValue
            });
        } else {
            gsap.set(box, {
                rotationY: index * angle,
                transformOrigin: "50% 50% -220"
            });
        }
    };



    useEffect(() => {
        const boxes = boxesRef.current;
        const stage = stageRef.current;

        const angle = 360 / fixNum; // Adjust angle based on the number of items

        // Set initial perspective and transform style
        gsap.set(stage, {
            perspective: 1100,
            transformStyle: 'preserve-3d',
        });

        // Set initial positions for each box
        boxes.forEach((box, index) => {
            setTransformOrigin(box, index, angle);
            box.dataset.rotationY = index * angle;
        });

        const handleResize = () => {
            boxes.forEach((box, index) => {
                setTransformOrigin(box, index, angle);
            });
        };

        const handleNavigation = (event) => {
            const isPrev = event.target.id === 'prev';
            const direction = isPrev ? -1 : 1;

            gsap.to(boxes, {
                duration: 1,
                rotationY: (index, target) => {
                    const currentRotationY = parseFloat(target.dataset.rotationY || 0);
                    const newRotationY = currentRotationY + direction * angle;
                    target.dataset.rotationY = newRotationY;
                    return newRotationY;
                },
                stagger: 0,
            });
        };

        const $nav = document.getElementById('nav');
        if ($nav) {
            $nav.addEventListener('click', handleNavigation);
        }

        // Add resize event listener
        window.addEventListener('resize', handleResize);

        return () => {
            if ($nav) {
                $nav.removeEventListener('click', handleNavigation);
            }
            window.removeEventListener('resize', handleResize);
        };
    }, [fixNum]);

    return (
        <div className="stage" ref={stageRef}>
            {BannerData.filter((banner) => banner.Name === className)[0]?.ScrollImg.map(
                (item, index) => {
                    const { Id, Img } = item;
                    return (
                        <div
                            key={Id}
                            className="box"
                            ref={(el) => (boxesRef.current[index] = el)}
                        >
                            {Img && (
                                <LazyLoad offset={0}>
                                    <img className="Scrolling_Img" src={require(`../image/BannerImg/${Img}`)} alt="Banner Image" />
                                </LazyLoad>
                            )}
                        </div>
                    );
                }
            )}
        </div>
    );
};
