import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Accordion from 'react-bootstrap/Accordion';
import EDT_Data from '../../data/EDT/EDT_COE.json'
import '../../style/EDT.css'
import { AccordionButton } from 'react-bootstrap';
import { GoHorizontalRule, GoPlus } from 'react-icons/go';
import TrustedOrganization from '../TrustedOrganization';
import { GetStarted } from '../FooterSection/GetStarted';
import FooterBrands from '../FooterBrands';
import { Helmet } from 'react-helmet';
import AOS from 'aos'
const Banner = React.lazy(() => import('../Banner'));


const EDT_COE = () => {

    const [expandedItem, setExpandedItem] = useState(null);

    const handleToggle = (eventKey) => {
        setExpandedItem(expandedItem === eventKey ? null : eventKey);
    };

    useEffect(() => {
        AOS.init({ duration: 2000 })
    })

    const [isSmallScreen, setIsSmallScreen] = useState(false);
    useEffect(() => {
        const updateScreenSize = () => {
            setIsSmallScreen(window.innerWidth <= 1269);
        };

        window.addEventListener('resize', updateScreenSize);

        updateScreenSize();

        return () => {
            window.removeEventListener('resize', updateScreenSize);
        };
    }, []);


    return (
        <>
            <Helmet>
                <title>Enterprise Digital | Phenom&#x1D49;Cloud </title>
                <meta name="description" content="PhenomeCloud offers end-to-end enterprise digital solutions, including cloud, HR tech, digitalization, automation, and managed services for growth" />

                <meta
                    property="og:title"
                    content=">Enterprise Digital | Phenom&#x1D49;Cloud"
                />
                <meta
                    property="og:description"
                    content="PhenomeCloud offers end-to-end enterprise digital solutions, including cloud, HR tech, digitalization, automation, and managed services for growth"
                />
            </Helmet>
            <Banner />
            <TrustedOrganization />
            {EDT_Data.map((item, index) => {
                const { id, title, desc, bannerImage, accordians, infotext, grid, sub_title, card, button, cta_link, Header, btn_link, subDesc, GridName } = item;
                return (
                    <div className="" key={id}>
                        {id == 1 ? (
                            <div className="row d-flex justify-content-around py-4 p-5 mb-3 align-items-center">
                                {title && <h3 className="text-dark text-center fw-bold pt-2">{title}</h3>}
                                <div className='coe_future_head_content row d-flex justify-content-center align-items-center mt-4 px-5'>
                                    <div className="col-md-4 text-center col-sm-12 col-12 text-black">
                                        <div>
                                            <img alt='Enterprise Digital Solutions | Phenom&#x1D49;Cloud' style={{ "- webkit - transform": "scaleX(-1)", transform: "scaleX(-1)" }} src={require(`../../image/AdvisoryService/${bannerImage}.webp`)} className="img-size" />
                                        </div>
                                    </div>
                                    <div className="col-md-7 col-sm-12 col-12">
                                        {
                                            desc.map((data, index) => (
                                                <div className="head_on_desc_body mb-3">

                                                    {data.Desc &&
                                                        data.Desc.map((value, index1) => (

                                                            <div className="explore_service_section">
                                                                <div className="d-flex align-items-center mb-2" key={index}>
                                                                    <div>
                                                                        <p className="normal-text-font" dangerouslySetInnerHTML={{ __html: value.text }} ></p>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        ))
                                                    }
                                                    {data.ul &&
                                                        <ul>{
                                                            data.ul.map((value, index1) => (

                                                                <li className="normal-text-font text-start" dangerouslySetInnerHTML={{ __html: value.li }} ></li>

                                                            ))}
                                                        </ul>
                                                    }
                                                    {data.BtmDesc &&

                                                        <div className="explore_service_section">
                                                            <div className="d-flex align-items-center mb-2" key={index}>
                                                                <div>
                                                                    <p className="normal-text-font" dangerouslySetInnerHTML={{ __html: data.BtmDesc }} ></p>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        ) : ''}

                        {
                            (id == 2) && (<div className={`row d-flex justify-content-center align-items-center  px-5 Cloud_Solution_cta_section  light_blue_bg py-5`}>
                                <div className="col-md-12 d-flex flex-column  justify-content-center align-items-center">
                                    <div className="  col-12 col-md-12 col-lg-6 col-xl-6 d-flex flex-column justify-content-center  align-items-center">
                                        <h1 className="Cloud_sloution_cta_header text-center fw-bold ">{title}</h1>
                                        <h3 className="Cloud_sloution_cta_Subheader text-center mb-3">{desc}</h3>

                                    </div>
                                    <div className=" col-12 col-md-12 col-lg-5 col-xl-5 d-flex justify-content-center">
                                        <button className="cta_btn"> {
                                            cta_link === '/contact' ? (
                                                <Link to={cta_link}>{button}</Link>
                                            ) : (
                                                <Link to={cta_link}>{button}</Link>
                                            )
                                        }</button>
                                    </div>
                                </div>
                            </div>
                            )
                        }

                        {
                            (id == 3) && (
                                <div className="row d-flex justify-content-center align-items-center bg-blue">
                                    {title && <h3 className="text-center fw-bold hcm_offer_header mt-4 px-5" data-aos={"fade-up"}>{title}</h3>}
                                    {
                                        desc.map((value, items) => (
                                            value && <p key={items} className="text-center service_desc" style={{ width: '50%', fontSize: '16px' }} data-aos={"fade-up"}>{value.text}</p>
                                        ))
                                    }

                                    <div className="card-section-content row px-5 justify-content-center text-center ">
                                        {
                                            card.map((data, index) => {
                                                const { GridName } = data;
                                                return <div className={GridName} key={index} data-aos={`${isSmallScreen ? 'fade-up' : data.popUp}`}>

                                                    <Link to={data.card_url} className='text-decoration-none' >

                                                        <div className='card text-center erp-card-radius card-bg-color py-3 px-3 h-100 erp-card-background Description-Fontsize card-fix-height'>
                                                            <div className='text-black cardImg text-start d-flex justify-content-between align-items-center'>

                                                                <p className="fw-bold">{data.card_name}</p>

                                                                <img src={require(`../../image/EDT/${data.icon}`)} alt="Enterprise Digital Solutions | Phenom&#x1D49;Cloud" srcset="" className='icon-box' />
                                                            </div>
                                                            <div className="card-body px-1 d-flex flex-column text-start justify-content-center">

                                                                <p className="Description text-start m-0" dangerouslySetInnerHTML={{ __html: data.card_desc }} ></p>
                                                            </div>
                                                            {
                                                                data.card_url && (
                                                                    <div className=" col-md-12 d-flex justify-content-end align-items-center">
                                                                        <Link style={{ marginRight: '2%', textDecoration: 'none' }} to={data.card_url}>
                                                                            <p >{data.card_btn}</p></Link>
                                                                        <Link to={data.card_url} >
                                                                            <button className="btn card-btn-color"> <FontAwesomeIcon icon={faArrowRight} style={{ color: "#ffffff" }} /></button></Link>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>

                                                    </Link>

                                                </div>

                                            })
                                        }
                                    </div>

                                </div>
                            )
                        }

                        {id === 4 && (
                            <div className={`row d-flex justify-content-center align-items-center  px-5 Cloud_Solution_cta_section  light_blue_bg py-5`}>
                                <div className="col-md-12 d-flex flex-column justify-content-center align-items-center">
                                    <h1 className="Cloud_sloution_cta_header text-center fw-bold ">{Header}</h1>
                                    <h3 className="Cloud_sloution_cta_Subheader text-center mb-3">{desc}</h3>
                                    {
                                        btn_link &&
                                        <button className="cta_btn">
                                            {
                                                btn_link === '/contact' ? (
                                                    <Link to={btn_link}>{button}</Link>
                                                ) : (
                                                    <Link to={btn_link}>{button}</Link>
                                                )
                                            }
                                        </button>

                                    }
                                </div>
                            </div>
                        )}

                        {id == 5 && (
                            <div className="row d-flex justify-content-around mt-3 mb-3">
                                <div className="col-lg-11 col-md-11 col-sm-11 col-11 px-5 padding_fix">
                                    <div className=" ">
                                        <div className="">
                                            <p className="text-center fw-bold" style={{ fontSize: '25px' }} dangerouslySetInnerHTML={{ __html: title }}></p>
                                            {
                                                desc.map((data, id) => (
                                                    <p className='line-_height text-center'>{data.text}</p>
                                                ))
                                            }
                                        </div>
                                        <div className="">
                                            <div className="row d-flex justify-content-center whitebg_section EB_COE">
                                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 d-flex flex-column  " style={{ padding: '16px' }}>
                                                    {<Accordion className='d-flex flex-column EcCoe justify-content-center align-items-center'>
                                                        {accordians.map((item, index) => {
                                                            const { header, desc } = item;
                                                            return (
                                                                <Accordion.Item eventKey={index.toString()} className='mb-2 acoordian_items' key={index} style={{ border: 'none', background: '#E1EDFE' }} >
                                                                    <AccordionButton className='Dropdown-Bg-Color'
                                                                        onClick={() => handleToggle(index.toString())}
                                                                        aria-expanded={expandedItem === index.toString()}
                                                                    >
                                                                        <div className="d-flex justify-content-between align-items-center w-100">
                                                                            <span className='fw-bolder sm-font-size'>{header}</span>
                                                                            {expandedItem === index.toString() ? <GoHorizontalRule /> : <GoPlus className='PlusIcon' />}
                                                                        </div>
                                                                    </AccordionButton>

                                                                    <Accordion.Body key={index} className='py-1'>
                                                                        {
                                                                            desc && desc.map((item, index) => (
                                                                                <p className='text-start' dangerouslySetInnerHTML={{ __html: item.text }} key={index}></p>
                                                                            ))
                                                                        }
                                                                    </Accordion.Body>

                                                                </Accordion.Item>
                                                            );
                                                        })}
                                                    </Accordion>
                                                    }
                                                </div>
                                                <div className="text-black col-lg-6 col-md-6 col-sm-12 col-12 text-center dataManagement_Acrd EbCardImg">
                                                    <img alt='Enterprise Digital Solutions | Phenom&#x1D49;Cloud' src={require(`../../image/ERP/DataManagement/${bannerImage}.webp`)} className=" accordian_img" />
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            <div className='d-flex  flex-column align-items-center padding_fix'>
                                                <p className='text-center' dangerouslySetInnerHTML={{ __html: subDesc }} ></p>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        )

                        }

                    </div>
                );
            })}
            <FooterBrands />
            <GetStarted />
        </>
    )
}

export default EDT_COE