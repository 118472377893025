import React from 'react'
import TrustedOrganization from './TrustedOrganization'
import DisclaimerData from '../data/Disclaimer.json'
import { GetStarted } from './FooterSection/GetStarted'
const Banner = React.lazy(() => import('./Banner'));

const Disclaimer = () => {
    return (
        <>
            <Banner />
            <TrustedOrganization />
            <div className='container-fluid col-md-12 col-som-12' style={{ padding: "6%" }}>
                {
                    DisclaimerData[0].Data.map((data, index) => (
                        <div className="disclaimer_content" key={index}>
                            {
                                data.Header !== "" ? <h5 style={{ color: "#f37021", fontWeight: "bold" }} dangerouslySetInnerHTML={{ __html: data.Header }} /> : null
                            }
                            {
                                data.desc.map((data1, index1) => (
                                    <p className="privacy_desc" dangerouslySetInnerHTML={{ __html: data1.Desc }}></p>
                                ))
                            }
                        </div>
                    ))
                }
            </div>
            <GetStarted />
        </>
    )
}

export default Disclaimer