import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ind_flag from '../image/India_Flag.png'
import us_flag from '../image/USA_Flag.png'
const Banner = React.lazy(() => import('./Banner'));

const TalentHub = () => {
  const [activeTab, setActiveTab] = useState('India'); // Default tab is 'India'

  const handleTabClick = (tab) => {
    setActiveTab(tab); // Set the active tab when a tab is clicked
  };

  return (
    <>
     <Banner />
    <section className="htitle">
      <div className="container-fluid col-md-12 col-xs-12 cleard careersMain">
        <div className="col-md-10 offset-md-1 cleard template_width">
          <h2 className="text-center font-weight-bold">JOIN OUR TEAM</h2>
          <p className="text-center line-height3"> Phenom<sup>e</sup>cloud 
          is a full-service technology and support company that strives to help businesses meet their
                current and future challenges.
                We are a knowledge-driven organization. We sustain our competitive advantage through continuous learning
                and
                knowledge sharing. We encourage creative thinking and allow our employees to champion their ideas. Join us to 
                work with great people.
          </p>
        </div>
      </div>

      <div className="col-md-12 template_width container">
        <div className="col-md-12 mt-5">
          <div>
            <div className="card-heading text-center" id="careersPanelhead">
              <ul className="nav nav-tabs" id="careersLocationTabs">
                <li className="India nav-item">
                  <button
                    id="tab1primary"
                    className={`nav-link b_none ${
                      activeTab === "India" ? "active" : ""
                    }`}
                    onClick={() => handleTabClick("India")}
                  >
                    <img
                      className="img-responsive"
                      src={ind_flag}
                      alt="India_Flag"
                    />
                    <span>India</span>
                  </button>
                </li>
                <li className="US nav-item">
                  <button
                    id="tab2primary"
                    className={`nav-link b_none ${
                      activeTab === "US" ? "active" : ""
                    }`}
                    onClick={() => handleTabClick("US")}
                  >
                    <img
                      className="img-responsive"
                      src={us_flag}
                      alt="USA_Flag"
                    />
                    <span>United States</span>
                  </button>
                </li>
              </ul>
            </div>
            <div className="card-body careertabcontent" id="tabContent">
              <div className="tab-content" id="nav-tabContent">
                {/* India Tab Content */}
                {activeTab === "India" && (
                  <div
                    className="tab-pane show active IndiaTabcontent"
                    role="tabpanel"
                    aria-labelledby="tab1primary"
                    tabindex="0"
                  >
                    <div className="row indian_job_post p-0">
                      <section className="col-md-4 col-sm-6">
                        <Link to="/TalentHub/job-details/sr-dotnet-developer">
                          <div className="openPositionBlock">
                            <h5>Senior .Net Developer</h5>
                            <p className="orangetext">Vijayawada, India</p>
                            <h6>Engineering</h6>
                          </div>
                        </Link>
                      </section>
                      <section className="col-md-4 col-sm-6">
                        <Link to="/TalentHub/job-details/jr-dotnet-developer">
                          <div className="openPositionBlock">
                            <h5>.Net Developer (Fresher)</h5>
                            <p className="orangetext">Vijayawada, India</p>
                            <h6>Engineering</h6>
                          </div>
                        </Link>
                      </section>

                      <section className="col-md-4 col-sm-6">
                        <Link to="/TalentHub/job-details/application-architect">
                          <div className="openPositionBlock">
                            <h5>Application Architect</h5>
                            <p className="orangetext">Vijayawada, India</p>
                            <h6>Professional Services</h6>
                          </div>
                        </Link>
                      </section>

                      <section className="col-md-4 col-sm-6">
                        <Link to="/TalentHub/job-details/project-manager">
                          <div className="openPositionBlock">
                            <h5>Project Manager</h5>
                            <p className="orangetext">Vijayawada, India</p>
                            <h6>Professional Services</h6>
                          </div>
                        </Link>
                      </section>

                      <section className="col-md-4 col-sm-6">
                        <Link to="/TalentHub/job-details/sr-business-analyst">
                          <div className="openPositionBlock">
                            <h5>Senior Business Analyst</h5>
                            <p className="orangetext">Vijayawada, India</p>
                            <h6>Professional Services</h6>
                          </div>
                        </Link>
                      </section>

                      <section className="col-md-4 col-sm-6">
                        <Link to="/TalentHub/job-details/sr-content-writer">
                          <div className="openPositionBlock">
                            <h5>Senior Content Writer</h5>
                            <p className="orangetext">Vijayawada, India</p>
                            <h6>Marketing</h6>
                          </div>
                        </Link>
                      </section>

                      <section className="col-md-4 col-sm-6">
                        <Link to="/TalentHub/job-details/sr-database-developer">
                          <div className="openPositionBlock">
                            <h5>Senior Database Developer</h5>
                            <p className="orangetext">Vijayawada, India</p>
                            <h6>Engineering</h6>
                          </div>
                        </Link>
                      </section>

                      <section className="col-md-4 col-sm-6">
                        <Link to="/TalentHub/job-details/digital-marketing-executive">
                          <div className="openPositionBlock">
                            <h5>Digital Marketing Executive</h5>
                            <p className="orangetext">Vijayawada, India</p>
                            <h6>Marketing</h6>
                          </div>
                        </Link>
                      </section>

                      <section className="col-md-4 col-sm-6">
                        <Link to="/TalentHub/job-details/functional-analyst">
                          <div className="openPositionBlock">
                            <h5>Functional Analyst</h5>
                            <p className="orangetext">Vijayawada, India</p>
                            <h6>Professional Services</h6>
                          </div>
                        </Link>
                      </section>

                      <section className="col-md-4 col-sm-6">
                        <Link to="/TalentHub/job-details/inside-sales-representative">
                          <div className="openPositionBlock">
                            <h5>Inside Sales Representative</h5>
                            <p className="orangetext">Vijayawada, India</p>
                            <h6>Sales</h6>
                          </div>
                        </Link>
                      </section>

                      <section className="col-md-4 col-sm-6">
                        <Link to="/TalentHub/job-details/network-administrator">
                          <div className="openPositionBlock">
                            <h5>Network Admin</h5>
                            <p className="orangetext">Vijayawada, India</p>
                            <h6>Engineering</h6>
                          </div>
                        </Link>
                      </section>

                      <section className="col-md-4 col-sm-6">
                        <Link to="/TalentHub/job-details/sr-quality-analyst">
                          <div className="openPositionBlock">
                            <h5>Quality Analyst</h5>
                            <p className="orangetext">Vijayawada, India</p>
                            <h6>Engineering</h6>
                          </div>
                        </Link>
                      </section>

                      <section className="col-md-4 col-sm-6">
                        <Link to="/TalentHub/job-details/technical-analyst">
                          <div className="openPositionBlock">
                            <h5>Technical Analyst</h5>
                            <p className="orangetext">Vijayawada, India</p>
                            <h6>Professional Services</h6>
                          </div>
                        </Link>
                      </section>

                      <section className="col-md-4 col-sm-6">
                        <Link to="/TalentHub/job-details/ui-developer">
                          <div className="openPositionBlock">
                            <h5>UI Developer</h5>
                            <p className="orangetext">Vijayawada, India</p>
                            <h6>Creative</h6>
                          </div>
                        </Link>
                      </section>

                      <section className="col-md-4 col-sm-6">
                        <Link to="/TalentHub/job-details/ux-designer">
                          <div className="openPositionBlock">
                            <h5>UX Designer</h5>
                            <p className="orangetext">Vijayawada, India</p>
                            <h6>Creative</h6>
                          </div>
                        </Link>
                      </section>

                      <section className="col-md-4 col-sm-6">
                        <Link to="/TalentHub/job-details/instructional-designer">
                          <div className="openPositionBlock">
                            <h5>Instructional Designer</h5>
                            <p className="orangetext">Vijayawada, India</p>
                            <h6>Professional Services</h6>
                          </div>
                        </Link>
                      </section>

                      <section className="col-md-4 col-sm-6">
                        <Link to="/TalentHub/job-details/full-stack-developer">
                          <div className="openPositionBlock">
                            <h5>Full Stack Developer</h5>
                            <p className="orangetext">Vijayawada, India</p>
                            <h6>Engineering</h6>
                          </div>
                        </Link>
                      </section>
                    </div>
                  </div>
                )}

                {/* US Tab Content */}
                {activeTab === "US" && (
                  <div
                    className="tab-pane show active USTabcontent"
                    role="tabpanel"
                    aria-labelledby="tab2primary"
                    tabindex="0"
                  >
                    <div className="row us_job_post  p-0">
                      <section className="col-md-4 col-sm-6">
                        <Link to="/TalentHub/job-details/sales-analyst">
                          <div className="openPositionBlock">
                            <h5>Sales Analyst</h5>
                            <p className="orangetext">Irving, TX, US</p>
                            <h6>Sales</h6>
                          </div>
                        </Link>
                      </section> 
                      <section className="col-md-4 col-sm-6">
                        <Link to="/TalentHub/job-details/sr-sales-analyst">
                          <div className="openPositionBlock">
                            <h5>Sr. Sales Analyst</h5>
                            <p className="orangetext">Irving, TX, US</p>
                            <h6>Sales</h6>
                          </div>
                        </Link>
                      </section>

                      <section className="col-md-4 col-sm-6">
                        <Link to="/TalentHub/job-details/hcm-solutions-consultant">
                          <div className="openPositionBlock">
                            <h5>HCM Consultant</h5>
                            <p className="orangetext">Irving, TX, US</p>
                            <h6>Professional Services</h6>
                          </div>
                        </Link>
                      </section>

                      <section className="col-md-4 col-sm-6">
                        <Link to="/TalentHub/job-details/people-technology-implementation-analyst">
                          <div className="openPositionBlock">
                            <h5>People Technology Implementation Analyst</h5>
                            <p className="orangetext">Irving, TX, US</p>
                            <h6>Professional Services</h6>
                          </div>
                        </Link>
                      </section>

                      <section className="col-md-4 col-sm-6">
                        <Link to="/TalentHub/job-details/content-writer">
                          <div className="openPositionBlock">
                            <h5>Content Writer</h5>
                            <p className="orangetext">Irving, TX, US</p>
                            <h6>Marketing</h6>
                          </div>
                        </Link>
                      </section>

                      <section className="col-md-4 col-sm-6">
                        <Link to="/TalentHub/job-details/quality-analyst">
                          <div className="openPositionBlock">
                            <h5>Quality Analyst</h5>
                            <p className="orangetext">Irving, TX, US</p>
                            <h6>Engineering</h6>
                          </div>
                        </Link>
                      </section>

                      <section className="col-md-4 col-sm-6">
                        <Link to="/TalentHub/job-details/business-analyst">
                          <div className="openPositionBlock">
                            <h5>Business Analyst</h5>
                            <p className="orangetext">Irving, TX, US</p>
                            <h6>Professional Services</h6>
                          </div>
                        </Link>
                      </section>

                      <section className="col-md-4 col-sm-6">
                        <Link to="/TalentHub/job-details/development-engineer-wpf">
                          <div className="openPositionBlock">
                            <h5>Development Engineer - WPF</h5>
                            <p className="orangetext">Irving, TX, US</p>
                            <h6>Engineering</h6>
                          </div>
                        </Link>
                      </section>

                      <section className="col-md-4 col-sm-6">
                        <Link to="/TalentHub/job-details/business-development-manager">
                          <div className="openPositionBlock">
                            <h5>Business Development Manager</h5>
                            <p className="orangetext">Irving, TX, US</p>
                            <h6>Sales</h6>
                          </div>
                        </Link>
                      </section>

                      <section className="col-md-4 col-sm-6">
                        <Link to="/TalentHub/job-details/responsive-web-developer">
                          <div className="openPositionBlock">
                            <h5>Responsive Web - Developer</h5>
                            <p className="orangetext">Irving, TX, US</p>
                            <h6>Engineering</h6>
                          </div>
                        </Link>
                      </section>

                      <section className="col-md-4 col-sm-6">
                        <Link to="/TalentHub/job-details/appliction-developer-net">
                          <div className="openPositionBlock">
                            <h5>Application Developer .NET</h5>
                            <p className="orangetext">Irving, TX, US</p>
                            <h6>Engineering</h6>
                          </div>
                        </Link>
                      </section>

                      <section className="col-md-4 col-sm-6">
                        <Link to="/TalentHub/job-details/application-developer-java">
                          <div className="openPositionBlock">
                            <h5>Application Developer Java</h5>
                            <p className="orangetext">Irving, TX, US</p>
                            <h6>Engineering</h6>
                          </div>
                        </Link>
                      </section>

                      <section className="col-md-4 col-sm-6">
                        <Link to="/TalentHub/job-details/hr-generalist">
                          <div className="openPositionBlock">
                            <h5>HR Generalist</h5>
                            <p className="orangetext">Irving, TX, US</p>
                            <h6>Human Resources</h6>
                          </div>
                        </Link>
                      </section>

                      <section className="col-md-4 col-sm-6">
                        <Link to="/TalentHub/job-details/database-developer">
                          <div className="openPositionBlock">
                            <h5>Database Developer</h5>
                            <p className="orangetext">Irving, TX, US</p>
                            <h6>Engineering</h6>
                          </div>
                        </Link>
                      </section>

                      <section className="col-md-4 col-sm-6">
                        <Link to="/TalentHub/job-details/staffing-accounts-manager">
                          <div className="openPositionBlock">
                            <h5>Staffing Accounts Manager</h5>
                            <p className="orangetext">Irving, TX, US</p>
                            <h6>Staffing</h6>
                          </div>
                        </Link>
                      </section>

                      <section className="col-md-4 col-sm-6">
                        <Link to="/TalentHub/job-details/software-engineer">
                          <div className="openPositionBlock">
                            <h5>Software Engineer</h5>
                            <p className="orangetext">Irving, TX, US</p>
                            <h6>Engineering</h6>
                          </div>
                        </Link>
                      </section>

                      <section className="col-md-4 col-sm-6">
                        <Link to="/TalentHub/job-details/inside-sales-and-customer-success-representative">
                          <div className="openPositionBlock">
                            <h5>
                              Inside Sales & Customer Success Representative
                            </h5>
                            <p className="orangetext">Irving, TX, US</p>
                            <h6>Sales</h6>
                          </div>
                        </Link>
                      </section>

                      <section className="col-md-4 col-sm-6">
                        <Link to="/TalentHub/job-details/systems-administrator">
                          <div className="openPositionBlock">
                            <h5>Systems Administrator</h5>
                            <p className="orangetext">Irving, TX, US</p>
                            <h6>Infrastructure</h6>
                          </div>
                        </Link>
                      </section>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
   
  );
};

export default TalentHub;
