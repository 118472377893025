import React, { useState, useEffect } from "react";
import "../style/Contact.css";
import { IoArrowBack } from "react-icons/io5";
import { GetStarted } from "./FooterSection/GetStarted";
import TrustedOrganization from "./TrustedOrganization";
import { Helmet } from 'react-helmet';
const Banner = React.lazy(() => import('./Banner'));



const Contact = () => {
  const [isSubmitted, setSubmitted] = useState(false);
  const [formKey, setFormKey] = useState(0); // State to force re-render
  const MeetingLink = 'https://meetings.hubspot.com/natasha-ramdial-roopnarine';

  const loadForm = () => {
    const script = document.createElement('script');
    script.src = '//js.hsforms.net/forms/embed/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "1751195",
          formId: "a74585cb-3938-4132-b8ec-da98d81e2747",
          target: `.myFormDemo-${formKey}`, // Use the formKey to differentiate
          onFormSubmit: () => { setSubmitted(true); },
        });
      }
    });
  };

  useEffect(() => {
    loadForm();
  }, [formKey]);

  const CustomPopup = () => {
    return (
      <div className="custom-popup-backdrop">
        <div className="custom-popup">
          <div className='d-flex flex-column justify-content-evenly'>
            <h2 className='text-center fw-bold'>Thank you for reaching out!</h2>
            <p className='text-center text-dark'>
              A Phenom<sup>e</sup>Cloud specialist will get in touch with you soon to discuss how we can help maximize the potential of your HR technology. While you wait, feel free to explore how Phenom eCloud can enhance your HR experience.
            </p>
            <p className='text-center text-dark'>
              We’re excited to work with you!
            </p>
            <p className='text-center d-flex justify-content-center mt-4'>
              <button
                className='btn btn-outline-purple backButton mx-1'
                onClick={() => {
                  setSubmitted(false);
                  setFormKey(prevKey => prevKey + 1); // Increment key to force re-render
                }}>
                <span><IoArrowBack /></span>Back to page
              </button>
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="ContantBody" style={{ paddingTop: '2%' }}>
      <Helmet>
        <title> Phenom&#x1D49;Cloud | Demo</title>
        <meta name="description" content="Discover how PhenomeCloud’s experts deliver strategic consulting, implementation, maintenance, and optimization for human capital management technology like Ceridian Dayforce®." />
      </Helmet>

      <Banner />
      {/* <TrustedOrganization /> */}
      <div className='bg-color-form text-dark pt-4'>
        <div className="contact-form row container-fluid justify-content-center   p-0 w-100">
          <div className='demo d-flex justify-content-center text-dark form_section col-10 col-md-8 p-3'>
            <div className='mt-4 demo form_section_content w-100 pt-3' >
              <h2 className='fw-bold text-center'>Schedule a Demo</h2>
              <div className={`myFormDemo myFormDemo-${formKey}`}></div>
              {isSubmitted && <CustomPopup />}
            </div>
          </div>
        </div>
      </div>
      <GetStarted />
    </div>
  );
};

export default Contact;