import React, { useState, useEffect } from "react";
import "../style/Contact.css";
import { IoArrowBack } from "react-icons/io5";
import { GetStarted } from "./FooterSection/GetStarted";
import TrustedOrganization from "./TrustedOrganization";
import { Helmet } from 'react-helmet';
const Banner = React.lazy(() => import('./Banner'));



const Contact = () => {
  const [isSubmitted, setSubmitted] = useState(false);
  const [formKey, setFormKey] = useState(0); // State to force re-render
  const MeetingLink = 'https://meetings.hubspot.com/natasha-ramdial-roopnarine';

  const loadForm = () => {
    const script = document.createElement('script');
    script.src = '//js.hsforms.net/forms/embed/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "1751195",
          formId: "499be090-f6bb-436f-a61f-00ca7211e520",
          target: `.myFormDemo-${formKey}`, // Use the formKey to differentiate
          onFormSubmit: () => { setSubmitted(true); },
        });
      }
    });
  };

  useEffect(() => {
    loadForm();
  }, [formKey]); // Load the form whenever the formKey changes

  const CustomPopup = () => {
    return (
      <div className="custom-popup-backdrop">
        <div className="custom-popup">
          <div className='d-flex flex-column justify-content-evenly'>
            <h2 className='text-center fw-bold'>Thank you for reaching out!</h2>
            <p className='text-center text-dark'>
              A Phenom <sup>e</sup>Cloud specialist will get in touch with you soon to discuss how we can help maximize the potential of your HR technology. While you wait, feel free to explore how Phenom eCloud can enhance your HR experience.
            </p>
            <p className='text-center text-dark'>
              We’re excited to work with you!
            </p>
            <p className='text-center d-flex justify-content-center mt-4'>
              <button
                className='btn btn-outline-purple backButton mx-1'
                onClick={() => {
                  setSubmitted(false);
                  setFormKey(prevKey => prevKey + 1); // Increment key to force re-render
                }}>
                <span><IoArrowBack /></span>Back to page
              </button>
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (


    <div className="ContantBody">
      <Helmet>
        <title>Phenom&#x1D49;Cloud | Contact Us</title>
        <meta name="description" content=" Get in touch with PhenomeCloud for expert technology consulting, implementation, and support. Let’s collaborate to achieve your business goals." />
      </Helmet>

      <Banner />
      <TrustedOrganization />
      <div className='bg-color-form text-dark'>
        <div className="contact-form row container-fluid justify-content-center   p-0 w-100">
          <div className="col-md-4  d-flex px-5">
            <div className="row locations_content">
              <div className="company_locations">
                <div className="contents">
                  <h5 className="Location">Corporate Headquarters United States</h5>
                  <div className="location_container mb-2">
                    <span className="me-3"><img src={require(`../image/Contact/Location.png`)} alt="location" className="contactUs_icon" /></span>
                    <span className="address fw-normal">1333 Corporate Dr, # 212 Irving, TX - 75038, USA</span>
                  </div>
                  <div className="location_container">
                    <span className="me-3"><img src={require(`../image/Contact/Phone.png`)} alt="location" className="contactUs_icon" /></span>
                    <span className="phone fw-bold">  +1-855-978-6816</span>
                  </div>
                </div>
                <div className="contents">
                  <h5 className="Location">India - Vijayawada</h5>
                  <div className="location_container  mb-2">
                    <span className="me-3"><img src={require(`../image/Contact/Location.png`)} alt="location" className="contactUs_icon" /></span>
                    <span className="address fw-normal"> # 1-188/2A/2B/2C Inner Ring Road, Ambapuram - 520 015 Vijayawada, Andhra Pradesh</span>
                  </div>
                  <div className="location_container">
                    <span className="me-3"><img src={require(`../image/Contact/Phone.png`)} alt="location" className="contactUs_icon" /></span>
                    <span className="phone fw-bold"> +91 9553300071</span>
                  </div>

                </div>
                <div className="contents">
                  <h5 className="Location">India - Hyderabad</h5>
                  <div className="location_container  mb-2">
                    <span className="me-3"><img src={require(`../image/Contact/Location.png`)} alt="location" className="contactUs_icon" /></span>
                    <span className="address fw-normal"> #56, SR3 Towers, Jubilee Enclave, Opposite to Hitex Arch Madhapur, Hyderabad - 500081.</span>
                  </div>
                  <div className="location_container">
                    <span className="me-3"><img src={require(`../image/Contact/Phone.png`)} alt="location" className="contactUs_icon" /></span>
                    <span className="phone fw-bold"> +91 9553300071</span>
                  </div>
                </div>
              </div>
              <div className="contactUs_img_container">
                <img src={require(`../image/Contact/ContactUs.png`)} alt="CompanyLogo" />
              </div>

            </div>

          </div>
          <div className='d-flex justify-content-center text-dark form_section col-md-6 p-3'>
            <div className='mt-4 form_section_content w-100'>
              <h2 className='fw-bold text-center'>Contact Us</h2>
              <div className={`myFormDemo myFormDemo-${formKey}`}></div>
              {isSubmitted && <CustomPopup />}
            </div>
          </div>
        </div>
      </div>
      <GetStarted />
    </div>
  );
};

export default Contact;